import LoadingBar from "react-top-loading-bar";
import axios from "axios";
import React, { Component, Suspense, useState } from "react";
import Home from "./container/pages/Home/home";
import TentangKami from "./container/pages/Tentang Kami/tentangkami";
import VisiMisi from "./container/pages/Visi dan Misi/visimisi";
import Management from "./container/pages/Management/management";
import CaraBerinvestasi from "./container/pages/Cara Berinvestasi/caraberinvestasi";
import Projects from "./container/pages/projects/project";
import SyaratMenjadiInvestee from "./container/pages/Syarat Menjadi Investee/syaratmenjadiinvestee";
import SyaratMenjadiInvestor from "./container/pages/Syarat Menjadi Investor/syaratmenjadiinvestor";
import ListUmkm2 from "./container/pages/umkm/listumkm2";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import SNK from "./container/pages/SNK/syaratdanketentuan";
import SkemaInvestasi from "./container/pages/Sekema Investasi/skemainvestasi";
import DividenSHU from "./container/pages/Dividen/dividen";
import ComingSoon from "./container/pages/Coming Soon/comingsoon";
import Kontak from "./container/pages/Kontak/kontak";
import Artikel from "./container/pages/artikel/artikel";
import Slug from "./container/pages/artikel/slug";
import Faq from "./container/pages/FAQ/FAQ";
import { RecoilRoot, useRecoilValue } from "recoil";
import Dashboard from "./container/pages/Dahsboard/Dashboard";
import Register from "./components/Auth/Register";
import Register2 from "./components/Auth/Register2";
import Masuk from "./components/Auth/Masuk";
import OrderConfirmation from "./container/pages/OrderConfirmation/OrderConfirmation";
import { useEffect } from "react";
import { user, getTransaction } from "./store/Data";
import Invoice from "./components/Invoice/Invoice";
import NotFound from "./container/pages/404/404";
import Loading from "./components/Loading/Loading";
import Login2 from "./components/Auth/Login2";
import useToken from "./components/Auth/UseToken";
import useExpire from "./components/Auth/UseExpire";
import Whatsapp from "./components/WA/Whatsapp";

export default function App() {
  const { token, setToken } = useToken();
  const expired = useExpire();
  const [progress, setProgress] = useState(100);

  return (
    <RecoilRoot>
      <Suspense
        fallback={
          <>
            <LoadingBar
              color="#f11946"
              progress={progress}
              onLoaderFinished={() => setProgress(0)}
            />
            <Loading />
          </>
        }
      >
        <Router>
          <Switch>
            <Route path="/home">
              <Home />
            </Route>
            <Route path="/checkout">
              <OrderConfirmation />
            </Route>
            <Route path="/wa">
              <Whatsapp />
            </Route>
            <Route path="/invoice/:id">
              <Invoice />
            </Route>
            <Route path="/dashboard">
              <Dashboard />
            </Route>
            <Route path="/FAQ">
              <Faq />
            </Route>
            <Route path="/tentang-kami">
              <TentangKami />
            </Route>
            <Route path="/visi-dan-misi">
              <VisiMisi />
            </Route>
            <Route path="/projects/:slug">
              <Projects />
            </Route>
            <Route path="/list-umkm">
              <ListUmkm2 />
            </Route>
            <Route path="/syarat-dan-ketentuan">
              <SNK />
            </Route>
            <Route path="/skema-investasi">
              <SkemaInvestasi />
            </Route>
            <Route path="/syarat-menjadi-investor">
              <SyaratMenjadiInvestor />
            </Route>
            <Route path="/syarat-menjadi-investee">
              <SyaratMenjadiInvestee />
            </Route>
            <Route path="/dividen-shu">
              <DividenSHU />
            </Route>
            <Route path="/simpan-pinjam">
              <ComingSoon name="Simpan Pinjam" />
            </Route>
            <Route path="/badan-wakaf">
              <ComingSoon name="Badan Wakaf" />
            </Route>
            <Route path="/berita">
              <ComingSoon name="Berita" />
            </Route>
            <Route path="/kontak">
              <Kontak />
            </Route>
            <Route path="/register">
              <Register setToken={setToken} />
            </Route>
            <Route path="/login">
              <Login2 setToken={setToken} />
            </Route>
            <Route path="/cara-berinvestasi">
              <CaraBerinvestasi />
            </Route>
            <Route path="/manajemen">
              <Management />
            </Route>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/artikel">
              <Artikel />
            </Route>
            <Route path="/artikel/:slug">
              <Slug />
            </Route>
          </Switch>
        </Router>
      </Suspense>
    </RecoilRoot>
  );
}
