import Header from "../../../components/Header/header";
import Breadcumbs from "../../../components/Breadcumbs/breadcumbs";
import Footer from "../../../components/Footer/footer";
import Contact from "../../../components/Contact/contact";
import { seo } from "../../../helper/seo";

function Kontak(props) {
  seo({
    title:"Kontak | Gema Indonesia",
    metaDescription: "Alamat : Jl. Weleri- Gemuh KM.4 No.234, RT.02/RW.03, Krajan, Purworejo, Kec. Ringinarum, Kabupaten Kendal, Jawa Tengah 51356 | No. Telp : +6285158998350",
  })
  return (
    <div>
      {window.scrollTo(0, 0)}
        <Header/>
        <section className="page-header">
                <div className="container">
                    <div className="title">
                    <h1>Kontak</h1>
                    </div>
                </div>
            </section>
        <section className="inner-page">
        <div className="container">
            <div className="wrap">
                <Contact />
            </div>
        </div>
        </section>
        <Footer />
    </div>
  );
}

export default Kontak;
