import Header from "../../../components/Header/header";
import Breadcumbs from "../../../components/Breadcumbs/breadcumbs";
import Footer from "../../../components/Footer/footer";
import { seo } from "../../../helper/seo";
import { Link } from "react-router-dom";

function SkemaInvestasi(props) {
  seo({
    title:"Skema investasi | Gema Indonesia",
    metaDescription: "Equity crowdfunding Untuk Anda yang ingin memiliki bisnis tetapi tidak mengerti cara berbisnis atau mengatur operasional bisnis, atau Anda ingin tetap bekerja tetapi ingin mendapatkan passive income, maka investasi bisnis dengan Equity Crowndfunding merupakan solusi yang tepat untuk Anda.",
  })
  return (
    <div>
      {window.scrollTo(0, 0)}
        <Header/>
        <section className="page-header">
                <div className="container">
                    <div className="title">
                    <h1>Skema Investasi</h1>
                    </div>
                </div>
            </section>
        <section className="inner-page">
        <div className="container">
            <div className="wrap">
            <h3>Skema Investasi</h3>
            <p>Equity crowdfunding
                Untuk Anda yang ingin memiliki bisnis tetapi tidak mengerti cara berbisnis atau mengatur
                operasional bisnis, atau Anda ingin tetap bekerja tetapi ingin mendapatkan passive income,
                maka investasi bisnis dengan Equity Crowndfunding merupakan solusi yang tepat untuk
                Anda.</p>
            <p>
            Equity Crowdfunding adalah penawaran Unit Penyertaan Dana bisnis kepada investor atau dikenal dengan
            sistem urun dana.
            Sistem urun dana memberikan kesempatan investor kesempatan untuk berinvestasi ke
            dalam bisnis privat dengan modal kecil yang sebelumnya sulit untuk dilakukan. Investasi
            system ini juga merupakan peluang besar bagi investor yang ingin sekali berinvestasi bisnis
            tetapi memiliki keterbatasan dana.
            Sebagai gambaran, kurang lebih skemanya seperti gambar di bawah ini :
            </p>
            <div className="text-center">
            <img src="/assets/img/skema-investasi-gema.jpg" width="80%" alt="" />
            </div>
            <p>Anda sebagai investor akan mendapatkan Unit Penyertaan Dana kepemilikan dari bisnis tersebut sehingga
            berhak mendapatkan Imbal Hasil saat bisnis untung dan memiliki potensi keuntungan berupa
            capital gain nilai dari Unit Penyertaan Dana yang anda miliki.</p>
                        <p>Koperasi GEMA berperan sebagai penyelenggara, dan penerbit Unit Penyertaan Dana disini adalah UMKM
            sebagai penerima modal atau investee, dan anggota koperasi yang berinvestasi disebut
            dengan investor.</p>
                        <p>
                        Koperasi GEMA membantu investor untuk menyeleksi UMKM yang terdaftar untuk
            pembiayaan yang potensial dan layak untuk diberi investasi dengan ketentuan yang kami
            terapkan dalam menganalisis kelayakan UMKM sebelum listing atau dipublikasi ke investor
            untuk memastikan UMKM tersebut prospek serta meyakinkan untuk para investor.
            </p>
            <Link to="/list-umkm" className="btn btn-orange">Beli Unit Penyertaan Dana</Link>
            </div>
        </div>
        </section>
        <Footer />
    </div>
  );
}

export default SkemaInvestasi;
