import React from "react";
import Post from "../../../components/Post/post";
import { useParams } from "react-router";

const Slug = () => {
  const { slug } = useParams();

  return (
    <>
      <Post slug={slug} />
    </>
  );
};

export default Slug;
