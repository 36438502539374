import React from "react";
import image from "../../../assets/img/icon-404.svg";

export default function NotFound() {
  return (
    <div className="bg-grey2" style={{ position: "fixed" }}>
      <div className="mx-auto my-auto text-center">
        <div>
          <img src={image} alt="" />
        </div>
        <p>
          We are sorry but the page you are looking for does not exist. <br />
          You could return to the homepage using the button bellow
        </p>
        <div className="mt-3">
          <button
            onClick={() => {
              window.location = "/";
            }}
            className="btn btn-orange"
          >
            Back To Home
          </button>
        </div>
      </div>
    </div>
  );
}
