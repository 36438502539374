function Services() {
    return (
      <div>
        <section id="services" className="services">
        <div className="container">

        <div className="section-title">
        <h2>Mengapa</h2>
        <p>MENGAPA HARUS MENJADI ANGGOTA GEMA?</p>
        </div>

        <div className="row">
        <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
        <div className="icon-box">
            <div className="icon"><i className="bx bxl-dribbble"></i></div>
            <h4><a href="">Wujud Demokrasi Ekonomi</a></h4>
            <p>Koperasi GEMA adalah wujud demokrasi ekonomi melalui kebersamaan, kekeluargaan, keterbukaan, dan kebertanggungjawaban.
            </p>
        </div>
        </div>

        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div className="icon-box">
            <div className="icon"><i className="bx bx-file"></i></div>
            <h4><a href="">Pemilik Sekaligus Pengguna</a></h4>
            <p>Anggota Koperasi GEMA berkedudukan sebagai pemilik dan sekaligus sebagai pengguna jasa dari perusahaan koperasi.</p>
        </div>
        </div>

        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
        <div className="icon-box">
            <div className="icon"><i className="bx bx-tachometer"></i></div>
            <h4><a href="">Pasive Income</a></h4>
            <p>Koperasi GEMA memberi kesempatan bagi anggota untuk belajar manajemen keuangan serta mendapatkan penghasilan di waktu tertentu dari hasil pengelolaan koperasi</p>
        </div>
        </div>

        <div className="col-lg-2 col-md-6 d-flex align-items-stretch mt-4">

        </div>

        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
        <div className="icon-box">
            <div className="icon"><i className="bx bx-world"></i></div>
            <h4><a href="">Koneksi Luas</a></h4>
            <p>Anggota Koperasi GEMA dapat memiliki jaringan relasi atau koneksi yang luas</p>
        </div>
        </div>

        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
        <div className="icon-box">
            <div className="icon"><i className="bx bx-slideshow"></i></div>
            <h4><a href="">Pilihan Portofolio</a></h4>
            <p>Berbagai pilihan portofolio investasi disediakan di Koperasi GEMA 
            </p>
        </div>
        </div>

        <div className="col-lg-2 col-md-6 d-flex align-items-stretch mt-4">

        </div>

        </div>

        </div>
        </section>
      </div>
    );
  }
  
  export default Services;
  