import Header from "../../../components/Header/header";
import Breadcumbs from "../../../components/Breadcumbs/breadcumbs";
import ProjectDetails from "../../../components/Project Details/projectdetails";
import Footer from "../../../components/Footer/footer";
import { useParams } from "react-router";
import { Link } from "react-router-dom";


function Projects(props) {
let { slug } = useParams();
  return (
    <div>
        {window.scrollTo(0, 0)}
        <Header/>
        <Breadcumbs name="Projects"/>
        <section className="inner-page">
        <div className="container">
        <button className="btn btn-back mb-4"><Link to="/list-umkm"><i className="bx bx-arrow-back"></i> Kembali ke Semua Proyek</Link></button>
        <ProjectDetails slug={slug} />
        </div>
        </section>
        <Footer />
    </div>
  );
}

export default Projects;
