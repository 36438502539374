import { Link } from "react-router-dom";

function Hero() {
    return (
      <div>
       <section id="hero">
        <div id="heroCarousel" data-bs-interval="5000" className="carousel slide carousel-fade" data-bs-ride="carousel">

        <ol className="carousel-indicators" id="hero-carousel-indicators"></ol>

        <div className="carousel-inner" role="listbox">
            <div className="carousel-item active" style={{backgroundImage: "url(" + "https://gemaindonesia.id/wp-content/uploads/2021/04/slider-gema-1.jpg" + ")"}}>
            <div className="carousel-container">
                <div className="container">
                <h2 className="animate__animated animate__fadeInDown">Gerakan Ekonomi Madani</h2>
                <p className="animate__animated animate__fadeInUp">GemaIndonesia.id Gerakan Ekonomi Madani – GEMA adalah sebuah  Koperasi yang dikelola dengan managamen modern, menggunakan platform Equity Crowdfunding untuk pengembangan usaha  seperti Usaha Mikro, Kecil dan Menengah (UMKM), serta usaha rintisan (Startup) </p>
                <Link to="/register" className="btn-get-started animate__animated animate__fadeInUp scrollto">Registrasi</Link>
                </div>
            </div>
            </div>
        </div>

        <a className="carousel-control-prev" href="#heroCarousel" role="button" data-bs-slide="prev">
            <span className="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
        </a>

        <a className="carousel-control-next" href="#heroCarousel" role="button" data-bs-slide="next">
            <span className="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
        </a>

        </div>
        </section>
      </div>
    );
  }
  
  export default Hero;
  