import Header from "../../../components/Header/header";
import Breadcumbs from "../../../components/Breadcumbs/breadcumbs";
import Innerpages from "../../../components/Innerpages/innerpages";
import Footer from "../../../components/Footer/footer";
import { seo } from "../../../helper/seo";

function VisiMisi(props) {
  seo({
    title:"Visi dan Misi | Gema Indonesia",
    metaDescription: "GEMA adalah sebuah Koperasi yang dikelola dengan managamen modern, menggunakan platform Equity Crowdfunding untuk pengembangan usaha seperti Usaha Mikro, Kecil dan Menengah (UMKM), serta usaha rintisan (Startup)",
  })
  return (
    <div>
      {window.scrollTo(0, 0)}
        <Header/>
        <section className="page-header">
                <div className="container">
                    <div className="title">
                    <h1>Visi dan Misi</h1>
                    </div>
                </div>
            </section>
        <section className="inner-page">
        <div className="container">
            <div className="wrap">
            <p>
            Koperasi GEMA hadir dalam rangka mendukung Program Pemerintah dengan
            mempertemukan seluruh anggota baik sebagai Pemodal maupun sebagai pemilik
            usaha UMKM yang ingin mengembangkan bisnisnya menjadi lebih sehat, lebih besar
            dan terus bertumbuh.
            </p>
            <h3>Visi</h3>
            <p>
            Terwujudnya Koperasi yang mandiri dan tangguh serta amanah dalam rangka
            menumbuhkan dan membangun ekonomi bangsa yang adil, berdaulat dan berdaya
            saing tinggi.
            </p>
            <h3>Misi</h3>
            <ul>
                <li>Mengajak seluruh lapisan masyarakat bersatu padu membangun ekonomi
                kerakyatan tanpa membedakan suku, ras, golongan dan agama, secara gotong
                royong dalam wadah Koperasi.</li>
                <li>
                Membantu pelaku usaha khususnya Usaha Mikro, Kecil dan Menengah (UMKM)
                dari segi permodalan sehingga usahanya mampu bertumbuh, berkembang dan
                berdaya saing tinggi.
                </li>
                <li>
                Menjadikan pasar dari sebuah produk sekaligus juga sebagai pemilik bisnis dan
                ikut berkontribusi dalam mengembangkan sebuah bisnis.
                </li>
            </ul>
            </div>
        </div>
        </section>
        <Footer />
    </div>
  );
}

export default VisiMisi;
