export default function useExpire() {
  const date = new Date();
  const expire = localStorage.getItem("token2");
  if (expire) {
    const expired = expire.substr(24, 13);
    const now = date.getTime();
    const diff = expired - now;
    const minutes = Math.floor(diff / 1000 / 60);
    if (minutes <= 0) return true;
  }
  return false;
}
