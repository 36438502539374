import Header from "../../../components/Header/header";
import Breadcumbs from "../../../components/Breadcumbs/breadcumbs";
import Footer from "../../../components/Footer/footer";
import { seo } from "../../../helper/seo";

function ComingSoon(props) {
  seo({
    title:"Coming Soon | Gema Indonesia",
  })
  return (
    <div>
      {window.scrollTo(0, 0)}
        <Header/>
        <Breadcumbs name={props.name}/>
        <section className="inner-page">
        <div className="container">
            <div className="coming-soon text-center">
            <h1>Coming Soon</h1>
            </div>
        </div>
        </section>
        <Footer />
    </div>
  );
}

export default ComingSoon;
