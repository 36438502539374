import Header from "../../../components/Header/header";
import UMKM from "./umkm";
import Footer from "../../../components/Footer/footer";
import Portofolio2 from "../../../components/Portofolio/portofolio2";
import PortofolioCard from "../../../components/Portofolio/PortofolioCard";
import {
  AxiosProvider,
  Request,
  Get,
  Delete,
  Head,
  Post,
  Put,
  Patch,
  withAxios,
} from "react-axios";
import axios from "axios";
import React, { Component, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { seo } from "../../../helper/seo";

class ListUmkm2 extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    projects: [],
    setShow: false,
    filter: false,
    filterPriceMin: 0,
    filterPriceMax: 0,
  };

  //    search = (event) => {
  //        this.setState({
  //            filter:false
  //        })
  //         if(event.target.value){
  //             axios.get(`https://api.gemaindonesia.id/api/projects/search/${event.target.value}`)
  //             .then(response => {
  //                 if(Array.isArray(response.data.data) && response.data.data.length){
  //                     this.setState({
  //                         projects: response.data.data
  //                     })
  //                 } else {
  //                     this.setState({
  //                         setShow: true
  //                     })
  //                 }
  //             })
  //            }else{
  //             this.getProject()
  //            }
  //     }

  handleFilterOn = () => {
    this.setState({
      filter: true,
    });
  };
  handleFilterOff = () => {
    this.setState({
      filter: false,
    });
  };

  setShow = (value) => {
    if (value === false) {
      this.setState({
        setShow: false,
      });
    } else {
      this.setState({
        setShow: true,
      });
    }
  };

  handlePriceChange = (event) => {
    if (event.target.value == 1) {
      this.setState({
        filterPriceMin: 0,
        filterPriceMax: 300000000,
      });
    } else if (event.target.value == 2) {
      this.setState({
        filterPriceMin: 300000000,
        filterPriceMax: 500000000,
      });
    } else if (event.target.value == 3) {
      this.setState({
        filterPriceMin: 500000000,
        filterPriceMax: 1000000000,
      });
    } else if (event.target.value == 4) {
      this.setState({
        filterPriceMin: 1000000000,
        filterPriceMax: 1500000000,
      });
    } else if (event.target.value == 5) {
      this.setState({
        filterPriceMin: 1500000000,
        filterPriceMax: 2000000000,
      });
    } else if (event.target.value == 6) {
      this.setState({
        filterPriceMin: 2000000000,
        filterPriceMax: 2500000000,
      });
    } else if (event.target.value == 7) {
      this.setState({
        filterPriceMin: 2500000000,
        filterPriceMax: 3000000000,
      });
    }
  };

  showAlert = () => {
    if (this.state.setShow === true) {
      return (
        <Alert variant="danger" onClose={() => this.setShow(false)} dismissible>
          <p className="alertP">Proyek tidak di temukan!</p>
        </Alert>
      );
    }
  };

  getProject = () => {
    axios
      .get("https://backend.gemaindonesia.id/api/projects")
      .then((response) => {
        this.setState({
          projects: response.data.data,
        });
      });
  };

  percentage(partialValue, totalValue) {
    return (partialValue / totalValue) * 100 + "%";
  }

  showUmkm = () => {
    if (this.state.filter === false) {
      return this.state.projects.map((project) => (
        <UMKM
          slug={"/projects/" + project.slug}
          name={project.name}
          img_url={project.img_url}
          location={(project.harga_lot * project.jumlah_lot).toLocaleString()}
          category={project.category}
          website={project.website}
        />
      ));
    } else {
      return this.state.projects.map((project) => {
        if (
          project.harga_lot * project.jumlah_lot >= this.state.filterPriceMin &&
          project.harga_lot * project.jumlah_lot <= this.state.filterPriceMax
        ) {
          return (
            <UMKM
              slug={"/projects/" + project.slug}
              name={project.name}
              img_url={project.img_url}
              location={(
                project.harga_lot * project.jumlah_lot
              ).toLocaleString()}
              category={project.category}
              website={project.website}
            />
          );
        }
      });
    }
  };

  componentDidMount() {
    seo({
      title: "List UMKM | Gema Indonesia",
      metaDescription: "Berikut adalah daftar UMKM Gema Indonesia",
      keywords: "Ivestasi UMKM, Equity Crowdfunding, Bisnis Investasi",
    });
    this.getProject();
  }

  render() {
    return (
      <div>
        {window.scrollTo(0, 0)}
        <Header />
        <section className="page-header">
          <div className="container">
            <div className="title">
              <h1>List UMKM</h1>
            </div>
          </div>
        </section>
        <section className="inner-page">
          <div className="container">
            <Portofolio2 />
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default ListUmkm2;
