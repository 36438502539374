import Header from "../../../components/Header/header";
import Breadcumbs from "../../../components/Breadcumbs/breadcumbs";
import Footer from "../../../components/Footer/footer";
import { seo } from "../../../helper/seo";
import { Link } from "react-router-dom";

function SyaratMenjadiInvestor(props) {
  seo({
    title:"Syarat Menjadi Investor | Gema Indonesia",
    metaDescription: `
    1. Sudah menjadi Anggota aktif Koperasi GEMA
    2. Berusia minimum 18 tahun atau wajib dengan pengawasan dan persetujuan dari
    orang tua/wali yang sah apabila berusia 13 sampai 17 tahun
    3. Memiliki Kartu Tanda Penduduk (e-KTP)
    4. Sudah memahami Syarat dan Ketentuan Anggota dan Investor untuk berinvestasi di
    Koperasi GEMA dengan segala konsekuensi yang timbul karena invetasi
    `
  })
  return (
    <div>
      {window.scrollTo(0, 0)}
        <Header/>
        <section className="page-header">
                <div className="container">
                    <div className="title">
                    <h1>Syarat Menjadi Investor</h1>
                    </div>
                </div>
            </section>
        <section className="inner-page">
        <div className="container">
            <div className="wrap">
            <h3>Syarat Menjadi Investor</h3>
            <p>
                <ol>
                    <li>Sudah menjadi Anggota aktif Koperasi GEMA</li>
                    <li>Berusia minimum 18 tahun atau wajib dengan pengawasan dan persetujuan dari
                        orang tua/wali yang sah apabila berusia 13 sampai 17 tahun;</li>
                    <li>Memiliki Kartu Tanda Penduduk (e-KTP)</li>
                    <li>Sudah memahami Syarat dan Ketentuan Anggota dan Investor untuk berinvestasi di
                        Koperasi GEMA dengan segala konsekuensi yang timbul karena invetasi</li>
                </ol>
            </p>
            <Link to="/register" className="btn btn-orange">Daftar Jadi Investor</Link>
            </div>
        </div>
        </section>
        <Footer />
    </div>
  );
}

export default SyaratMenjadiInvestor;
