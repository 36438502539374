import { Link } from "react-router-dom";

function terjualHabis(perolehan, pendanaan) {
  if (perolehan == pendanaan) {
    return <span className="terjual">Terjual</span>;
  }
}

function PortofolioCard(props) {
  const day = (data1, data2) => {
    if (data1 === data2) {
      return 0;
    } else {
      return 30;
    }
  };
  const beli = (data1, data2) => {
    if (data1 === data2) {
      return <div className="beli2">Beli</div>;
    } else {
      return <div className="beli">Beli</div>;
    }
  };

  const getCard = () => {
    if (props.isLoaded) {
      return (
        <Link style={{ color: "#424242" }} to={props.slug}>
          <div className="card my-3">
            {terjualHabis(props.perolehan, props.total_pendanaan)}
            {beli(props.perolehan, props.total_pendanaan)}
            <img
              className="card-img-top"
              src={props.image}
              alt="Card image cap"
            />
            <div className="card-body">
              <div className="title">
                <h5 className="card-title">{props.name}</h5>
                <span className="badges">{props.category}</span>
                {/* <div className="garis"></div> */}
              </div>
              <div className="row">
                <div className="col-8">
                  <p>
                    <span className="harga">
                      Rp. {props.perolehan.toLocaleString()}
                    </span>
                  </p>
                  <p>dari Rp. {props.total_pendanaan} total pendanaan</p>
                </div>
                <div className="col-4">
                  <div className="status3">
                    <p className="hari">
                      {day(props.total_pendanaan, props.perolehan)}
                    </p>
                    <p>Hari Lagi</p>
                  </div>
                </div>
              </div>
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: props.proggress }}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {props.proggress}
                </div>
              </div>
              <p className="mt-1">Dana yang terkumpul</p>
              <div className="row">
                <div className="col-6">
                  <div className="status">
                    Harga Per Keping
                    <p>Rp. {props.harga_lot.toLocaleString()}</p>
                  </div>
                </div>
                <div className="col-6">
                  <div className="status2">
                    Jumlah Total Koin
                    <p>{props.total_lot.toLocaleString()}</p>
                  </div>
                  <div className="status2">
                    Jumlah Lot
                    <p>{(props.total_lot / 100).toLocaleString()}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="status">
                    Periode Dividen
                    <p>{props.periode_dividen}</p>
                  </div>
                </div>
                <div className="col-6">
                  <div className="status2">
                    Estimasi Dividen
                    <p>{props.estimasi_dividen}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      );
    } else {
      return (
        <>
          <div className="card my-3">
            <div className="card-img-loader"></div>
            <div className="card-body">
              <div className="project-title-loader"></div>
              <div className="project-category-loader"></div>
              <div className="row mt-5">
                <div className="col-lg-6 col-6">
                  <div className="project-left-loader"></div>
                </div>
                <div className="col-lg-6 col-6">
                  <div className="project-right-loader"></div>
                </div>
              </div>
              <div className="project-proggres-loader"></div>
              <div className="row mt-3">
                <div className="col-lg-6 col-6">
                  <div className="project-left-loader"></div>
                  <div className="project-left-loader"></div>
                </div>
                <div className="col-lg-6 col-6">
                  <div className="project-right-loader"></div>
                  <div className="project-right-loader"></div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  return <>{getCard()}</>;
}

export default PortofolioCard;
