import { Link } from "react-router-dom";

function UMKM(props) {
    return (
        <tr>
        <td>
            <div className="company d-flex">
                <img src={props.img_url} width="50px" alt="" />
                <div className="title">
                    <Link to={props.slug}>{props.name}</Link>
                </div>
            </div>
        </td>
        <td>Rp. {props.location}</td>
        <td>{props.category}</td>
        <td><a href={props.website} target="_blank">{props.website}</a></td>
        </tr>
    );
  }
  
  export default UMKM;
  