import React from "react";
import Layout2 from "../../../components/Layout/Layout2";
import axios from "axios";
import { Component } from "react";

function prettyDate(time) {
    var date = new Date((time || "").replace(/-/g, "/").replace(/[TZ]/g, " ")),
        diff = (((new Date()).getTime() - date.getTime()) / 1000),
        day_diff = Math.floor(diff / 86400);
    var year = date.getFullYear(),
        month = date.getMonth()+1,
        day = date.getDate();

    if (isNaN(day_diff) || day_diff < 0 || day_diff >= 31)
        return (
            year.toString()+'-'
            +((month<10) ? '0'+month.toString() : month.toString())+'-'
            +((day<10) ? '0'+day.toString() : day.toString())
        );

    var r =
    ( 
        (
            day_diff == 0 && 
            (
                (diff < 60 && "Just now")
                || (diff < 120 && "1 minute ago")
                || (diff < 3600 && Math.floor(diff / 60) + " minutes ago")
                || (diff < 7200 && "1 hour ago")
                || (diff < 86400 && Math.floor(diff / 3600) + " hours ago")
            )
        )
        || (day_diff == 1 && "Yesterday")
        || (day_diff < 7 && day_diff + " days ago")
        || (day_diff < 31 && Math.ceil(day_diff / 7) + " weeks ago")
    );
    return r;
}

export default class artikel extends Component {

    state={
        posts : {
            nodes: []
        },
        isLoading : true
    }

    getPost = () => {
      if(this.state.isLoading){
        return(
          <>
          <div className="col-lg-4">
        <div className="post-loader">
          <div className="post-img-loader">

          </div>
          <div className="post-title-wraper">
          <div className="post-title-loader"></div>
          <div className="post-title-loader2"></div>
          </div>
          <div className="post-author-wraper">
            <div className="post-author-img"></div>
            <div className="post-author-detail">
              <div className="post-author-name"></div>
              <div className="post-author-name2"></div>
            </div>
          </div>
        </div>
          </div>
          <div className="col-lg-4">
        <div className="post-loader">
          <div className="post-img-loader">

          </div>
          <div className="post-title-wraper">
          <div className="post-title-loader"></div>
          <div className="post-title-loader2"></div>
          </div>
          <div className="post-author-wraper">
            <div className="post-author-img"></div>
            <div className="post-author-detail">
              <div className="post-author-name"></div>
              <div className="post-author-name2"></div>
            </div>
          </div>
        </div>
          </div>
          <div className="col-lg-4">
        <div className="post-loader">
          <div className="post-img-loader">

          </div>
          <div className="post-title-wraper">
          <div className="post-title-loader"></div>
          <div className="post-title-loader2"></div>
          </div>
          <div className="post-author-wraper">
            <div className="post-author-img"></div>
            <div className="post-author-detail">
              <div className="post-author-name"></div>
              <div className="post-author-name2"></div>
            </div>
          </div>
        </div>
          </div>
          </>
        )
      }else{
        return (
            this.state.posts.nodes.map(post=>{
              return(
                <div className="col-lg-4" key={post.id}>
                  <a href={"artikel/"+post.slug}>
                  <div className="post">
                  <img src={post.featuredImage.node.mediaItemUrl} alt=""/>
                 <div className="post-title">
                         <div className="text">
                         {post.title}
                         </div>
                 <div className="post-description">
                 <div className="author-pic">
                     <img src={post.author.node.avatar.url} alt=""/>
                     </div>
                     <div className="postby">
                         <div className="author-name">
                         {post.author.node.name}
                         </div>
                         <div className="post-date">
                         {prettyDate(post.date)}
                         </div>
                     </div>

                 </div>
                 </div>
                 </div>
                  </a>
              </div>
              )
            })
         )
      }
    }

    getArtikel = async () => {
      axios({
        url: 'https://gemaindonesia.id/sandi/graphql',
        method: 'post',
        data: {
          query: `
          query Posts {
            posts {
              nodes {
                title
                slug
                date
                excerpt
                id
                categories {
                  nodes {
                    name
                  }
                }
                tags {
                  nodes {
                    name
                  }
                }
                author {
                  node {
                    name
                    avatar {
                      url
                    }
                  }
                }
                featuredImage {
                  node {
                    mediaItemUrl
                  }
                }
              }
            }
          }
            `
        }
      }).then((result) => {
        this.setState({
          posts: result.data.data.posts,
          isLoading: false
      })
      }).catch(error => {
          this.getArtikel()
      });
    }

    componentDidMount = () => {
        this.getArtikel()
    }
    
    render() {
        return (
            <div>
               <Layout2>
               <div className="container">
                    <div className="wrap">
                    <div className="post-wrapper">
                    <div className="post-sec-title">
                    <h2>Post Terbaru</h2>
                    </div>
                    <div className="post-list">
                    <div className="row">
                    {this.getPost()}
                    </div>
                    </div>
                    </div>
                    </div>
                </div>
               </Layout2>
            </div>
        );
    }
}
