import Header from "../../../components/Header/header";
import Breadcumbs from "../../../components/Breadcumbs/breadcumbs";
import Footer from "../../../components/Footer/footer";
import { Link } from "react-router-dom";
import { seo } from "../../../helper/seo";


function CaraBerinvestasi(props) {
  seo({
    title:"Cara Berivestasi | Gema Indonesia",
    metaDescription: "Panduan Cara Berinvestasi di GEMA Indonesia| 1. Anda harus menjadi anggota koperasi Gema Indonesia terlebih dahulu.- 2.Kemudian mengisi form pendaftaran, agar kami bisa mengetahui preferensi anda. - 3. Jika keanggotan anda sudah di aktivasi, silakan anda bisa membeli Unit Penyertaan Dana di bisnis-bisnis yang anda minati. transfer ke admin sesuai dengan nominal pembelian. - 4.Setelah melakukan transfer, lakukan konfirmasi ke admin untuk aktivasi ID member dan sertifikat pembelian Unit Penyertaan Dana dan akan di masukan ke group para investor",
  })
  return (
    <div>
      {window.scrollTo(0, 0)}
        <Header/>
        <section className="page-header">
                <div className="container">
                    <div className="title">
                    <h1>Cara Berinvestasi</h1>
                    </div>
                </div>
            </section>
        <section className="inner">
        <div className="container">
            <div className="wraper">
              <div className="row my-5">
                <div className="col-lg-6 col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="title">
                        <h5 className="card-title">Langkah 1</h5>
                        <h6 className="card-subtitle mb-2 text-muted">Daftar Jadi Anggota Koperasi Gema Indonesia</h6>
                      </div>
                      <p className="card-text">Anda harus menjadi anggota koperasi Gema Indonesia terlebih dahulu.</p>
                      <Link to="/register" className="btn btn-orange3 mt-3">Daftar jadi member</Link>
                    </div>
                </div>
                </div>
                <div className="col-lg-6 col-12">
                  <img src="/assets/img/web_devices.svg" width="70%" alt="" />
                </div>
              </div>
              <div className="row my-5">
              <div className="col-lg-6 col-12">
                  <img src="/assets/img/form.svg" width="70%" alt="" />
                </div>
                <div className="col-lg-6 col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="title">
                        <h5 className="card-title">Langkah 2</h5>
                        <h6 className="card-subtitle mb-2 text-muted">Isi form pendaftaran</h6>
                      </div>
                      <p className="card-text">Kemudian mengisi form pendaftaran, agar kami bisa mengetahui preferensi anda. </p>
                      <Link to="/register" className="btn btn-orange3 mt-3">Isi form pendaftaran</Link>
                    </div>
                </div>
                </div>
              </div>
              <div className="row my-5">
              <div className="col-lg-6 col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="title">
                        <h5 className="card-title">Langkah 3</h5>
                        <h6 className="card-subtitle mb-2 text-muted">Beli Unit Penyertaan Dana bisnis</h6>
                      </div>
                      <p className="card-text">Jika keanggotan anda sudah di aktivasi,  silakan anda bisa membeli Unit Penyertaan Dana di bisnis-bisnis yang anda minati. transfer ke admin sesuai dengan nominal pembelian</p>
                      <Link to="/list-umkm" className="btn btn-orange3 mt-3">Booking Unit Penyertaan Dana</Link>
                    </div>
                </div>
                </div>
              <div className="col-lg-6 col-12">
                  <img src="/assets/img/search.svg" width="70%" alt="" />
                </div>
              </div>
              <div className="row my-5">
              <div className="col-lg-6 col-12">
                  <img src="/assets/img/confirm.svg" width="70%" alt="" />
                </div>
                <div className="col-lg-6 col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="title">
                        <h5 className="card-title">Langkah 4</h5>
                        <h6 className="card-subtitle mb-2 text-muted">Konfimasi pembelian Unit Penyertaan Dana</h6>
                      </div>
                      <p className="card-text">Setelah melakukan transfer, lakukan  konfirmasi ke admin untuk aktivasi ID member dan sertifikat pembelian Unit Penyertaan Dana dan akan di masukan ke group para investor</p>
                      <a href="https://api.whatsapp.com/send?phone=6285163629898&text=Halo%20admin%2C%20saya%20mau%20konfirmasi%20pembelian" target="_blank" className="btn btn-orange3 mt-3">Konfirmasi</a>
                    </div>
                </div>
                </div>
              </div>
            </div>
        </div>
        </section>
        <Footer />
    </div>
  );
}

export default CaraBerinvestasi;
