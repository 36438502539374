import Header from "../../../components/Header/header";
import Breadcumbs from "../../../components/Breadcumbs/breadcumbs";
import Footer from "../../../components/Footer/footer";
import { seo } from "../../../helper/seo";
import { Link } from "react-router-dom";

function DividenSHU(props) {
  seo({
    title:"Imbal Hasil SHU | Gema Indonesia",
    metaDescription: `Pada dasarnya terdapat 4 (empat) prinsip terkait pembagian Imbal Hasil/SHU pada sebuah Koperasi yang diatur pada UU Perkoperasian, antara lain`,
  })
  return (
    <div>
      {window.scrollTo(0, 0)}
        <Header/>
        <section className="page-header">
                <div className="container">
                    <div className="title">
                    <h1>Imbal Hasil / SHU</h1>
                    </div>
                </div>
            </section>
        <section className="inner-page">
        <div className="container">
            <div className="wrap">
            <h3>Imbal Hasil / SHU</h3>
            <p>
            Pada dasarnya terdapat 4 (empat) prinsip terkait pembagian Imbal Hasil/SHU pada
            sebuah Koperasi yang diatur pada UU Perkoperasian, antara lain :
            <ol>
                <li>SHU bersumber dari anggota.</li>
                <li>SHU sebagai bentuk imbal jasa atas partisipasi anggota.</li>
                <li>SHU dibagikan secara transparan dan terbuka.</li>
                <li>SHU dibayarkan secara tunai.</li>
            </ol>
            </p>
            <p>
            Di Koperasi GEMA sendiri, secara umum kita membagi Imbal Hasil/SHU menjadi 2
lingkup yang berbeda untuk seluruh anggota, yakni dari Koperasinya itu sendiri dan

dari kontribusi anggota sebagai pemodal dalam proses penggalangan dana untuk
membiayai unit usaha tertentu, baik sebagai peminjam (borrower) maupun sebagai
investor.
            </p>
            <p>
            Jadi tiap setahun sekali akan mendapatkan Imbal Hasil dan/ SHU sebagai anggota
Koperasi, juga pada periode tertentu (misal per 3 bulan, 6 bulan, atau 12 bulan)
akan mendapatkan Imbal Hasil lainnya dari berbagai unit usaha yang dibiayai oleh
anggota koperasi sebagai pemodal.
            </p>
            <Link href="/list-umkm"><a className="btn btn-orange">Beli Unit Penyertaan Dana</a></Link>
            </div>
        </div>
        </section>
        <Footer />
    </div>
  );
}

export default DividenSHU;
