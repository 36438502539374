import PortofolioCard from "./PortofolioCard";
import { Link } from "react-router-dom";
import axios from "axios";
import { Component, useEffect } from "react";
import axiosRetry from "axios-retry";
import { useRecoilValue } from "recoil";
import { getProject } from "../../store/Data";

axiosRetry(axios, { retries: 5 });

function percentage(partialValue, totalValue) {
  return (partialValue / totalValue) * 100 + "%";
}

export default function Portofolio() {
  const projects = useRecoilValue(getProject);

  useEffect(() => {}, [projects]);

  const GetPort = () => {
    if (projects) {
      return projects.slice(0, 4).map((project) => {
        return (
          <div key={project.projectId} className="col-lg-3">
            <PortofolioCard
              isLoaded={projects ? true : false}
              slug={"/projects/" + project.slug}
              image={project.imgUrl}
              name={project.name}
              category={project.category}
              perolehan={
                parseInt(project.paid_sum || 0) || project.totalPerolehan
              }
              total_pendanaan={project.totalPendanaan}
              proggress={percentage(
                parseInt(project.paid_sum || 0) || project.totalPerolehan,
                project.totalPendanaan
              )}
              harga_lot={project.hargaPerlembar}
              total_lot={project.totalPendanaan / project.hargaPerlembar}
              periode_dividen={project.dividenPeriode + " Bulan"}
              estimasi_dividen={
                project.minDividen + " - " + project.maxDividen + "% / Tahun"
              }
            />
          </div>
        );
      });
    } else {
      return (
        <>
          <div className="col-lg-3">
            <PortofolioCard />
          </div>
          <div className="col-lg-3">
            <PortofolioCard />
          </div>
          <div className="col-lg-3">
            <PortofolioCard />
          </div>
          <div className="col-lg-3">
            <PortofolioCard />
          </div>
        </>
      );
    }
  };

  return (
    <>
      <section id="portfolio" className="portfolio section-bg">
        <div className="container">
          <div className="section-title">
            <h2>Investasi</h2>
            <p>Pendanaan yang sedang berlangsung</p>
          </div>
          <div className="row">
            <GetPort />
          </div>
          <div className="text-center">
            {/* <Link to="/list-umkm" className="btn btn-orange mt-5">SEMUA PROYEK</Link> */}
          </div>
        </div>
      </section>
    </>
  );
}
