import axios from "axios";
import { atom, selector } from "recoil";

const cart = JSON.parse(localStorage.getItem("cart")) || [];
const userData = JSON.parse(localStorage.getItem("token"));

const membershipGema = atom({
  key: "membership",
  default: 250000,
});

const cartItem = atom({
  key: "cart",
  default: cart,
});

const auth_user = atom({
  key: "auth-user",
  default: userData?.user,
});

const isShow = atom({
  key: "button-show",
  default: false,
});

const isLogin = atom({
  key: "login",
  default: JSON.parse(localStorage.getItem("user")) ? true : false,
});

const user = atom({
  key: "users",
  default: JSON.parse(localStorage.getItem("user")),
});

const transaction = atom({
  key: "transaction",
  default: false,
});

const chart = atom({
  key: "chart",
  default: {
    paymentId: "",
    userId: "",
    projectId: "",
    totalKoin: "",
    totalLot: "",
    totalPrice: "",
    isMember: userData?.userDetail?.memberActivatedAt ? "Aktif" : "Tidak Aktif",
    kodeUnik: Math.floor(Math.random() * 500) + 100,
    status: "pending",
  },
});

const register = atom({
  key: "register",
  default: {
    name: "",
    email: "",
    username: "-",
    password: "",
    phone: "",
    mother_name: "",
    address: "",
    city: "",
    districts: "",
    region: "",
    post_code: "",
    id_card_number: "",
    id_card_path: "",
    selfie_path: "",
    npwp_number: "-",
    npwp_path: "-",
    account_name: "-",
    account_number: "",
    bank_name: "",
  },
});
const buktiTransfer = atom({
  key: "buktiTransfer",
  default: {
    bukti_transfer: "",
  },
});

const getTransaction = selector({
  key: "get-transaction",
  get: async () => {
    try {
      const result = await axios.get(
        "https://admin.gemaindonesia.id/admin/v1/entities/custom-transaction",
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("token")).accessToken
                ? JSON.parse(localStorage.getItem("token")).accessToken
                : null
            }`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      return result.data;
    } catch (error) {
      return null;
    }
  },
});

const getProject = selector({
  key: "get-project",
  get: async () => {
    try {
      const result = await axios.get(
        "https://admin.gemaindonesia.id/admin/v1/entities/projects"
      );
      return result.data.data.data;
    } catch (error) {
      return null;
    }
  },
});

export {
  cartItem,
  membershipGema,
  isShow,
  isLogin,
  user,
  register,
  transaction,
  chart,
  getTransaction,
  buktiTransfer,
  getProject,
  auth_user,
};
