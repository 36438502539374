import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import Swal from "sweetalert2";
import NotFound from "../../container/pages/404/404";
import { user } from "../../store/Data";
import useToken from "../Auth/UseToken";

export default function Invoice() {
  const [isLoading, setLoading] = useState(false);
  const [buktiBayar, setBuktiBayar] = useState([]);
  const { token } = useToken();

  const goRegister = async () => {
    const data = {
      buktiBayar: buktiBayar,
      id: invoice?.payment_id,
    };
    // convert data to form data
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    try {
      const response = await axios.post(
        "https://admin.gemaindonesia.id/admin/v1/entities/custom-transaction/add",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (response) {
        console.log(response);
        Swal.fire("Success", "Bukti Pembayaran berhasil di upload", "success");
      }
    } catch (error) {
      console.log(error.response);
      Swal.fire("Error", "Upload gagal, silahkan coba lagi", "warning");
    }
  };

  const userData = useRecoilValue(user);
  const { id } = useParams();
  const [invoice, setInvoice] = useState({
    payment_id: "",
    project_id: 0,
    status: "",
    total_koin: 0,
    total_lot: 0,
    total_price: 0,
    user: {
      id: "",
      name: "",
    },
  });

  const [found, setFound] = useState(false);
  const [isError, setError] = useState(false);
  const [isMember, setMember] = useState(false);
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const [kodeUnik, setKodeUnik] = useState(0);
  const getInvoice = async () => {
    try {
      const invoice = await axios.get(
        "https://admin.gemaindonesia.id/admin/v1/entities/custom-transaction/read?slug=custom-transaction&id=" +
          id,
        {
          headers: headers,
        }
      );
      console.log(invoice);
      if (invoice) {
        setFound(true);
        setInvoice(invoice.data);
        setKodeUnik(invoice.data.kode_unik);
        if (invoice?.data.is_member == "Aktif") {
          setMember(true);
        }
      }
    } catch (error) {
      // setError(true);
    }
  };

  // handle change bukti bayar
  const handleChange = (e) => {
    setBuktiBayar(e.target.files[0]);
  };

  const isoDatetoDateTime = (isoDate) => {
    const date = new Date(isoDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    return `${day}/${month}/${year} ${","} ${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    getInvoice();
    console.log(buktiBayar);
  }, [isMember]);

  return (
    <>
      {found ? (
        <div className="bg-grey2">
          <div className="invoice-loader mx-auto my-auto mt-5 mb-5">
            <div className="invoice-wrapper">
              <div className="logo text-center mb-4">
                <img
                  src="https://i.ibb.co/K25ykWZ/logo-gema-169x60px.png"
                  width={"30%"}
                  alt=""
                />
              </div>
              <hr />
              <h1 className="mt-3">
                <b>Invoice</b>
              </h1>
              <div
                className="invoice-recipent mt-2"
                style={{ opacity: "0.7", fontSize: "14px" }}
              >
                ID Pendanaan : {invoice?.payment_id} <br />
                Nama : {invoice?.user.name} <br />
                Tanggal : {isoDatetoDateTime(invoice?.created_at)} <br />
              </div>
              <div className="d-flex justify-content-end">
                <div className="status">
                  <div style={{ textAlign: "right" }}>
                    Status : <b>{invoice?.status}</b>
                  </div>
                  <div style={{ textAlign: "right", opacity: "0.7" }}>
                    {isoDatetoDateTime(invoice?.created_at)}
                  </div>
                </div>
              </div>
              <div className="invoice-details mt-3 p-4">
                <b>Unit Pendanaan</b>
                <br />
                <br />
                <div className="item-detail" style={{ fontSize: "14px" }}>
                  Koin Cetro Coffee : {invoice?.total_koin.toLocaleString()}{" "}
                  Keping ({invoice?.total_lot.toLocaleString()} Lot) <br />
                  {!isMember ? (
                    <>
                      "Keanggotaan Koperasi GEMA : Rp. 250.000,-" <br />
                    </>
                  ) : null}
                  Kepimilikan Unit{" "}
                  {invoice?.total_price / invoice?.project?.total_pendanaan} %
                  <br />
                  Imbal Hasil per 6 bulan <br />
                  Nominal : Rp. {invoice?.total_price.toLocaleString()}-, <br />
                  Kode Unik : {invoice?.kode_unik} <br />
                </div>
              </div>
              <div className="invoice-details2 d-flex text-white py-3 px-4 justify-content-end">
                <b>
                  Total : RP.{" "}
                  {isMember
                    ? (invoice.total_price + kodeUnik).toLocaleString()
                    : (
                        invoice.total_price +
                        kodeUnik +
                        250000
                      ).toLocaleString()}
                  -,
                </b>
              </div>
              {invoice?.status == "pending" ? (
                <>
                  {" "}
                  <div
                    className="invoice-info mt-4"
                    style={{ fontSize: "12px" }}
                  >
                    Silakan transfer jumlah yang tertera di bagian TOTAL.
                    Pastikan sesuai hingga 3 digit terakhir : <br />{" "}
                    <b>Koperasi GEMA Bank Mandiri 136-00-881-882-86</b> <br />{" "}
                    a.n <b>Koperasi Gerakan Ekonomi Madani</b>
                    <br />
                    <br />
                    *Jika dalam 1x24 jam tidak melakukan pembayaran, maka order
                    pendanaan akan dibatalkan secara otomatis oleh sistem
                  </div>
                </>
              ) : null}
            </div>
            {invoice?.bukti_bayar ? (
              <div className="invoice-wrapper mt-3">
                <img
                  src={
                    "https://admin.gemaindonesia.id/storage/" +
                    invoice?.bukti_bayar
                  }
                  width={"100%"}
                  alt="bukti_bayar"
                />
                <button
                  disabled
                  className="btn btn-orange mt-3"
                  style={{ width: "100%" }}
                >
                  Konfirmasi Pembayaran
                </button>
                <Link
                  to="/dashboard"
                  className="btn btn-orange mt-3"
                  style={{ width: "100%" }}
                >
                  Kembali ke dashboard
                </Link>
              </div>
            ) : (
              <div className="invoice-wrapper mt-3">
                <label htmlFor="buktiBayar" className="mb-2 text-small">
                  Upload Bukti Bayar
                </label>
                <br />
                <input
                  type="file"
                  name="buktiBayar"
                  className="form-control"
                  id=""
                  onChange={handleChange}
                />
                <button
                  onClick={() => {
                    goRegister();
                  }}
                  className="btn btn-orange mt-3"
                  style={{ width: "100%" }}
                >
                  Konfirmasi Pembayaran
                </button>
                <Link
                  to="/dashboard"
                  className="btn btn-orange mt-3"
                  style={{ width: "100%" }}
                >
                  Kembali ke dashboard
                </Link>
              </div>
            )}
          </div>
        </div>
      ) : null}
      {isError ? <NotFound /> : null}
    </>
  );
}
