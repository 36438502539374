import React from "react";
import { Container, Nav, Navbar, NavDropdown, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { goLogout } from "../Auth/Logout";
import useAuth from "../Auth/UseAuth";

export default function Header() {
  const { user } = useAuth();

  const navbar = [
    {
      name: "Home",
      link: "/",
      dropdown: [
        {
          name: "Tentang Kami",
          link: "/tentang-kami",
        },
        {
          name: "Visi dan Misi",
          link: "/visi-dan-misi",
        },
        {
          name: "Manajemen",
          link: "/manajemen",
        },
      ],
    },
    {
      name: "Investasi",
      link: "#",
      dropdown: [
        {
          name: "Cara Berinvestasi",
          link: "/cara-berinvestasi",
        },
        {
          name: "Skema Investasi",
          link: "/skema-investasi",
        },
        {
          name: "Syarat Menjadi Investor",
          link: "/syarat-menjadi-investor",
        },
        {
          name: "Syarat Menjadi UMKM Investee",
          link: "/syarat-menjadi-investee",
        },
        {
          name: "List UMKM",
          link: "/list-umkm",
        },
        {
          name: "Imbal Hasil / SHU",
          link: "/dividen-shu",
        },
      ],
    },
    {
      name: "Artikel",
      link: "/artikel",
    },
    {
      name: "FAQ",
      link: "/faq",
    },
    {
      name: "Kontak",
      link: "/kontak",
    },
  ];
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="dark"
      variant="dark"
      style={{ fontSize: "16px", fontWeight: "400" }}
      fixed="top"
    >
      <Container>
        <Navbar.Brand href="#home">
          <Link to="/">
            <img
              src="https://gemaindonesia.id/wp-content/uploads/2021/10/logo_gema_169x60.png"
              width={130}
              className="p-2"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {navbar.map((el, index) => {
              return (
                <>
                  {!el.dropdown ? (
                    <Nav.Link as={Link} to={el.link}>
                      {el.name}
                    </Nav.Link>
                  ) : (
                    <NavDropdown title={el.name} id="collasible-nav-dropdown">
                      {el.dropdown.map((el, index) => {
                        return (
                          <NavDropdown.Item as={Link} to={el.link}>
                            {el.name}
                          </NavDropdown.Item>
                        );
                      })}
                    </NavDropdown>
                  )}
                </>
              );
            })}
            {user ? (
              <div className="mobile">
                <Nav.Link as={Link} to={"/dashboard"}>
                  Dashboard
                </Nav.Link>
                <Nav.Link onClick={goLogout}>Logout</Nav.Link>
              </div>
            ) : null}
          </Nav>
          <Nav className="p-3">
            {user ? (
              <div className="web d-flex">
                <Image
                  src="https://kerma.widyatama.ac.id/wp-content/uploads/2021/05/blank-profile-picture-973460_1280.png"
                  fluid
                  roundedCircle
                  width={40}
                />
                <NavDropdown title={user?.name} id="collasible-nav-dropdown">
                  <NavDropdown.Item as={Link} to={"/dashboard"}>
                    Dashboard
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={goLogout}>Logout</NavDropdown.Item>
                </NavDropdown>
              </div>
            ) : (
              <>
                <Nav.Link href="/login" className="btn btn-orange px-4">
                  Login
                </Nav.Link>
                <Nav.Link href="/register" className="btn btn-orange px-4">
                  Register
                </Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
