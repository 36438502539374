import Header from "../../../components/Header/header";
import Breadcumbs from "../../../components/Breadcumbs/breadcumbs";
import Footer from "../../../components/Footer/footer";
import { seo } from "../../../helper/seo";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import AccordionItem from "react-bootstrap/esm/AccordionItem";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import axios from "axios";

import React, { Component } from "react";

export default class Faq extends Component {
  state = {
    category: [
      {
        slug: "",
        name: "",
      },
    ],
    faq: [
      {
        content: "",
        title: "",
        faqId: "",
      },
    ],
  };

  getProject = () => {
    axios({
      url: "https://gemaindonesia.id/sandi/graphql",
      method: "post",
      data: {
        query: `
              query MyQuery2 {
                categoriesFaqs {
                  nodes {
                    slug
                    name
                  }
                }
              }
                `,
      },
    })
      .then((result) => {
        this.setState({
          category: result.data.data.categoriesFaqs.nodes,
        });
      })
      .catch((error) => {
        this.getProject();
      });
  };

  getFaq = (slug) => {
    axios({
      url: "https://gemaindonesia.id/sandi/graphql",
      method: "post",
      data: {
        query: `
              query MyQuery {
                categoryFaqs(id: "${slug}", idType: SLUG) {
                    contentNodes {
                    nodes {
                        ... on Faq {
                        content
                        title
                        faqId
                        }
                    }
                    }
                }
                }
                `,
      },
    })
      .then((result) => {
        this.setState({
          faq: result.data.data.categoryFaqs.contentNodes.nodes,
        });
      })
      .catch((error) => {
        this.getFaq(slug);
      });
  };

  componentDidMount = () => {
    this.getProject();
    this.getFaq("tentang-equity-crowdfunding-investasi");
  };

  render() {
    return (
      <div>
        {window.scrollTo(0, 0)}
        <Header />
        <section className="page-header">
          <div className="container">
            <div className="title">
              <h1>F.A.Q</h1>
            </div>
          </div>
        </section>
        <section className="inner-page">
          <div className="container">
            <div className="wrap">
              <h3>Frequently Ask Question</h3>
              <div className="row">
                <div className="col-lg-4">
                  <div className="faq-wrapper">
                    {this.state.category.map((cat) => {
                      return (
                        <button
                          className="btn btn-orange mb-2"
                          onClick={() => {
                            this.getFaq(cat.slug);
                          }}
                        >
                          {cat.name}
                        </button>
                      );
                    })}
                  </div>
                </div>
                <div className="col-lg-8">
                  <Accordion defaultActiveKey="0">
                    {this.state.faq.map((faq) => {
                      return (
                        <Accordion.Item eventKey={faq.faqId}>
                          <Accordion.Header>{faq.title}</Accordion.Header>
                          <Accordion.Body>
                            <div
                              dangerouslySetInnerHTML={{ __html: faq.content }}
                            ></div>
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
