import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import Footer from "../../../components/Footer/footer";
import Header from "../../../components/Header/header";
import { chart, transaction, user as userData } from "../../../store/Data";
import Swal from "sweetalert2";
import useAuth from "../../../components/Auth/UseAuth";
import useToken from "../../../components/Auth/UseToken";

const OrderConfirmed = () => {
  useEffect(() => {}, []);

  return (
    <>
      {/* <Header /> */}
      <div className="bg-grey3">
        <div className="confirmed-wrap">
          <div className="p-4 text-center">
            <img src="./assets/img/icon-confirmed.svg" width={"50%"} alt="" />
          </div>
          <div className="p-4 pb-5 text-center">
            <p style={{ fontWeight: "bold" }}>
              <b>Order Pendanaan Sukses</b>
            </p>
            <p>
              Silakan cek Whatsapp dan Email Anda untuk konfirmasi transfer dan
              proses selanjutnya. Jika ada pertanyaan silakan
              <a href="https://bit.ly/AdminGEMA"> Hubungi Kami Disini</a>
            </p>
            <a href="/home" className="btn btn-orange">
              Kembali Ke Menu
            </a>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

const Checkout = () => {
  return (
    <>
      {/* <Header /> */}
      <div className="bg-grey2">
        <div className="checkout-wrap my-5">
          <div className="p-4">
            <div
              className="p-4 bg-dark text-white"
              style={{ fontSize: "14px" }}
            >
              Anda akan melakukan pembelian : <br /> Unit Penyertaan{" "}
              <b>Dana Cetroo Coffee</b> Sejumlah 10 Lot <br /> atau 1.000 Keping
              Koin <br />
              Dengan Nominal <b>Rp. 1.000.000,-</b>
            </div>
            <div className="p-3 text-white bg-orange">
              <b>Total Rp. 1.000.000,-</b>
            </div>
          </div>
          <div className="px-4 text-center">
            <p style={{ fontWeight: "bold" }}>
              <b>Ketentuan Investasi</b>
            </p>
            <p>
              Harap membaca dan memahami Ketentuan Investasi melalui Koperasi
              GEMA Indonesia ini dengan seksama. Jika masih ada keraguan dalam
              Ketentuan Investasi ini, ada baiknya melakukan konsultasi terlebih
              dahulu kepada admin.{" "}
            </p>
            <hr />
            <div
              className="bg-light p-3 snk-wrap"
              style={{ textAlign: "left" }}
            >
              <p>
                <b>SYARAT DAN KETENTUAN INVESTOR</b>
              </p>
              <div>
                <p>
                  <ol>
                    <li>
                      Layanan Urun Dana adalah suatu program layanan urun dana
                      yang diselenggarakan oleh Penyelenggara dengan melakukan
                      penawaran Unit Penyertaan Dana milik Penerbit kepada
                      Investor atau masyarakat umum melalui jaringan system
                      elektronik layanan urun dana melalui penawaran Unit
                      Penyertaan Dana berbasis teknologi informasi (equity
                      crowdfunding) milik Penyelenggara yang bersifat terbuka.
                    </li>
                    <li>
                      Penyelenggara adalah Koperasi GEMA, merupakan pihak yang
                      menyediakan, mengelola, dan mengoperasikan Layanan Urun
                      Dana.
                    </li>
                    <li>Pengguna adalah Investee dan Investor.</li>
                    <li>
                      Investee atau penerbit Unit Penyertaan Dana atau penerima
                      modal merupakan badan organisasi berbentuk unit Usaha
                      Mikro, Kecil dan Menengah (UMKM), merupakan pihak yang
                      melaksanakan Layanan Urun Dana menawarkan Unit Penyertaan
                      Dana berbasis teknologi melalui Penyelenggara.
                    </li>
                    <li>
                      Investor adalah pihak yang melakukan pembelian Unit
                      Penyertaan Dana Penerbit melalui Penyelenggara.
                    </li>
                    <li>
                      Unit Penyertaan Dana adalah nilai Unit Penyertaan Dana
                      milik Penerbit selama Layanan Urun Dana berlangsung.
                    </li>
                    <li>
                      Buyback adalah proses dimana Penerbit melakukan pembelian
                      kembali Unit Penyertaan Dana yang telah dijual oleh
                      Penerbit kepada Investor.
                    </li>
                    <li>
                      Hari Kalender adalah hari Senin sampai dengan hari Minggu,
                      termasuk hari libur Nasional yang ditetapkan Pemerintah
                      Indonesia sebagaimana perhitungan tahun kalender Masehi.
                    </li>
                    <li>
                      Hari Kerja adalah hari kerja dimana Penyelenggara
                      beroperasi.
                    </li>
                  </ol>
                </p>
                <p style={{ fontWeight: "bold" }}>
                  PELAKSANAAN LAYANAN URUN DANA
                </p>
                <p>
                  Investor dengan ini mengajukan pendaftaran kepada
                  Penyelenggara dan Penyelenggara menerima pendaftaran dari
                  Investor sebagai Anggota dalam rangka untuk melaksanakan
                  pembelian Unit Penyertaan Dana milik Penerbit melalui program
                  Layanan Urun Dana dengan maksud dan tujuan, lingkup Layanan
                  Urun Dana, syarat dan ketentuan, serta batas tanggung jawab
                  sesuai dengan syarat dan ketentuan.
                </p>
                <p style={{ fontWeight: "bold" }}>
                  MASA PENAWARAN Unit Penyertaan Dana
                </p>
                <p>
                  <ol>
                    <li>
                      Dalam hal ini Investor melakukan pembelian Unit Penyertaan
                      Dana Penerbit selama masa penawaran Unit Penyertaan Dana
                      oleh Penerbit yang dilakukan paling lama 60 (enam puluh)
                      Hari Kalender.
                    </li>
                    <li>
                      Investor mengerti dan memahami bahwa Penerbit dapat
                      membatalkan penawaran Unit Penyertaan Dana melalui Layanan
                      Urun Dana sebelum berakhirnya masa penawaran Unit
                      Penyertaan Dana dengan membayar sejumlah denda kepada
                      Penyelenggara.
                    </li>
                  </ol>
                </p>
                <p style={{ fontWeight: "bold" }}>
                  PEMBELIAN Unit Penyertaan Dana
                </p>
                <p>
                  <ol>
                    <li>
                      Pembelian Unit Penyertaan Dana oleh Investor dalam
                      penawaran Unit Penyertaan Dana melalui Layanan Urun Dana
                      dilakukan dengan menyetorkan sejumlah dana pada akun atau
                      rekening.
                    </li>
                    <li>
                      Tidak ada batasan pembelian Unit Penyertaan Dana di
                      Koperasi GEMA
                    </li>
                  </ol>
                </p>
                <p style={{ fontWeight: "bold" }}>
                  PENYERAHAN DANA DAN Unit Penyertaan Dana
                </p>
                <p>
                  <ol>
                    <li>
                      Investor mengerti dan memahami bahwa Penyelenggara wajib
                      menyerahkan dana dari Investor kepada Penerbit melalui
                      Penyelenggara, paling lambat 21 (dua puluh satu) Hari
                      Kerja setelah berakhirnya masa penawaran Unit Penyertaan
                      Dana.
                    </li>
                    <li>
                      Manfaat bersih dari penempatan dana dikembalikan kepada
                      Investor secara proporsional.
                    </li>
                    <li>
                      Berakhirnya masa penawaran adalah:
                      <ol type="a">
                        <li>
                          Tanggal tertentu yang telah ditetapkan dan disepakati
                          oleh Para Pihak; atau
                        </li>
                        <li>
                          tanggal tertentu berakhirnya masa penawaran Unit
                          Penyertaan Dana namun seluruh Unit Penyertaan Dana
                          yang ditawarkan melalui Layanan Urun Dana telah dibeli
                          oleh Investor.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Investor mengerti dan memahami bahwa Penerbit wajib
                      menyerahkan Unit Penyertaan Dana kepada Penyelenggara
                      untuk didistribusikan kepada Investor paling lambat 5
                      (lima) Hari Kerja setelah Penerbit menerima dana Investor
                      dari Penyelenggara.
                    </li>
                    <li>
                      Investor mengerti dan memahami bahwa Penyelenggara wajib
                      mendistribusikan Unit Penyertaan Dana kepada Investor
                      paling lambat 10 (sepuluh) Hari Kerja setelah menerima
                      Unit Penyertaan Dana dari Penerbit.
                    </li>
                    <li>
                      Pendistribusian Unit Penyertaan Dana kepada Investor oleh
                      Penyelenggara dapat dilakukan secara elektronik melalui
                      penitipan kolektif pada kustodian atau pendistribusian
                      secara fisik melalui pengiriman sertifikat Unit Penyertaan
                      Dana.
                    </li>
                    <li>
                      Investor mengerti dan memahami bahwa Penerbit diwajibkan
                      menetapkan jumlah minimum dana yang harus diperoleh dalam
                      penawaran Unit Penyertaan Dana melalui Layanan Urun Dana,
                      dan apabila jumlah minimum dana yang telah ditentukan oleh
                      Penerbit tersebut tidak terpenuhi, maka penawaran Unit
                      Penyertaan Dana melalui Layanan Urun Dana tersebut
                      dinyatakan batal demi hukum.
                    </li>
                    <li>
                      Investor mengerti dan memahami bahwa dalam hal penawaran
                      Unit Penyertaan Dana batal demi hukum, maka Penyelenggara
                      wajib mengembalikan dana beserta seluruh manfaat yang
                      timbul dari dana tersebut ke dalam saldo deposit Investor
                      di platform Penyelenggara secara proporsional kepada
                      Investor paling lambat 2 (dua) Hari Kerja setelah
                      penawaran Unit Penyertaan Dana dinyatakan batal demi
                      hukum.
                    </li>
                    <li>
                      Bagi Investor yang transaksinya tidak valid atau valid
                      sebagian, maka Koperasi GEMA akan menghubungi Investor
                      untuk melakukan konfirmasi. Apabila Investor tidak
                      melakukan konfirmasi balik selama 5 (lima) Hari Kerja
                      kepada Penyelenggara, maka transaksi Investor tersebut
                      dimasukkan ke dalam saldo deposit Investor di platform
                      Penyelenggara yang sewaktu-waktu dapat ditarik oleh
                      Investor.
                    </li>
                    <li>
                      Dalam hal transaksi pembelian Unit Penyertaan Dana
                      Investor dilakukan pada saat Unit Penyertaan Dana telah
                      dinyatakan habis/soldout, maka Investor berhak atas
                      pengembalian pembelian Unit Penyertaan Dana dengan
                      melakukan konfirmasi kepada Penyelenggara melalui media
                      komunikasi yang telah disediakan oleh Penyelenggara.
                    </li>
                    <li>
                      Pengembalian pembayaran pembelian Unit Penyertaan Dana
                      tersebut akan masuk ke dalam saldo deposit Investor di
                      platform Penyelenggara yang sewaktu-waktu dapat ditarik
                      oleh Investor.
                    </li>
                  </ol>
                </p>
                <p style={{ fontWeight: "bold" }}>
                  DAFTAR PEMEGANG Unit Penyertaan Dana
                </p>
                <p>
                  <ol>
                    <li>
                      Investor mengerti dan memahami bahwa Penerbit wajib
                      mencatatkan kepemilikan Unit Penyertaan Dana Investor
                      dalam daftar pemegang Unit Penyertaan Dana.
                    </li>
                    <li>
                      Persetujuan Investor terhadap Syarat dan Ketentuan ini
                      berarti Investor setuju dan sepakat bahwa Investor
                      memberikan kuasa kepada Penyelenggara untuk mewakili
                      Investor sebagai pemegang Unit Penyertaan Dana Penerbit
                      termasuk dalam Rapat Umum Pemegang Unit Penyertaan Dana
                      (“RUPS”) Penerbit dan penandatanganan akta serta dokumen
                      terkait lainnya
                    </li>
                  </ol>
                </p>
                <p style={{ fontWeight: "bold" }}>PENGHIMPUNAN DANA</p>
                <p>
                  <ol>
                    <li>
                      Investor mengerti dan memahami bahwa pembagian Imbal Hasil
                      kepada para pemegang Unit Penyertaan Dana tidak bersifat
                      lifetime karena Penerbit merupakan badan usaha berbadan
                      hukum berhak melakukan Buyback sebagaimana diatur dalam
                      akta anggaran dasar Penerbit dan peraturan
                      perundang-undangan yang berlaku.
                    </li>
                    <li>
                      Investor mengerti dan memahami bahwa pembagian Imbal Hasil
                      Penerbit diinformasikan di dalam kebijakan Imbal Hasil dan
                      didasarkan pada laba bersih Penerbit setelah dikurangi
                      dengan pencadangan. Mekanisme pembagian Imbal Hasil
                      lainnya (termasuk pembagian Imbal Hasil interim) mengacu
                      pada anggaran dasar Penerbit dan peraturan
                      perundang-undangan yang berlaku.
                    </li>
                    <li>
                      Investor mengerti dan memahami bahwa pembagian Imbal Hasil
                      final Penerbit mengacu pada persetujuan Rapat Umum
                      Pemegang Unit Penyertaan Dana (“RUPS”) Penerbit.
                    </li>
                    <li>
                      Investor mengerti dan memahami bahwa apabila terdapat
                      beban operasional usaha yang harus dikeluarkan setiap
                      periode tertentu, Penerbit tidak memiliki hak untuk
                      membebankannya kepada Investor, melainkan beban tersebut
                      dimasukkan ke dalam penghitungan biaya operasional yang
                      kemudian dilaporkan dalam laporan keuangan periode
                      tersebut.
                    </li>
                  </ol>
                </p>
                <p style={{ fontWeight: "bold" }}>KEWAJIBAN INVESTOR</p>
                <p>
                  Tanpa mengesampingkan hak dan kewajiban lainnya sebagaimana
                  telah tersebut dalam Perjanjian ini, maka kewajiban
                  Penyelenggara adalah sebagai berikut:
                  <ol>
                    <li>
                      Penyelenggara wajib memenuhi seluruh hak-hak Investor
                    </li>
                    <li>
                      Penyelenggara memonitor, menganalisa, dan memastikan bahwa
                      Pengguna berada di jalur yang sesuai dengan visi misi
                      Penyelenggara dan Layanan Urun Dana.
                    </li>
                    <li>
                      Penyelenggara bertanggung jawab melakukan ganti rugi atas
                      setiap kerugian Investor yang timbul disebabkan oleh
                      kelalaian karyawan ataupun direksi Penyelenggara.
                    </li>
                  </ol>
                </p>
                <p style={{ fontWeight: "bold" }}>HAK PENYELENGGARA</p>
                <p>
                  Tanpa mengesampingkan hak dan kewajiban lainnya sebagaimana
                  telah tersebut dalam Perjanjian ini, maka hak Penyelenggara
                  adalah :
                  <ol>
                    <li>
                      Penyelenggara berhak atas manfaat dari Investor atas
                      Layanan Urun Dana yang sedang berlangsung.
                    </li>
                  </ol>
                </p>
                <p style={{ fontWeight: "bold" }}>PERPAJAKAN</p>
                <p>
                  Pembebanan pajak yang timbul dalam Layanan Urun Dana ini
                  menjadi beban masing-masing pihak sesuai dengan ketentuan
                  hukum perpajakkan yang berlaku di wilayah Negara Republik
                  Indonesia.
                </p>
                <p style={{ fontWeight: "bold" }}>
                  HAK ATAS KEKAYAAN INTELEKTUAL
                </p>
                <p>
                  <ol>
                    <li>
                      Hak atas kekayaan intelektual yang timbul atas pelaksanaan
                      Layanan Urun Dana dan izin Penyelenggara, beserta
                      fasilitas-fasilitas lain yang dimiliki Penyelenggara dan
                      digunakan dalam Layanan Urun Dana ini adalah tetap dan
                      seterusnya milik Penyelenggara dan tidak ada penyerahan
                      hak dari Penyelenggara kepada Investor dalam Layanan Urun
                      Dana ini.
                    </li>
                    <li>
                      Investor tidak berhak untuk mengubah, mengembangkan,
                      membagikan dan/atau menjual baik seluruh maupun sebagian
                      hak atas kekayaan intelektual yang timbul atas
                      pengembangan, inovasi, perubahan berupa fitur dan/atau
                      fungsi terhadap sistem teknologi informasi.
                    </li>
                    <li>
                      Penyelenggara dengan ini menjamin bahwa hak atas kekayaan
                      intelektual yang terkandung dalam pelaksanaan Layanan Urun
                      Dana ini tidak melanggar hak atas kekayaan intelektual
                      milik pihak manapun, dan Penyelenggara membebaskan
                      Investor dari segala tuntutan, gugatan dari pihak manapun,
                      sehubungan dengan pelanggaran terhadap hak atas kekayaan
                      intelektual yang terkandung dalam Layanan Urun Dana sesuai
                      dengan syarat dan ketentuan ini.
                    </li>
                  </ol>
                </p>
                <p style={{ fontWeight: "bold" }}>
                  JANGKA WAKTU DAN PENGAKHIRAN
                </p>
                <p>
                  <ol>
                    <li>
                      Jangka waktu Layanan Urun Dana antara Penyelenggara dan
                      Investor ini berlaku selama Penerbit turut serta dalam
                      Layanan Urun Dana.
                    </li>
                    <li>
                      Layanan Urun Dana ini berakhir dengan sendirinya, apabila
                      :
                      <ol type="a">
                        <li>
                          Penerbit melakukan Buyback Unit Penyertaan Dana;
                        </li>
                        <li>Diakhiri oleh Penyelenggara</li>
                      </ol>
                    </li>
                    <li>
                      Dalam hal Layanan Urun Dana ini berakhir dan/atau
                      dinyatakan berakhir, maka Para Pihak sepakat bahwa
                      ketentuan Informasi Rahasia sebagaimana diatur dalam
                      syarat dan ketentuan ini tetap berlaku dan mengikat Para
                      Pihak hingga kapanpun meskipun Layanan Urun Dana telah
                      berakhir.
                    </li>
                    <li>
                      Pengakhiran/pembatalan Layanan Urun Dana ini tidak
                      menghapuskan kewajiban-kewajiban masing-masing Pihak yang
                      telah atau akan timbul dan belum dilaksanakan pada saat
                      berakhirnya Layanan Urun Dana ini.
                    </li>
                    <li>
                      Dalam hal pengakhiran/pembatalan Layanan Urun Dana ini,
                      Para Pihak sepakat untuk mengesampingkan keberlakuan
                      ketentuan Pasal 1266 Kitab Undang-Undang Hukum Perdata,
                      sepanjang ketentuan tersebut mensyaratkan adanya suatu
                      putusan atau penetapan pengadilan untuk menghentikan atau
                      mengakhiri suatu perjanjian, sehingga
                      pengakhiran/pembatalan Layanan Urun Dana ini cukup
                      dilakukan dengan pemberitahuan tertulis dari salah satu
                      Pihak.
                    </li>
                  </ol>
                </p>
                <p style={{ fontWeight: "bold" }}>INFORMASI RAHASIA</p>
                <p>
                  <ol>
                    <li>
                      Salah satu Pihak (selanjutnya disebut “ Pihak Pemberi ”)
                      dapat memberikan Informasi Rahasia kepada Pihak lainnya
                      (selanjutnya disebut “Pihak Penerima”) dalam melaksanakan
                      Layanan Urun Dana ini. Para Pihak sepakat bahwa pemberian,
                      penerimaan dan penggunaan Informasi Rahasia tersebut
                      dilakukan sesuai dengan syarat dan ketentuan ini.
                    </li>
                    <li>
                      Informasi Rahasia yang dimaksud dalam ketentuan ini
                      berarti informasi yang bersifat non- publik, termasuk
                      namun tidak terbatas pada skema atau gambar produk,
                      penjelasan material, spesifikasi, laporan keuangan dan
                      informasi mengenai klien, kebijaksanaan dan praktek bisnis
                      Pihak Pemberi dan informasi mana dapat dimuat dalam media
                      cetak, tulis, disk / tape / compact disk komputer atau
                      media lainnya yang sesuai.
                    </li>
                    <li>
                      Tidak termasuk sebagai Informasi Rahasia adalah materi
                      atau informasi yang mana dapat dibuktikan oleh Pihak
                      Penerima bahwa:
                      <p>
                        <ol type="a">
                          <li>
                            Pada saat penerimaannya sebagai milik publik (
                            public domain ) atau menjadi milik publik ( public
                            domain ) atau menjadi milik publik ( public domain )
                            tanpa adanya pelanggaran oleh Pihak Penerima;
                          </li>
                          <li>
                            Telah diketahui oleh Pihak Penerima pada saat
                            diberikan oleh Pihak Pemberi;
                          </li>
                          <li>
                            Telah didapatkan dari pihak ketiga tanpa adanya
                            pelanggaran terhadap pengungkapan Informasi Rahasia;
                          </li>
                          <li>Dikembangkan sendiri oleh Pihak Penerima.</li>
                        </ol>
                      </p>
                    </li>
                    <li>
                      Pihak Penerima dengan ini menyatakan bahwa tidak akan
                      mengungkapkan Informasi Rahasia apapun yang diberikan
                      Pihak Pemberi ke pihak lainnya selain daripada yang
                      diperlukan dalam melaksanakan tugas, peran dan
                      kewajibannya dalam Layanan Urun Dana ini, tanpa terlebih
                      dahulu memperoleh persetujuan dari Pihak Pemberi dan Pihak
                      Penerima akan melakukan semua tindakan-tindakan pencegahan
                      yang wajar untuk mencegah terjadinya pelanggaran atau
                      kelalaian dalam pengungkapan, penggunaan, pembuatan
                      salinan ( copy ) atau pengalihan Informasi Rahasia
                      tersebut.
                    </li>
                    <li>
                      Masing-masing Pihak berkewajiban untuk menyimpan segala
                      rahasia data atau sistem yang diketahuinya baik secara
                      langsung maupun tidak langsung sehubungan Layanan Urun
                      Dana yang dilaksanakan sesuai dengan syarat dan ketentuan
                      ini dan bertanggung jawab atas segala kerugian yang
                      diakibatkan karena pembocoran Informasi Rahasia tersebut,
                      baik oleh masing- masing Pihak maupun karyawannya maupun
                      perwakilannya.
                    </li>
                  </ol>
                </p>
                <p style={{ fontWeight: "bold" }}>PERNYATAAN DAN JAMINAN</p>
                <p>
                  <ol>
                    <li>
                      Tanpa mengesampingkan pernyataan dan jaminan yang
                      diberikan oleh salah satu Pihak ke Pihak lainnya dalam
                      Layanan Urun Dana ini, masing-masing Pihak dengan ini
                      menyatakan dan menjamin Pihak lainnya dalam Layanan Urun
                      Dana hal-hal sebagai berikut:
                      <ol type="a">
                        <li>
                          Bahwa berdasarkan hukum negara Republik Indonesia,
                          masing-masing Pihak cakap menurut hukum untuk memiliki
                          harta kekayaan dan melakukan perbuatan hukum usahanya
                          di wilayah Republik Indonesia serta memiliki segala
                          perizinan yang diperlukan untuk menjalankan perbuatan
                          hukum.;
                        </li>
                        <li>
                          Bahwa masing-masing Pihak telah mengambil segala
                          tindakan yang diperlukan untuk memastikan wakil dari
                          masing-masing Pihak dalam Layanan Urun Dana telah
                          memiliki kuasa dan wewenang penuh untuk mengikatkan
                          diri dalam persetujuan Syarat dan Ketentuan ini;
                        </li>
                        <li>
                          Bahwa masing-masing Pihak telah memastikan bahwa
                          Layanan Urun Dana ini tidak melanggar ketentuan dari
                          anggaran dasar masing-masing Pihak dalam Layanan Urun
                          Dana dan tidak bertentangan dengan perjanjian apapun
                          yang dibuat oleh masing- masing Pihak dengan pihak
                          ketiga;
                        </li>
                        <li>
                          Pelaksanaan ketentuan-ketentuan dalam Layanan Urun
                          Dana ini dilaksanakan secara profesional dengan penuh
                          tanggung jawab dan atas dasar hubungan yang saling
                          menguntungkan.
                        </li>
                        <li>
                          Persetujuan syarat dan ketentuanini tidak bertentangan
                          atau melanggar atau berbenturan dengan kaidah-kaidah
                          hukum dan peraturan perundangundangan serta
                          kebijakan-kebijakan pemerintah Republik Indonesia atau
                          pihak yang berwenang lainnya;
                        </li>
                        <li>
                          Bersedia untuk menerapkan, mendukung dan mematuhi
                          ketentuan hokum dan peraturan perundang-undangan yang
                          berlaku di Republik Indonesia termasuk namun tidak
                          terbatas pada peraturan mengenai tindak pidana
                          korupsi, anti pencucian uang dan anti penyuapan;
                        </li>
                        <li>
                          Masing-masing Pihak akan melaksanakan Layanan Urun
                          Dana ini dengan itikad baik dan secara jujur. Tidak
                          satupun ketentuan dalam Layanan Urun Dana ini akan
                          digunakan oleh salah satu Pihak untuk mengambil
                          keuntungan secara tidak wajar dan mengakibatkan
                          kerugian bagi Pihak lainnya dan tidak satupun
                          ketentuan dalam Layanan Urun Dana ini dimaksudkan
                          untuk memberikan keuntungan secara tidak wajar kepada
                          Pihak lainnya.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Tanpa mengesampingkan pernyataan dan jaminan yang
                      diberikan oleh Penerbit ke Penyelenggara, Investor dengan
                      ini menyatakan dan menjamin kepada Penyelenggara hal-hal
                      sebagai berikut:
                      <ol type="a">
                        <li>
                          Bahwa Investor akan membebaskan Penyelenggara dari
                          klaim, tuntutan, gugatan dari pihak ketiga atas
                          kelalaian Investor dan/atau karyawan Investor dalam
                          melaksanakan Layanan Urun Dana ini;
                        </li>
                        <li>
                          Bahwa Investor menyatakan tidak berkeberatan dalam hal
                          Otoritas Jasa Keuangan dan/atau pihak lain yang sesuai
                          undang-undang berwenang untuk melakukan pemeriksaan,
                          akan melakukan pemeriksaan terhadap pelaksanaan
                          Layanan Urun Dana ini;
                        </li>
                        <li>
                          Bahwa Investor bersedia untuk kemungkinan pengubahan,
                          pembuatan atau pengambil alih kegiatan yang
                          dilaksanakan oleh Investor atau penghentian Layanan
                          Urun Dana.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </p>
                <p style={{ fontWeight: "bold" }}>KEADAAN MEMAKSA</p>
                <p>
                  <ol>
                    <li>
                      Keadaan Memaksa atau Force Majeure adalah
                      kejadian-kejadian yang terjadi diluar kemampuan dan
                      kekuasaan Para Pihak sehingga menghalangi Para Pihak untuk
                      melaksanakan Layanan Urun Dana ini, termasuk namun tidak
                      terbatas pada adanya kebakaran, banjir, gempa
                      bumi,likuifaksi, badai, huruhara, peperangan, epidemi,
                      pertempuran, pemogokan, sabotase, embargo, peledakan yang
                      mengakibatkan kerusakan sistem teknologi informasi yang
                      menghambat pelaksanaan Layanan Urun Dana ini, serta
                      kebijaksanaan pemerintah Republik Indonesia yang secara
                      langsung berpengaruh terhadap pelaksanaan Layanan Urun
                      Dana ini.
                    </li>
                    <li>
                      Masing-masing Pihak dibebaskan untuk membayar denda
                      apabila terlambat dalam melaksanakan kewajibannya dalam
                      Layanan Urun Dana ini, karena adanya hal-hal Keadaan
                      Memaksa.
                    </li>
                    <li>
                      Keadaan Memaksa sebagaimana dimaksud harus diberitahukan
                      oleh Pihak yang mengalami Keadaan Memaksa kepada Pihak
                      lainnya dalam Layanan Urun Dana ini paling lambat 7
                      (tujuh) Hari Kalender dengan melampirkan pernyataan atau
                      keterangan tertulis dari pemerintah untuk dipertimbangkan
                      oleh Pihak lainnya beserta rencana pemenuhan kewajiban
                      yang tertunda akibat terjadinya Keadaan Memaksa.
                    </li>
                    <li>
                      Keadaan Memaksa yang menyebabkan keterlambatan pelaksanaan
                      Layanan Urun Dana ini baik untuk seluruhnya maupun
                      sebagian bukan merupakan alasan untuk pembatalan Layanan
                      Urun Dana ini sampai dengan diatasinya Keadaan Memaksa
                      tersebut.
                    </li>
                  </ol>
                </p>
                <p style={{ fontWeight: "bold" }}>
                  PENGALIHAN LAYANAN URUN DANA
                </p>
                <p>
                  <ol>
                    <li>
                      Investor setuju dan sepakat untuk tidak mengalihkan
                      sebagian atau keseluruhan hak dan kewajiban Penerbit dalam
                      Layanan Urun Dana ini kepada pihak lainnya atau pihak
                      manapun.
                    </li>
                    <li>
                      Dalam hal adanya permintaan peralihan atas hak kepemilikan
                      Unit Penyertaan Dana dikarenakan Investor meninggal dunia,
                      maka ahli waris mengajukan permohonan perubahan
                      kepemilikan Unit Penyertaan Dana kepada Penyelenggara
                      dengan melengkapi dokumen sebagai sebagai berikut :
                      <ol type="a">
                        <li>
                          Surat permohonan peralihan kepemilikan Unit Penyertaan
                          Dana dikarenakan Investor meninggal dunia;
                        </li>
                        <li>
                          Softcopy surat kematian dari instansi berwenang;
                        </li>
                        <li>
                          Softcopy surat keterangan ahli waris dari instansi
                          berwenang dan/atau surat penetapan pengadilan tentang
                          ahli waris;
                        </li>
                        <li>
                          Softcopy E-KTP Investor (almarhum/almarhumah) dan ahli
                          waris
                        </li>
                        <li>
                          Softcopy Kartu Keluarga (KK) Investor
                          (almarhum/almarhumah)
                        </li>
                        <li>
                          Surat Penunjukan dan/atau Surat Kuasa dari ahli waris
                          (apabila ahli warislebih dari satu) untuk menunjuk
                          dan/atau menguasakan peralihan kepemilikan Unit
                          Penyertaan Dana kepada salah satu ahli waris.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </p>
                <p style={{ fontWeight: "bold" }}>
                  DOMISILI HUKUM DAN PENYELESAIAN SENGKETA
                </p>
                <p>
                  <ol>
                    <li>
                      Layanan Urun Dana ini dibuat, ditafsirkan dan dilaksanakan
                      berdasarkan hukum negara Republik Indonesia.
                    </li>
                    <li>
                      Setiap perselisihan yang timbul sehubungan dengan Layanan
                      Urun Dana ini, akan diupayakan untuk diselesaikan terlebih
                      dahulu oleh Para Pihak dengan melaksanakan musyawarah
                      untuk mufakat.
                    </li>
                    <li>
                      Apabila penyelesaian perselisihan secara musyawarah tidak
                      berhasil mencapai mufakat sampai dengan 30 (tiga puluh)
                      Hari Kalender sejak dimulainya musyawarah tersebut, maka
                      Para Pihak sepakat untuk menyelesaikan perselisihan
                      tersebut melalui proses pengadilan.
                    </li>
                    <li>
                      Para Pihak sepakat untuk menyelesaikan perselisihan di
                      Pengadilan Negeri Kendal di Provinsi Jawa Tengah tanpa
                      mengurangi hak darisalah satu untuk mengajukan gugatan
                      pada domisili pengadilan lainnya ( nonexlusive
                      jurisdiction ).
                    </li>
                    <li>
                      Tanpa mengesampingkan penyelesaian sengketa atau
                      perselisihan melalui pengadilan negeri, Para Pihak setuju
                      dan sepakat apabila penyelesaian sengketa atau
                      perselisihan di badan arbitrase dan badan alternatif
                      penyelesaian sengketa yang ditunjuk.
                    </li>
                    <li>
                      Hasil putusan pengadilan negeri maupun badan arbitrase dan
                      badan alternatif penyelesaian sengketa yang ditunjuk oleh
                      Otoritas Jasa Keuangan maupun regulator berwenang lainnya
                      bersifat final dan mempunyai kekuatan hokum tetap dan
                      mengikat bagi Para Pihak.
                    </li>
                  </ol>
                </p>
                <p style={{ fontWeight: "bold" }}>KELALAIAN / WANPRESTASI</p>
                <p>
                  <ol>
                    <li>
                      Dalam hal terjadi salah satu hal atau peristiwa yang
                      ditetapkan di bawah ini, maka merupakan suatu kejadian
                      kelalaian (wanprestasi) terhadap Layanan Urun Dana ini:
                      <ol type="a">
                        <li>
                          Kelalaian dalam Layanan Urun Dana Dalam hal salah satu
                          Pihak terbukti sama sekali tidak melaksanakan
                          kewajiban, atau melaksanakan kewajiban tetapi tidak
                          sebagaimana disepakati, atau melaksanakan kewajiban
                          tetapi tidak sesuai dengan waktu yang disepakati, atau
                          melakukan sesuatu yang tidak diperbolehkan dalam
                          Syarat dan Ketentuan.
                        </li>
                        <li>
                          Pernyataan Tidak Benar Dalam hal ternyata bahwa
                          sesuatu pernyataan atau jaminan yang diberikan oleh
                          salah satu Pihak kepada Pihak lainnya dalam Layanan
                          Urun Dana ini terbukti tidak benar atau tidak sesuai
                          dengan kenyataannya dan menimbulkan kerugian langsung
                          yang diderita salah satu Pihak.
                        </li>
                        <li>
                          Kepailitan Dalam hal salah satu Pihak dalam Layanan
                          Urun Dana ini oleh instansi yang berwenang dinyatakan
                          berada dalam keadaan pailit atau diberikan penundaan
                          membayar hutang- hutang ( surseance van betaling ).
                        </li>
                        <li>
                          Permohonan Kepailitan Dalam hal salah satu Pihak dalam
                          Layanan Urun Dana ini mengajukan permohonan kepada
                          instansi yang berwenang untuk dinyatakan pailit atau
                          untuk diberikan penundaan membayar hutang-hutang (
                          surseance van betaling ) atau dalam hal pihak lain
                          mengajukan permohonan kepada instansi yang berwenang
                          agar salah satu Pihak dalam Layanan Urun Dana ini
                          dinyatakan dalam keadaan pailit.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Dalam hal suatu kejadian kelalaian terjadi dan
                      berlangsung, maka Pihak yang tidak lalai berhak
                      menyampaikan peringatan sebanyak 3 (tiga) kali dengan
                      tenggang waktu 7 (tujuh) Hari Kalender diantara
                      masing-masing peringatan.
                    </li>
                    <li>
                      Setelah menyampaikan 3 (tiga) kali peringatan, Pihak yang
                      tidak lalai berhak mengajukan tuntutan berupa meminta
                      pemenuhan prestasi dilakukan atau meminta prestasi
                      dilakukan disertai ganti kerugian atau meminta ganti
                      kerugian saja atau menuntut pembatalan Layanan Urun Dana
                      disertai ganti kerugian.
                    </li>
                  </ol>
                </p>
                <p style={{ fontWeight: "bold" }}>PENALTI</p>
                <p>
                  Apabila dalam Layanan Urun Dana ini, Investor melanggar
                  ketentuan dalam Layanan Urun Dana ini maka Penyelenggara
                  berhak menon-aktifkan atau membekukan akun Investor, bahkan
                  pengakhiran Layanan Urun Dana Investor oleh Penyelenggara
                  dalam Layanan Urun Dana ini.
                </p>
                <p style={{ fontWeight: "bold" }}>
                  MEKANISME DALAM HAL PENYELENGGARA TIDAK DAPAT MENJALANKAN
                  OPERASIONALNYA
                </p>
                <p>
                  Mekanisme penyelesaian Layanan Urun Dana dalam hal
                  Penyelenggara tidak dapat menjalankan operasional adalah
                  sebagai berikut :
                  <ol>
                    <li>
                      Penyelenggara melakukan pemberitahuan atau pengumuman
                      secara tertulis di website Penyelenggara dan media sosial
                      lainnya kepada seluruh Pengguna atau khalayak umum bahwa
                      Penyelenggara tidak dapat memberitahukan operasionalnya
                      dengan mencantumkan alasan jelas;
                    </li>
                    <li>
                      Bahwa pengaturan tata cara Buyback mengacu pada akta
                      anggaran dasar Penerbit dan undang-undang dasar tentang
                      perseroan terbatas yang berlaku di Negara Republik
                      Indonesia;
                    </li>
                    <li>
                      Buyback seluruh Unit Penyertaan Dana yang dimiliki
                      Investor dengan harga pasar atau disepakati secara
                      tertulis oleh Para Pihak di kemudian hari.
                    </li>
                    <li>
                      Menunjuk penyelenggara lain yang telah mendapat izin dari
                      Otoritas Jasa Keuangan seperti Penyelenggara, dengan
                      syarat dan ketentuan yang sudah disepakati bersama dengan
                      Investor.
                    </li>
                  </ol>
                </p>
                <p style={{ fontWeight: "bold" }}>DISCLAIMER</p>
                <p>
                  <ol>
                    <li>
                      Penyelenggara bertindak sebagi penyelenggara Layanan Urun
                      Dana, bukan sebagai pihak yang menjalankan bisnis
                      (Penerbit).
                    </li>
                    <li>
                      Semua data dan informasi yang tersaji di website, aplikasi
                      dan prospectus diperoleh Penyelenggara dari Penerbit dan
                      media online.
                    </li>
                    <li>
                      Keputusan pembelian Unit Penyertaan Dana, sepenuhnya
                      merupakan hak dan pilihan Investor, sehingga segala risiko
                      dan konsekuensi atas pembelian Unit Penyertaan Dana
                      merupakan tanggung jawab dan risiko Investor sepenuhnya.
                    </li>
                    <li>
                      Dengan membeli Unit Penyertaan Dana di Penyelenggara,
                      berarti Investor dianggap telah membaca, memahami dan
                      menyetujui seluruh syarat dan ketentuan serta memahami
                      risiko investasi termasuk risiko kehilangan atau seluruh
                      modal.
                    </li>
                  </ol>
                </p>
                <p style={{ fontWeight: "bold" }}>LAIN - LAIN</p>
                <p>
                  <ol>
                    <li>
                      Para Pihak wajib tunduk dan patuh terhadap peraturan
                      perundang-undangan yang berlaku di negara Republik
                      Indonesia terkait pelaksanaan Layanan Urun Dana ini.
                    </li>
                    <li>
                      Layanan Urun Dana ini diinterpretasikan dan dilaksanakan
                      berdasarkan hokum yang berlaku di Negara Republik
                      Indonesia.
                    </li>
                    <li>
                      Keabsahan, penafsiran dan pelaksanaan Layanan Urun Dana
                      ini, diatur serta tunduk pada hukum dan peraturan
                      perundang-undangan yang berlaku di negara Republik
                      Indonesia;
                    </li>
                    <li>
                      Dalam hal ada salah satu atau lebih ketentuan dalam syarat
                      dan ketentuanini dinyatakan tidak sah, tidak berlaku atau
                      tidak dapat dilaksanakan berdasarkan hukum dan/atau
                      peraturan perundang-undangan yang berlaku di Republik
                      Indonesia, maka kedua belah pihak setuju bahwa keabsahan
                      ketentuan lainnya dalam Syarat dan Ketentuan ini tetap
                      berlaku dan dapat dilaksanakan serta tidak akan
                      terpengaruh;
                    </li>
                    <li>
                      Penyelenggara berhak untuk mengubah syarat dan
                      ketentuanini sewaktuwaktu dalam rangka penyempurnaan dan
                      penyesuaian dengan ketentuan hukum yang berlaku di Negara
                      Republik Indonesia
                    </li>
                    <li>
                      Dokumen-dokumen atau kesepakatan-kesepakatan terkait
                      dengan Layanan Urun Dana ini yang telah dibuat oleh Para
                      Pihak sebelum disetujuinya syarat dan ketentuan ini
                      dinyatakan dicabut dan tidak berlaku terhitung sejak
                      disetujuinya syarat dan ketentuan.
                    </li>
                  </ol>
                </p>
                <p>
                  SYARAT DAN KETENTUAN INI DIBUAT DAN DIBERIKAN PERSETUJUAN
                  SECARA ELEKTRONIK OLEH INVESTOR DALAM KEADAAN SEHAT DAN SADAR
                  SERTA TANPA ADA PAKSAAN DARI PIHAK MANAPUN JUGA.
                </p>
                <p>
                  SETELAH INVESTOR MEMBUBUHKAN TANDA CENTANG (√) PADA KOTAK
                  PERSETUJUAN SECARA ELEKTRONIK ATAS SYARAT DAN KETENTUAN INI,
                  MAKA INVESTOR DENGAN INI MENYATAKAN SETUJU TELAH MEMBACA,
                  MENGERTI, MEMAHAMI SECARA SEKSAMA DAN TUNDUK PADA SETIAP DAN
                  KESELURUHAN SYARAT DAN KETENTUAN, SERTA TUNDUK PADA PERATURAN
                  KOPERASI GEMA BESERTA PERUBAHAN-PERUBAHANNYA.
                </p>
                <p>
                  DAN OLEH KARENA ITU, DALAM PELAKSANAAN LAYANAN URUN DANA,
                  INVESTOR MENYATAKAN DAN MENJAMIN BAHWA INVESTOR SELALU TETAP
                  MEMATUHI DAN MELAKSANAKAN SETIAP KETENTUAN YANG ADA DALAM
                  SYARAT DAN KETENTUAN INI DENGAN PENUH TANGGUNG JAWAB DAN
                  PROFESIONAL.
                </p>
              </div>
            </div>
            <hr />
            <div className="form-check px-4 pb-4" style={{ textAlign: "left" }}>
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label className="form-check-label" for="flexCheckDefault">
                Dengan ini, saya <b>Anissa Nurlia Kusumaningtyas</b> menyatakan
                bahwa saya telah membaca, memahami, dan menyetujui Ketentuan
                Investasi di atas*
              </label>
            </div>
          </div>
          <div
            className="bg-dark text-white d-flex justify-content-between"
            style={{ color: "grey" }}
          >
            <div className="spacer"></div>
            <div className="btn-checkout mr-auto">
              <button className="text-link2 text-white p-4 bg-orange">
                Beli
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default function OrderConfirmation() {
  const [checked, setChecked] = useState("");
  const [accept1, setAccept1] = useState(false);
  const [accept2, setAccept2] = useState(false);
  const { token } = useToken();
  const { user } = useAuth();
  const [carts, setCarts] = useRecoilState(chart);
  const [confirmed, setConfirmed] = useState(false);
  const [isTransaction, setTransaction] = useRecoilState(transaction);
  const history = useHistory();

  const checkout = async () => {
    const formData = {};
    Object.keys(carts).forEach((key) => {
      formData[key] = String(carts[key]);
    });

    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    try {
      const result = await axios.post(
        "https://admin.gemaindonesia.id/admin/v1/entities/transactions/add",
        {
          data: formData,
        },
        {
          headers: headers,
        }
      );
      if (result) {
        console.log(result);
        setConfirmed(true);
        // console.log(result);
      }
    } catch (error) {
      console.log(error.response);
      Swal.fire("Error", "Data tidak valid, silahkan coba lagi", "warning");
      history.push("/");
      // console.clear();
    }
  };

  useEffect(() => {
    if (!isTransaction) {
      history.push("/");
    }
    return () => {
      setTransaction(false);
    };
  }, [isTransaction]);

  return (
    <>
      {confirmed ? (
        <OrderConfirmed />
      ) : (
        <div className="bg-grey2">
          <div className="checkout-wrap my-5">
            <div className="p-4">
              <div
                className="p-4 bg-dark text-white"
                style={{ fontSize: "14px" }}
              >
                Anda akan melakukan pembelian : <br /> Unit Penyertaan Dana{" "}
                <b>{carts?.title}</b> Sejumlah{" "}
                {carts?.totalLot.toLocaleString()} Lot <br /> atau{" "}
                {carts?.totalKoin.toLocaleString()} Keping Koin <br />
                {carts.isMember === "Tidak Aktif"
                  ? "dan Keanggotaan GEMA Indonesia Rp. 250.000,-"
                  : null}{" "}
                <br />
                Dengan Nominal{" "}
                <b>
                  Rp.{" "}
                  {carts.isMember === "Tidak Aktif"
                    ? (carts.totalPrice + 250000).toLocaleString()
                    : carts.totalPrice.toLocaleString()}
                </b>
              </div>
              <div className="p-3 text-white bg-orange">
                <b>
                  Total Rp.{" "}
                  {carts.isMember === "Tidak Aktif"
                    ? (carts.totalPrice + 250000).toLocaleString()
                    : carts.totalPrice.toLocaleString()}
                </b>
              </div>
            </div>
            <div className="px-4 text-center">
              <p style={{ fontWeight: "bold" }}>
                <b>Ketentuan Investasi</b>
              </p>
              <p>
                Harap membaca dan memahami Ketentuan Investasi melalui Koperasi
                GEMA Indonesia ini dengan seksama. Jika masih ada keraguan dalam
                Ketentuan Investasi ini, ada baiknya melakukan konsultasi
                terlebih dahulu kepada admin.{" "}
              </p>
              <hr />
              <div
                className="bg-light p-3 snk-wrap"
                style={{ textAlign: "left" }}
              >
                <p>
                  <b>SYARAT DAN KETENTUAN INVESTOR</b>
                </p>
                <div>
                  <p>
                    <ol>
                      <li>
                        Layanan Urun Dana adalah suatu program layanan urun dana
                        yang diselenggarakan oleh Penyelenggara dengan melakukan
                        penawaran Unit Penyertaan Dana milik Penerbit kepada
                        Investor atau masyarakat umum melalui jaringan system
                        elektronik layanan urun dana melalui penawaran Unit
                        Penyertaan Dana berbasis teknologi informasi (equity
                        crowdfunding) milik Penyelenggara yang bersifat terbuka.
                      </li>
                      <li>
                        Penyelenggara adalah Koperasi GEMA, merupakan pihak yang
                        menyediakan, mengelola, dan mengoperasikan Layanan Urun
                        Dana.
                      </li>
                      <li>Pengguna adalah Investee dan Investor.</li>
                      <li>
                        Investee atau penerbit Unit Penyertaan Dana atau
                        penerima modal merupakan badan organisasi berbentuk unit
                        Usaha Mikro, Kecil dan Menengah (UMKM), merupakan pihak
                        yang melaksanakan Layanan Urun Dana menawarkan Unit
                        Penyertaan Dana berbasis teknologi melalui
                        Penyelenggara.
                      </li>
                      <li>
                        Investor adalah pihak yang melakukan pembelian Unit
                        Penyertaan Dana Penerbit melalui Penyelenggara.
                      </li>
                      <li>
                        Unit Penyertaan Dana adalah nilai Unit Penyertaan Dana
                        milik Penerbit selama Layanan Urun Dana berlangsung.
                      </li>
                      <li>
                        Buyback adalah proses dimana Penerbit melakukan
                        pembelian kembali Unit Penyertaan Dana yang telah dijual
                        oleh Penerbit kepada Investor.
                      </li>
                      <li>
                        Hari Kalender adalah hari Senin sampai dengan hari
                        Minggu, termasuk hari libur Nasional yang ditetapkan
                        Pemerintah Indonesia sebagaimana perhitungan tahun
                        kalender Masehi.
                      </li>
                      <li>
                        Hari Kerja adalah hari kerja dimana Penyelenggara
                        beroperasi.
                      </li>
                    </ol>
                  </p>
                  <p style={{ fontWeight: "bold" }}>
                    PELAKSANAAN LAYANAN URUN DANA
                  </p>
                  <p>
                    Investor dengan ini mengajukan pendaftaran kepada
                    Penyelenggara dan Penyelenggara menerima pendaftaran dari
                    Investor sebagai Anggota dalam rangka untuk melaksanakan
                    pembelian Unit Penyertaan Dana milik Penerbit melalui
                    program Layanan Urun Dana dengan maksud dan tujuan, lingkup
                    Layanan Urun Dana, syarat dan ketentuan, serta batas
                    tanggung jawab sesuai dengan syarat dan ketentuan.
                  </p>
                  <p style={{ fontWeight: "bold" }}>
                    MASA PENAWARAN Unit Penyertaan Dana
                  </p>
                  <p>
                    <ol>
                      <li>
                        Dalam hal ini Investor melakukan pembelian Unit
                        Penyertaan Dana Penerbit selama masa penawaran Unit
                        Penyertaan Dana oleh Penerbit yang dilakukan paling lama
                        60 (enam puluh) Hari Kalender.
                      </li>
                      <li>
                        Investor mengerti dan memahami bahwa Penerbit dapat
                        membatalkan penawaran Unit Penyertaan Dana melalui
                        Layanan Urun Dana sebelum berakhirnya masa penawaran
                        Unit Penyertaan Dana dengan membayar sejumlah denda
                        kepada Penyelenggara.
                      </li>
                    </ol>
                  </p>
                  <p style={{ fontWeight: "bold" }}>
                    PEMBELIAN Unit Penyertaan Dana
                  </p>
                  <p>
                    <ol>
                      <li>
                        Pembelian Unit Penyertaan Dana oleh Investor dalam
                        penawaran Unit Penyertaan Dana melalui Layanan Urun Dana
                        dilakukan dengan menyetorkan sejumlah dana pada akun
                        atau rekening.
                      </li>
                      <li>
                        Tidak ada batasan pembelian Unit Penyertaan Dana di
                        Koperasi GEMA
                      </li>
                    </ol>
                  </p>
                  <p style={{ fontWeight: "bold" }}>
                    PENYERAHAN DANA DAN Unit Penyertaan Dana
                  </p>
                  <p>
                    <ol>
                      <li>
                        Investor mengerti dan memahami bahwa Penyelenggara wajib
                        menyerahkan dana dari Investor kepada Penerbit melalui
                        Penyelenggara, paling lambat 21 (dua puluh satu) Hari
                        Kerja setelah berakhirnya masa penawaran Unit Penyertaan
                        Dana.
                      </li>
                      <li>
                        Manfaat bersih dari penempatan dana dikembalikan kepada
                        Investor secara proporsional.
                      </li>
                      <li>
                        Berakhirnya masa penawaran adalah:
                        <ol type="a">
                          <li>
                            Tanggal tertentu yang telah ditetapkan dan
                            disepakati oleh Para Pihak; atau
                          </li>
                          <li>
                            tanggal tertentu berakhirnya masa penawaran Unit
                            Penyertaan Dana namun seluruh Unit Penyertaan Dana
                            yang ditawarkan melalui Layanan Urun Dana telah
                            dibeli oleh Investor.
                          </li>
                        </ol>
                      </li>
                      <li>
                        Investor mengerti dan memahami bahwa Penerbit wajib
                        menyerahkan Unit Penyertaan Dana kepada Penyelenggara
                        untuk didistribusikan kepada Investor paling lambat 5
                        (lima) Hari Kerja setelah Penerbit menerima dana
                        Investor dari Penyelenggara.
                      </li>
                      <li>
                        Investor mengerti dan memahami bahwa Penyelenggara wajib
                        mendistribusikan Unit Penyertaan Dana kepada Investor
                        paling lambat 10 (sepuluh) Hari Kerja setelah menerima
                        Unit Penyertaan Dana dari Penerbit.
                      </li>
                      <li>
                        Pendistribusian Unit Penyertaan Dana kepada Investor
                        oleh Penyelenggara dapat dilakukan secara elektronik
                        melalui penitipan kolektif pada kustodian atau
                        pendistribusian secara fisik melalui pengiriman
                        sertifikat Unit Penyertaan Dana.
                      </li>
                      <li>
                        Investor mengerti dan memahami bahwa Penerbit diwajibkan
                        menetapkan jumlah minimum dana yang harus diperoleh
                        dalam penawaran Unit Penyertaan Dana melalui Layanan
                        Urun Dana, dan apabila jumlah minimum dana yang telah
                        ditentukan oleh Penerbit tersebut tidak terpenuhi, maka
                        penawaran Unit Penyertaan Dana melalui Layanan Urun Dana
                        tersebut dinyatakan batal demi hukum.
                      </li>
                      <li>
                        Investor mengerti dan memahami bahwa dalam hal penawaran
                        Unit Penyertaan Dana batal demi hukum, maka
                        Penyelenggara wajib mengembalikan dana beserta seluruh
                        manfaat yang timbul dari dana tersebut ke dalam saldo
                        deposit Investor di platform Penyelenggara secara
                        proporsional kepada Investor paling lambat 2 (dua) Hari
                        Kerja setelah penawaran Unit Penyertaan Dana dinyatakan
                        batal demi hukum.
                      </li>
                      <li>
                        Bagi Investor yang transaksinya tidak valid atau valid
                        sebagian, maka Koperasi GEMA akan menghubungi Investor
                        untuk melakukan konfirmasi. Apabila Investor tidak
                        melakukan konfirmasi balik selama 5 (lima) Hari Kerja
                        kepada Penyelenggara, maka transaksi Investor tersebut
                        dimasukkan ke dalam saldo deposit Investor di platform
                        Penyelenggara yang sewaktu-waktu dapat ditarik oleh
                        Investor.
                      </li>
                      <li>
                        Dalam hal transaksi pembelian Unit Penyertaan Dana
                        Investor dilakukan pada saat Unit Penyertaan Dana telah
                        dinyatakan habis/soldout, maka Investor berhak atas
                        pengembalian pembelian Unit Penyertaan Dana dengan
                        melakukan konfirmasi kepada Penyelenggara melalui media
                        komunikasi yang telah disediakan oleh Penyelenggara.
                      </li>
                      <li>
                        Pengembalian pembayaran pembelian Unit Penyertaan Dana
                        tersebut akan masuk ke dalam saldo deposit Investor di
                        platform Penyelenggara yang sewaktu-waktu dapat ditarik
                        oleh Investor.
                      </li>
                    </ol>
                  </p>
                  <p style={{ fontWeight: "bold" }}>
                    DAFTAR PEMEGANG Unit Penyertaan Dana
                  </p>
                  <p>
                    <ol>
                      <li>
                        Investor mengerti dan memahami bahwa Penerbit wajib
                        mencatatkan kepemilikan Unit Penyertaan Dana Investor
                        dalam daftar pemegang Unit Penyertaan Dana.
                      </li>
                      <li>
                        Persetujuan Investor terhadap Syarat dan Ketentuan ini
                        berarti Investor setuju dan sepakat bahwa Investor
                        memberikan kuasa kepada Penyelenggara untuk mewakili
                        Investor sebagai pemegang Unit Penyertaan Dana Penerbit
                        termasuk dalam Rapat Umum Pemegang Unit Penyertaan Dana
                        (“RUPS”) Penerbit dan penandatanganan akta serta dokumen
                        terkait lainnya
                      </li>
                    </ol>
                  </p>
                  <p style={{ fontWeight: "bold" }}>PENGHIMPUNAN DANA</p>
                  <p>
                    <ol>
                      <li>
                        Investor mengerti dan memahami bahwa pembagian Imbal
                        Hasil kepada para pemegang Unit Penyertaan Dana tidak
                        bersifat lifetime karena Penerbit merupakan badan usaha
                        berbadan hukum berhak melakukan Buyback sebagaimana
                        diatur dalam akta anggaran dasar Penerbit dan peraturan
                        perundang-undangan yang berlaku.
                      </li>
                      <li>
                        Investor mengerti dan memahami bahwa pembagian Imbal
                        Hasil Penerbit diinformasikan di dalam kebijakan Imbal
                        Hasil dan didasarkan pada laba bersih Penerbit setelah
                        dikurangi dengan pencadangan. Mekanisme pembagian Imbal
                        Hasil lainnya (termasuk pembagian Imbal Hasil interim)
                        mengacu pada anggaran dasar Penerbit dan peraturan
                        perundang-undangan yang berlaku.
                      </li>
                      <li>
                        Investor mengerti dan memahami bahwa pembagian Imbal
                        Hasil final Penerbit mengacu pada persetujuan Rapat Umum
                        Pemegang Unit Penyertaan Dana (“RUPS”) Penerbit.
                      </li>
                      <li>
                        Investor mengerti dan memahami bahwa apabila terdapat
                        beban operasional usaha yang harus dikeluarkan setiap
                        periode tertentu, Penerbit tidak memiliki hak untuk
                        membebankannya kepada Investor, melainkan beban tersebut
                        dimasukkan ke dalam penghitungan biaya operasional yang
                        kemudian dilaporkan dalam laporan keuangan periode
                        tersebut.
                      </li>
                    </ol>
                  </p>
                  <p style={{ fontWeight: "bold" }}>KEWAJIBAN INVESTOR</p>
                  <p>
                    Tanpa mengesampingkan hak dan kewajiban lainnya sebagaimana
                    telah tersebut dalam Perjanjian ini, maka kewajiban
                    Penyelenggara adalah sebagai berikut:
                    <ol>
                      <li>
                        Penyelenggara wajib memenuhi seluruh hak-hak Investor
                      </li>
                      <li>
                        Penyelenggara memonitor, menganalisa, dan memastikan
                        bahwa Pengguna berada di jalur yang sesuai dengan visi
                        misi Penyelenggara dan Layanan Urun Dana.
                      </li>
                      <li>
                        Penyelenggara bertanggung jawab melakukan ganti rugi
                        atas setiap kerugian Investor yang timbul disebabkan
                        oleh kelalaian karyawan ataupun direksi Penyelenggara.
                      </li>
                    </ol>
                  </p>
                  <p style={{ fontWeight: "bold" }}>HAK PENYELENGGARA</p>
                  <p>
                    Tanpa mengesampingkan hak dan kewajiban lainnya sebagaimana
                    telah tersebut dalam Perjanjian ini, maka hak Penyelenggara
                    adalah :
                    <ol>
                      <li>
                        Penyelenggara berhak atas manfaat dari Investor atas
                        Layanan Urun Dana yang sedang berlangsung.
                      </li>
                    </ol>
                  </p>
                  <p style={{ fontWeight: "bold" }}>PERPAJAKAN</p>
                  <p>
                    Pembebanan pajak yang timbul dalam Layanan Urun Dana ini
                    menjadi beban masing-masing pihak sesuai dengan ketentuan
                    hukum perpajakkan yang berlaku di wilayah Negara Republik
                    Indonesia.
                  </p>
                  <p style={{ fontWeight: "bold" }}>
                    HAK ATAS KEKAYAAN INTELEKTUAL
                  </p>
                  <p>
                    <ol>
                      <li>
                        Hak atas kekayaan intelektual yang timbul atas
                        pelaksanaan Layanan Urun Dana dan izin Penyelenggara,
                        beserta fasilitas-fasilitas lain yang dimiliki
                        Penyelenggara dan digunakan dalam Layanan Urun Dana ini
                        adalah tetap dan seterusnya milik Penyelenggara dan
                        tidak ada penyerahan hak dari Penyelenggara kepada
                        Investor dalam Layanan Urun Dana ini.
                      </li>
                      <li>
                        Investor tidak berhak untuk mengubah, mengembangkan,
                        membagikan dan/atau menjual baik seluruh maupun sebagian
                        hak atas kekayaan intelektual yang timbul atas
                        pengembangan, inovasi, perubahan berupa fitur dan/atau
                        fungsi terhadap sistem teknologi informasi.
                      </li>
                      <li>
                        Penyelenggara dengan ini menjamin bahwa hak atas
                        kekayaan intelektual yang terkandung dalam pelaksanaan
                        Layanan Urun Dana ini tidak melanggar hak atas kekayaan
                        intelektual milik pihak manapun, dan Penyelenggara
                        membebaskan Investor dari segala tuntutan, gugatan dari
                        pihak manapun, sehubungan dengan pelanggaran terhadap
                        hak atas kekayaan intelektual yang terkandung dalam
                        Layanan Urun Dana sesuai dengan syarat dan ketentuan
                        ini.
                      </li>
                    </ol>
                  </p>
                  <p style={{ fontWeight: "bold" }}>
                    JANGKA WAKTU DAN PENGAKHIRAN
                  </p>
                  <p>
                    <ol>
                      <li>
                        Jangka waktu Layanan Urun Dana antara Penyelenggara dan
                        Investor ini berlaku selama Penerbit turut serta dalam
                        Layanan Urun Dana.
                      </li>
                      <li>
                        Layanan Urun Dana ini berakhir dengan sendirinya,
                        apabila :
                        <ol type="a">
                          <li>
                            Penerbit melakukan Buyback Unit Penyertaan Dana;
                          </li>
                          <li>Diakhiri oleh Penyelenggara</li>
                        </ol>
                      </li>
                      <li>
                        Dalam hal Layanan Urun Dana ini berakhir dan/atau
                        dinyatakan berakhir, maka Para Pihak sepakat bahwa
                        ketentuan Informasi Rahasia sebagaimana diatur dalam
                        syarat dan ketentuan ini tetap berlaku dan mengikat Para
                        Pihak hingga kapanpun meskipun Layanan Urun Dana telah
                        berakhir.
                      </li>
                      <li>
                        Pengakhiran/pembatalan Layanan Urun Dana ini tidak
                        menghapuskan kewajiban-kewajiban masing-masing Pihak
                        yang telah atau akan timbul dan belum dilaksanakan pada
                        saat berakhirnya Layanan Urun Dana ini.
                      </li>
                      <li>
                        Dalam hal pengakhiran/pembatalan Layanan Urun Dana ini,
                        Para Pihak sepakat untuk mengesampingkan keberlakuan
                        ketentuan Pasal 1266 Kitab Undang-Undang Hukum Perdata,
                        sepanjang ketentuan tersebut mensyaratkan adanya suatu
                        putusan atau penetapan pengadilan untuk menghentikan
                        atau mengakhiri suatu perjanjian, sehingga
                        pengakhiran/pembatalan Layanan Urun Dana ini cukup
                        dilakukan dengan pemberitahuan tertulis dari salah satu
                        Pihak.
                      </li>
                    </ol>
                  </p>
                  <p style={{ fontWeight: "bold" }}>INFORMASI RAHASIA</p>
                  <p>
                    <ol>
                      <li>
                        Salah satu Pihak (selanjutnya disebut “ Pihak Pemberi ”)
                        dapat memberikan Informasi Rahasia kepada Pihak lainnya
                        (selanjutnya disebut “Pihak Penerima”) dalam
                        melaksanakan Layanan Urun Dana ini. Para Pihak sepakat
                        bahwa pemberian, penerimaan dan penggunaan Informasi
                        Rahasia tersebut dilakukan sesuai dengan syarat dan
                        ketentuan ini.
                      </li>
                      <li>
                        Informasi Rahasia yang dimaksud dalam ketentuan ini
                        berarti informasi yang bersifat non- publik, termasuk
                        namun tidak terbatas pada skema atau gambar produk,
                        penjelasan material, spesifikasi, laporan keuangan dan
                        informasi mengenai klien, kebijaksanaan dan praktek
                        bisnis Pihak Pemberi dan informasi mana dapat dimuat
                        dalam media cetak, tulis, disk / tape / compact disk
                        komputer atau media lainnya yang sesuai.
                      </li>
                      <li>
                        Tidak termasuk sebagai Informasi Rahasia adalah materi
                        atau informasi yang mana dapat dibuktikan oleh Pihak
                        Penerima bahwa:
                        <p>
                          <ol type="a">
                            <li>
                              Pada saat penerimaannya sebagai milik publik (
                              public domain ) atau menjadi milik publik ( public
                              domain ) atau menjadi milik publik ( public domain
                              ) tanpa adanya pelanggaran oleh Pihak Penerima;
                            </li>
                            <li>
                              Telah diketahui oleh Pihak Penerima pada saat
                              diberikan oleh Pihak Pemberi;
                            </li>
                            <li>
                              Telah didapatkan dari pihak ketiga tanpa adanya
                              pelanggaran terhadap pengungkapan Informasi
                              Rahasia;
                            </li>
                            <li>Dikembangkan sendiri oleh Pihak Penerima.</li>
                          </ol>
                        </p>
                      </li>
                      <li>
                        Pihak Penerima dengan ini menyatakan bahwa tidak akan
                        mengungkapkan Informasi Rahasia apapun yang diberikan
                        Pihak Pemberi ke pihak lainnya selain daripada yang
                        diperlukan dalam melaksanakan tugas, peran dan
                        kewajibannya dalam Layanan Urun Dana ini, tanpa terlebih
                        dahulu memperoleh persetujuan dari Pihak Pemberi dan
                        Pihak Penerima akan melakukan semua tindakan-tindakan
                        pencegahan yang wajar untuk mencegah terjadinya
                        pelanggaran atau kelalaian dalam pengungkapan,
                        penggunaan, pembuatan salinan ( copy ) atau pengalihan
                        Informasi Rahasia tersebut.
                      </li>
                      <li>
                        Masing-masing Pihak berkewajiban untuk menyimpan segala
                        rahasia data atau sistem yang diketahuinya baik secara
                        langsung maupun tidak langsung sehubungan Layanan Urun
                        Dana yang dilaksanakan sesuai dengan syarat dan
                        ketentuan ini dan bertanggung jawab atas segala kerugian
                        yang diakibatkan karena pembocoran Informasi Rahasia
                        tersebut, baik oleh masing- masing Pihak maupun
                        karyawannya maupun perwakilannya.
                      </li>
                    </ol>
                  </p>
                  <p style={{ fontWeight: "bold" }}>PERNYATAAN DAN JAMINAN</p>
                  <p>
                    <ol>
                      <li>
                        Tanpa mengesampingkan pernyataan dan jaminan yang
                        diberikan oleh salah satu Pihak ke Pihak lainnya dalam
                        Layanan Urun Dana ini, masing-masing Pihak dengan ini
                        menyatakan dan menjamin Pihak lainnya dalam Layanan Urun
                        Dana hal-hal sebagai berikut:
                        <ol type="a">
                          <li>
                            Bahwa berdasarkan hukum negara Republik Indonesia,
                            masing-masing Pihak cakap menurut hukum untuk
                            memiliki harta kekayaan dan melakukan perbuatan
                            hukum usahanya di wilayah Republik Indonesia serta
                            memiliki segala perizinan yang diperlukan untuk
                            menjalankan perbuatan hukum.;
                          </li>
                          <li>
                            Bahwa masing-masing Pihak telah mengambil segala
                            tindakan yang diperlukan untuk memastikan wakil dari
                            masing-masing Pihak dalam Layanan Urun Dana telah
                            memiliki kuasa dan wewenang penuh untuk mengikatkan
                            diri dalam persetujuan Syarat dan Ketentuan ini;
                          </li>
                          <li>
                            Bahwa masing-masing Pihak telah memastikan bahwa
                            Layanan Urun Dana ini tidak melanggar ketentuan dari
                            anggaran dasar masing-masing Pihak dalam Layanan
                            Urun Dana dan tidak bertentangan dengan perjanjian
                            apapun yang dibuat oleh masing- masing Pihak dengan
                            pihak ketiga;
                          </li>
                          <li>
                            Pelaksanaan ketentuan-ketentuan dalam Layanan Urun
                            Dana ini dilaksanakan secara profesional dengan
                            penuh tanggung jawab dan atas dasar hubungan yang
                            saling menguntungkan.
                          </li>
                          <li>
                            Persetujuan syarat dan ketentuanini tidak
                            bertentangan atau melanggar atau berbenturan dengan
                            kaidah-kaidah hukum dan peraturan perundangundangan
                            serta kebijakan-kebijakan pemerintah Republik
                            Indonesia atau pihak yang berwenang lainnya;
                          </li>
                          <li>
                            Bersedia untuk menerapkan, mendukung dan mematuhi
                            ketentuan hokum dan peraturan perundang-undangan
                            yang berlaku di Republik Indonesia termasuk namun
                            tidak terbatas pada peraturan mengenai tindak pidana
                            korupsi, anti pencucian uang dan anti penyuapan;
                          </li>
                          <li>
                            Masing-masing Pihak akan melaksanakan Layanan Urun
                            Dana ini dengan itikad baik dan secara jujur. Tidak
                            satupun ketentuan dalam Layanan Urun Dana ini akan
                            digunakan oleh salah satu Pihak untuk mengambil
                            keuntungan secara tidak wajar dan mengakibatkan
                            kerugian bagi Pihak lainnya dan tidak satupun
                            ketentuan dalam Layanan Urun Dana ini dimaksudkan
                            untuk memberikan keuntungan secara tidak wajar
                            kepada Pihak lainnya.
                          </li>
                        </ol>
                      </li>
                      <li>
                        Tanpa mengesampingkan pernyataan dan jaminan yang
                        diberikan oleh Penerbit ke Penyelenggara, Investor
                        dengan ini menyatakan dan menjamin kepada Penyelenggara
                        hal-hal sebagai berikut:
                        <ol type="a">
                          <li>
                            Bahwa Investor akan membebaskan Penyelenggara dari
                            klaim, tuntutan, gugatan dari pihak ketiga atas
                            kelalaian Investor dan/atau karyawan Investor dalam
                            melaksanakan Layanan Urun Dana ini;
                          </li>
                          <li>
                            Bahwa Investor menyatakan tidak berkeberatan dalam
                            hal Otoritas Jasa Keuangan dan/atau pihak lain yang
                            sesuai undang-undang berwenang untuk melakukan
                            pemeriksaan, akan melakukan pemeriksaan terhadap
                            pelaksanaan Layanan Urun Dana ini;
                          </li>
                          <li>
                            Bahwa Investor bersedia untuk kemungkinan
                            pengubahan, pembuatan atau pengambil alih kegiatan
                            yang dilaksanakan oleh Investor atau penghentian
                            Layanan Urun Dana.
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </p>
                  <p style={{ fontWeight: "bold" }}>KEADAAN MEMAKSA</p>
                  <p>
                    <ol>
                      <li>
                        Keadaan Memaksa atau Force Majeure adalah
                        kejadian-kejadian yang terjadi diluar kemampuan dan
                        kekuasaan Para Pihak sehingga menghalangi Para Pihak
                        untuk melaksanakan Layanan Urun Dana ini, termasuk namun
                        tidak terbatas pada adanya kebakaran, banjir, gempa
                        bumi,likuifaksi, badai, huruhara, peperangan, epidemi,
                        pertempuran, pemogokan, sabotase, embargo, peledakan
                        yang mengakibatkan kerusakan sistem teknologi informasi
                        yang menghambat pelaksanaan Layanan Urun Dana ini, serta
                        kebijaksanaan pemerintah Republik Indonesia yang secara
                        langsung berpengaruh terhadap pelaksanaan Layanan Urun
                        Dana ini.
                      </li>
                      <li>
                        Masing-masing Pihak dibebaskan untuk membayar denda
                        apabila terlambat dalam melaksanakan kewajibannya dalam
                        Layanan Urun Dana ini, karena adanya hal-hal Keadaan
                        Memaksa.
                      </li>
                      <li>
                        Keadaan Memaksa sebagaimana dimaksud harus diberitahukan
                        oleh Pihak yang mengalami Keadaan Memaksa kepada Pihak
                        lainnya dalam Layanan Urun Dana ini paling lambat 7
                        (tujuh) Hari Kalender dengan melampirkan pernyataan atau
                        keterangan tertulis dari pemerintah untuk
                        dipertimbangkan oleh Pihak lainnya beserta rencana
                        pemenuhan kewajiban yang tertunda akibat terjadinya
                        Keadaan Memaksa.
                      </li>
                      <li>
                        Keadaan Memaksa yang menyebabkan keterlambatan
                        pelaksanaan Layanan Urun Dana ini baik untuk seluruhnya
                        maupun sebagian bukan merupakan alasan untuk pembatalan
                        Layanan Urun Dana ini sampai dengan diatasinya Keadaan
                        Memaksa tersebut.
                      </li>
                    </ol>
                  </p>
                  <p style={{ fontWeight: "bold" }}>
                    PENGALIHAN LAYANAN URUN DANA
                  </p>
                  <p>
                    <ol>
                      <li>
                        Investor setuju dan sepakat untuk tidak mengalihkan
                        sebagian atau keseluruhan hak dan kewajiban Penerbit
                        dalam Layanan Urun Dana ini kepada pihak lainnya atau
                        pihak manapun.
                      </li>
                      <li>
                        Dalam hal adanya permintaan peralihan atas hak
                        kepemilikan Unit Penyertaan Dana dikarenakan Investor
                        meninggal dunia, maka ahli waris mengajukan permohonan
                        perubahan kepemilikan Unit Penyertaan Dana kepada
                        Penyelenggara dengan melengkapi dokumen sebagai sebagai
                        berikut :
                        <ol type="a">
                          <li>
                            Surat permohonan peralihan kepemilikan Unit
                            Penyertaan Dana dikarenakan Investor meninggal
                            dunia;
                          </li>
                          <li>
                            Softcopy surat kematian dari instansi berwenang;
                          </li>
                          <li>
                            Softcopy surat keterangan ahli waris dari instansi
                            berwenang dan/atau surat penetapan pengadilan
                            tentang ahli waris;
                          </li>
                          <li>
                            Softcopy E-KTP Investor (almarhum/almarhumah) dan
                            ahli waris
                          </li>
                          <li>
                            Softcopy Kartu Keluarga (KK) Investor
                            (almarhum/almarhumah)
                          </li>
                          <li>
                            Surat Penunjukan dan/atau Surat Kuasa dari ahli
                            waris (apabila ahli warislebih dari satu) untuk
                            menunjuk dan/atau menguasakan peralihan kepemilikan
                            Unit Penyertaan Dana kepada salah satu ahli waris.
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </p>
                  <p style={{ fontWeight: "bold" }}>
                    DOMISILI HUKUM DAN PENYELESAIAN SENGKETA
                  </p>
                  <p>
                    <ol>
                      <li>
                        Layanan Urun Dana ini dibuat, ditafsirkan dan
                        dilaksanakan berdasarkan hukum negara Republik
                        Indonesia.
                      </li>
                      <li>
                        Setiap perselisihan yang timbul sehubungan dengan
                        Layanan Urun Dana ini, akan diupayakan untuk
                        diselesaikan terlebih dahulu oleh Para Pihak dengan
                        melaksanakan musyawarah untuk mufakat.
                      </li>
                      <li>
                        Apabila penyelesaian perselisihan secara musyawarah
                        tidak berhasil mencapai mufakat sampai dengan 30 (tiga
                        puluh) Hari Kalender sejak dimulainya musyawarah
                        tersebut, maka Para Pihak sepakat untuk menyelesaikan
                        perselisihan tersebut melalui proses pengadilan.
                      </li>
                      <li>
                        Para Pihak sepakat untuk menyelesaikan perselisihan di
                        Pengadilan Negeri Kendal di Provinsi Jawa Tengah tanpa
                        mengurangi hak darisalah satu untuk mengajukan gugatan
                        pada domisili pengadilan lainnya ( nonexlusive
                        jurisdiction ).
                      </li>
                      <li>
                        Tanpa mengesampingkan penyelesaian sengketa atau
                        perselisihan melalui pengadilan negeri, Para Pihak
                        setuju dan sepakat apabila penyelesaian sengketa atau
                        perselisihan di badan arbitrase dan badan alternatif
                        penyelesaian sengketa yang ditunjuk.
                      </li>
                      <li>
                        Hasil putusan pengadilan negeri maupun badan arbitrase
                        dan badan alternatif penyelesaian sengketa yang ditunjuk
                        oleh Otoritas Jasa Keuangan maupun regulator berwenang
                        lainnya bersifat final dan mempunyai kekuatan hokum
                        tetap dan mengikat bagi Para Pihak.
                      </li>
                    </ol>
                  </p>
                  <p style={{ fontWeight: "bold" }}>KELALAIAN / WANPRESTASI</p>
                  <p>
                    <ol>
                      <li>
                        Dalam hal terjadi salah satu hal atau peristiwa yang
                        ditetapkan di bawah ini, maka merupakan suatu kejadian
                        kelalaian (wanprestasi) terhadap Layanan Urun Dana ini:
                        <ol type="a">
                          <li>
                            Kelalaian dalam Layanan Urun Dana Dalam hal salah
                            satu Pihak terbukti sama sekali tidak melaksanakan
                            kewajiban, atau melaksanakan kewajiban tetapi tidak
                            sebagaimana disepakati, atau melaksanakan kewajiban
                            tetapi tidak sesuai dengan waktu yang disepakati,
                            atau melakukan sesuatu yang tidak diperbolehkan
                            dalam Syarat dan Ketentuan.
                          </li>
                          <li>
                            Pernyataan Tidak Benar Dalam hal ternyata bahwa
                            sesuatu pernyataan atau jaminan yang diberikan oleh
                            salah satu Pihak kepada Pihak lainnya dalam Layanan
                            Urun Dana ini terbukti tidak benar atau tidak sesuai
                            dengan kenyataannya dan menimbulkan kerugian
                            langsung yang diderita salah satu Pihak.
                          </li>
                          <li>
                            Kepailitan Dalam hal salah satu Pihak dalam Layanan
                            Urun Dana ini oleh instansi yang berwenang
                            dinyatakan berada dalam keadaan pailit atau
                            diberikan penundaan membayar hutang- hutang (
                            surseance van betaling ).
                          </li>
                          <li>
                            Permohonan Kepailitan Dalam hal salah satu Pihak
                            dalam Layanan Urun Dana ini mengajukan permohonan
                            kepada instansi yang berwenang untuk dinyatakan
                            pailit atau untuk diberikan penundaan membayar
                            hutang-hutang ( surseance van betaling ) atau dalam
                            hal pihak lain mengajukan permohonan kepada instansi
                            yang berwenang agar salah satu Pihak dalam Layanan
                            Urun Dana ini dinyatakan dalam keadaan pailit.
                          </li>
                        </ol>
                      </li>
                      <li>
                        Dalam hal suatu kejadian kelalaian terjadi dan
                        berlangsung, maka Pihak yang tidak lalai berhak
                        menyampaikan peringatan sebanyak 3 (tiga) kali dengan
                        tenggang waktu 7 (tujuh) Hari Kalender diantara
                        masing-masing peringatan.
                      </li>
                      <li>
                        Setelah menyampaikan 3 (tiga) kali peringatan, Pihak
                        yang tidak lalai berhak mengajukan tuntutan berupa
                        meminta pemenuhan prestasi dilakukan atau meminta
                        prestasi dilakukan disertai ganti kerugian atau meminta
                        ganti kerugian saja atau menuntut pembatalan Layanan
                        Urun Dana disertai ganti kerugian.
                      </li>
                    </ol>
                  </p>
                  <p style={{ fontWeight: "bold" }}>PENALTI</p>
                  <p>
                    Apabila dalam Layanan Urun Dana ini, Investor melanggar
                    ketentuan dalam Layanan Urun Dana ini maka Penyelenggara
                    berhak menon-aktifkan atau membekukan akun Investor, bahkan
                    pengakhiran Layanan Urun Dana Investor oleh Penyelenggara
                    dalam Layanan Urun Dana ini.
                  </p>
                  <p style={{ fontWeight: "bold" }}>
                    MEKANISME DALAM HAL PENYELENGGARA TIDAK DAPAT MENJALANKAN
                    OPERASIONALNYA
                  </p>
                  <p>
                    Mekanisme penyelesaian Layanan Urun Dana dalam hal
                    Penyelenggara tidak dapat menjalankan operasional adalah
                    sebagai berikut :
                    <ol>
                      <li>
                        Penyelenggara melakukan pemberitahuan atau pengumuman
                        secara tertulis di website Penyelenggara dan media
                        sosial lainnya kepada seluruh Pengguna atau khalayak
                        umum bahwa Penyelenggara tidak dapat memberitahukan
                        operasionalnya dengan mencantumkan alasan jelas;
                      </li>
                      <li>
                        Bahwa pengaturan tata cara Buyback mengacu pada akta
                        anggaran dasar Penerbit dan undang-undang dasar tentang
                        perseroan terbatas yang berlaku di Negara Republik
                        Indonesia;
                      </li>
                      <li>
                        Buyback seluruh Unit Penyertaan Dana yang dimiliki
                        Investor dengan harga pasar atau disepakati secara
                        tertulis oleh Para Pihak di kemudian hari.
                      </li>
                      <li>
                        Menunjuk penyelenggara lain yang telah mendapat izin
                        dari Otoritas Jasa Keuangan seperti Penyelenggara,
                        dengan syarat dan ketentuan yang sudah disepakati
                        bersama dengan Investor.
                      </li>
                    </ol>
                  </p>
                  <p style={{ fontWeight: "bold" }}>DISCLAIMER</p>
                  <p>
                    <ol>
                      <li>
                        Penyelenggara bertindak sebagi penyelenggara Layanan
                        Urun Dana, bukan sebagai pihak yang menjalankan bisnis
                        (Penerbit).
                      </li>
                      <li>
                        Semua data dan informasi yang tersaji di website,
                        aplikasi dan prospectus diperoleh Penyelenggara dari
                        Penerbit dan media online.
                      </li>
                      <li>
                        Keputusan pembelian Unit Penyertaan Dana, sepenuhnya
                        merupakan hak dan pilihan Investor, sehingga segala
                        risiko dan konsekuensi atas pembelian Unit Penyertaan
                        Dana merupakan tanggung jawab dan risiko Investor
                        sepenuhnya.
                      </li>
                      <li>
                        Dengan membeli Unit Penyertaan Dana di Penyelenggara,
                        berarti Investor dianggap telah membaca, memahami dan
                        menyetujui seluruh syarat dan ketentuan serta memahami
                        risiko investasi termasuk risiko kehilangan atau seluruh
                        modal.
                      </li>
                    </ol>
                  </p>
                  <p style={{ fontWeight: "bold" }}>LAIN - LAIN</p>
                  <p>
                    <ol>
                      <li>
                        Para Pihak wajib tunduk dan patuh terhadap peraturan
                        perundang-undangan yang berlaku di negara Republik
                        Indonesia terkait pelaksanaan Layanan Urun Dana ini.
                      </li>
                      <li>
                        Layanan Urun Dana ini diinterpretasikan dan dilaksanakan
                        berdasarkan hokum yang berlaku di Negara Republik
                        Indonesia.
                      </li>
                      <li>
                        Keabsahan, penafsiran dan pelaksanaan Layanan Urun Dana
                        ini, diatur serta tunduk pada hukum dan peraturan
                        perundang-undangan yang berlaku di negara Republik
                        Indonesia;
                      </li>
                      <li>
                        Dalam hal ada salah satu atau lebih ketentuan dalam
                        syarat dan ketentuanini dinyatakan tidak sah, tidak
                        berlaku atau tidak dapat dilaksanakan berdasarkan hukum
                        dan/atau peraturan perundang-undangan yang berlaku di
                        Republik Indonesia, maka kedua belah pihak setuju bahwa
                        keabsahan ketentuan lainnya dalam Syarat dan Ketentuan
                        ini tetap berlaku dan dapat dilaksanakan serta tidak
                        akan terpengaruh;
                      </li>
                      <li>
                        Penyelenggara berhak untuk mengubah syarat dan
                        ketentuanini sewaktuwaktu dalam rangka penyempurnaan dan
                        penyesuaian dengan ketentuan hukum yang berlaku di
                        Negara Republik Indonesia
                      </li>
                      <li>
                        Dokumen-dokumen atau kesepakatan-kesepakatan terkait
                        dengan Layanan Urun Dana ini yang telah dibuat oleh Para
                        Pihak sebelum disetujuinya syarat dan ketentuan ini
                        dinyatakan dicabut dan tidak berlaku terhitung sejak
                        disetujuinya syarat dan ketentuan.
                      </li>
                    </ol>
                  </p>
                  <p>
                    SYARAT DAN KETENTUAN INI DIBUAT DAN DIBERIKAN PERSETUJUAN
                    SECARA ELEKTRONIK OLEH INVESTOR DALAM KEADAAN SEHAT DAN
                    SADAR SERTA TANPA ADA PAKSAAN DARI PIHAK MANAPUN JUGA.
                  </p>
                  <p>
                    SETELAH INVESTOR MEMBUBUHKAN TANDA CENTANG (√) PADA KOTAK
                    PERSETUJUAN SECARA ELEKTRONIK ATAS SYARAT DAN KETENTUAN INI,
                    MAKA INVESTOR DENGAN INI MENYATAKAN SETUJU TELAH MEMBACA,
                    MENGERTI, MEMAHAMI SECARA SEKSAMA DAN TUNDUK PADA SETIAP DAN
                    KESELURUHAN SYARAT DAN KETENTUAN, SERTA TUNDUK PADA
                    PERATURAN KOPERASI GEMA BESERTA PERUBAHAN-PERUBAHANNYA.
                  </p>
                  <p>
                    DAN OLEH KARENA ITU, DALAM PELAKSANAAN LAYANAN URUN DANA,
                    INVESTOR MENYATAKAN DAN MENJAMIN BAHWA INVESTOR SELALU TETAP
                    MEMATUHI DAN MELAKSANAKAN SETIAP KETENTUAN YANG ADA DALAM
                    SYARAT DAN KETENTUAN INI DENGAN PENUH TANGGUNG JAWAB DAN
                    PROFESIONAL.
                  </p>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={() => {
                      setAccept1(!accept1);
                    }}
                    value=""
                    style={{ marginRight: "10px" }}
                    id="flexCheckDefault"
                    checked={accept1}
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    I Accept All Term & Condition
                  </label>
                </div>
              </div>
              <hr />
              <div
                className="form-check px-4 pb-4"
                style={{ textAlign: "left" }}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  onChange={() => {
                    setAccept2(!accept2);
                  }}
                  value=""
                  checked={accept2}
                  id="flexCheckDefault"
                />
                <label className="form-check-label" for="flexCheckDefault">
                  Dengan ini, saya <b>{user.name}</b> menyatakan bahwa saya
                  telah membaca, memahami, dan menyetujui Ketentuan Investasi di
                  atas*
                </label>
              </div>
            </div>
            <div
              className="bg-dark text-white d-flex justify-content-between"
              style={{ color: "grey" }}
            >
              <div className="spacer"></div>
              <div className="btn-checkout mr-auto">
                {accept1 && accept2 ? (
                  <button
                    onClick={() => {
                      checkout();
                    }}
                    className="text-link2 text-white p-4 bg-orange"
                  >
                    Beli
                  </button>
                ) : (
                  <button
                    disabled
                    className="text-link2 text-white p-4 bg-orange"
                    style={{ backgroundColor: "grey" }}
                  >
                    Beli
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
