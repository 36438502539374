import Header from "../../../components/Header/header";
import Hero from "../../../components/Hero/hero";
import About from "../../../components/About/about";
import Count from "../../../components/Count/count";
import Services from "../../../components/Services/services";
import Team from "../../../components/Team/team";
import Contact from "../../../components/Contact/contact";
import Footer from "../../../components/Footer/footer";
import Portofolio from "../../../components/Portofolio/portofolio";
import { seo } from "../../../helper/seo";
import { useLocation } from "react-router-dom";

function Home(props) {
  seo({
    title: "Investasi Bisnis Dengan Equity Crowndfunding | Gema Indonesia",
    metaDescription:
      "GEMA adalah sebuah Koperasi yang dikelola dengan managamen modern, menggunakan platform Equity Crowdfunding untuk pengembangan usaha seperti Usaha Mikro, Kecil dan Menengah (UMKM), serta usaha rintisan (Startup)",
  });
  return (
    <div>
      {window.scrollTo(0, 0)}
      <Header />
      <Hero />
      <About />
      <Count />
      <Services />
      <Portofolio />
      <Team />
      <Contact />
      <Footer />
    </div>
  );
}

export default Home;
