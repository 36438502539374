import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import React, { Component } from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import "react-tabs/style/react-tabs.css";
import Cart from "../Cart/Cart";
import AddToCart from "../AddToCart/AddToCart";
import NotFound from "../../container/pages/404/404";

axiosRetry(axios, { retries: 5 });

export default class projectdetails extends Component {
  state = {
    project: {},
    isLoading: true,
    isShow: false,
    investcalc: 1,
  };

  percentage(partialValue, totalValue) {
    return (partialValue / totalValue) * 100 + "%";
  }

  getProject = async () => {
    const result = await axios.get(
      `https://admin.gemaindonesia.id/admin/v1/entities/myproject/read?slug=myproject&id=${this.props.slug}`
    );
    if (result) {
      this.setState({
        project: result.data,
        isLoading: false,
      });
      console.log(result.data);
    }
  };

  button = (data) => {
    if (data === null) {
      return (
        <button className="btn btn-orange" disabled>
          DOWNLOAD PROSPEKTUS
        </button>
      );
    } else {
      return (
        <a target={"_blank"} href={data}>
          <button className="btn btn-orange">DOWNLOAD PROSPEKTUS</button>
        </a>
      );
    }
  };

  day = (data1, data2) => {
    if (data1 === data2) {
      return 0;
    } else {
      return 30;
    }
  };

  content = () => {
    if (this.state.project.keterangan === "") {
      return <div className="coming-soon">Coming Soon</div>;
    } else {
      return (
        <div
          className="project-tentang"
          dangerouslySetInnerHTML={{ __html: this.state.project.keterangan }}
        />
      );
    }
  };
  getMap = () => {
    if (this.state.project.keterangan === "") {
      return <div className="coming-soon">Coming Soon</div>;
    } else {
      return (
        <div
          className="project-map"
          dangerouslySetInnerHTML={{ __html: this.state.project.map_link }}
        />
      );
    }
  };

  // replace all text contain src="files to src="https://abaybucket.s3.ap-southeast-3.amazonaws.com/files
  replaceAll = (str, find, replace) => {
    return str.replace(new RegExp(find, "g"), replace);
  };

  galery = () => {
    if (this.state.project.gallery === "") {
      return <div className="coming-soon">Coming Soon</div>;
    } else {
      return (
        <div
          className="project-galery"
          dangerouslySetInnerHTML={{
            __html: this.replaceAll(
              this.state.project.gallery || "",
              `src="files`,
              `src="https://admin.gemaindonesia.id/storage/files`
            ),
          }}
        />
      );
    }
  };

  beli = (data1, data2) => {
    if (parseInt(data1) === parseInt(data2)) {
      return (
        <button
          className="btn btn-orange"
          style={{ marginRight: "10px" }}
          disabled
        >
          BELI SEKARANG
        </button>
      );
    } else {
      return (
        <a
          onClick={() => {
            this.setState({
              isShow: true,
            });
          }}
          target="_blank"
          className="btn btn-orange"
          style={{ marginRight: "10px" }}
        >
          BELI SEKARANG
        </a>
      );
    }
  };

  getProjectDetail = () => {
    if (this.state.isLoading) {
      return (
        <div className="wrap">
          <div className="row">
            <div className="col-lg-6">
              <div className="project-img-loader"></div>
            </div>
            <div className="col-lg-6">
              <div className="project-title-loader"></div>
              <div className="project-category-loader"></div>
              <div className="row mt-5">
                <div className="col-lg-6 col-6">
                  <div className="project-left-loader"></div>
                </div>
                <div className="col-lg-6 col-6">
                  <div className="project-right-loader"></div>
                </div>
              </div>
              <div className="project-proggres-loader"></div>
              <div className="row mt-3">
                <div className="col-lg-6 col-6">
                  <div className="project-left-loader"></div>
                  <div className="project-left-loader"></div>
                </div>
                <div className="col-lg-6 col-6">
                  <div className="project-right-loader"></div>
                  <div className="project-right-loader"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div className="wrap">
            <div className="row">
              <div className="col-lg-6">
                <img
                  className="img-url"
                  src={this.state.project.img_url}
                  width="100%"
                  alt=""
                />
              </div>
              <div className="col-lg-6">
                <div className="projects">
                  <div className="title">
                    <h1>{this.state.project.project_name}</h1>
                    <span className="badges">
                      {this.state.project.category}
                    </span>
                    <div className="row">
                      <div className="col-8">
                        <div className="harga">
                          Rp.{" "}
                          {(
                            parseInt(this.state.project.paid_sum) ||
                            this.state.project.total_perolehan
                          ).toLocaleString()}
                        </div>
                        <p>
                          Dari Rp.{" "}
                          {this.state.project.total_pendanaan.toLocaleString()}{" "}
                          total Pendanaan
                        </p>
                      </div>
                      <div className="col-4">
                        <div className="harga">
                          {this.day(
                            this.state.project.harga_perlembar * 100,
                            this.state.project.paid_sum ||
                              this.state.project.total_perolehan
                          )}
                        </div>
                        <p>Hari Lagi</p>
                      </div>
                    </div>
                    <div className="progress mt-5">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: this.percentage(
                            this.state.project.paid_sum ||
                              this.state.project.total_perolehan,
                            this.state.project.total_pendanaan
                          ),
                        }}
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {this.percentage(
                          this.state.project.paid_sum ||
                            this.state.project.total_perolehan,
                          this.state.project.total_pendanaan
                        )}
                      </div>
                    </div>
                    <div className="row mt-5">
                      <div className="col-lg-6">
                        <p>Harga Per Keping</p>
                        <p className="pricelot">
                          Rp.{" "}
                          {parseInt(
                            this.state.project.harga_perlembar
                          ).toLocaleString()}
                        </p>
                      </div>
                      <div className="col-lg-6">
                        <p>Jumlah Koin</p>
                        <p className="pricelot">
                          {(
                            this.state.project.total_pendanaan /
                            this.state.project.harga_perlembar
                          ).toLocaleString()}
                        </p>
                      </div>
                      <div className="col-lg-6"></div>
                      <div className="col-lg-6 mt-2">
                        <p>Jumlah Lot</p>
                        <p className="pricelot">
                          {(
                            this.state.project.total_pendanaan /
                            this.state.project.harga_perlembar /
                            100
                          ).toLocaleString()}
                        </p>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-lg-6">
                        <p>Periode Dividen</p>
                        <p className="pricelot">
                          {this.state.project.dividen_periode} Bulan
                        </p>
                      </div>
                      <div className="col-lg-6">
                        <p>Estimasi Dividen</p>
                        <p className="pricelot">
                          {this.state.project.min_dividen +
                            " - " +
                            this.state.project.max_dividen +
                            "% / Tahun"}
                        </p>
                      </div>
                    </div>
                  </div>
                  {this.beli(
                    this.state.project.harga_perlembar * 100,
                    this.state.project.paid_sum ||
                      this.state.project.total_perolehan
                  )}
                  {this.button(this.state.project.proposal_link)}
                </div>
              </div>
            </div>
          </div>
          <div className="wrap p-4 mt-3">
            <Tabs>
              <TabList>
                <Tab>Finansial</Tab>
                <Tab>Tentang Bisnis</Tab>
                <Tab>Kategori Bisnis</Tab>
                <Tab>Lokasi</Tab>
                <Tab>Galery</Tab>
                <Tab>Kalkulator Investasi</Tab>
              </TabList>

              <TabPanel>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="item">
                      <div className="icon total-saham"></div>
                      <div className="description">
                        <span>Total saham yang dibagikan ke investor</span>
                        <p>{this.state.project.saham_dibagikan} %</p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="icon total-keuntungan"></div>
                      <div className="description">
                        <span>
                          Total keuntungan investor / tahun berdasarkan data
                          historis
                        </span>
                        <p>Rp {this.state.project.keuntungan_historis}</p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="icon periode-dividen"></div>
                      <div className="description">
                        <span>Periode Dividen</span>
                        <p>{this.state.project.dividen_periode} Bulan</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="item">
                      <div className="icon yield"></div>
                      <div className="description">
                        <span>
                          Rata-rata dividen yield (%) / tahun berdasarkan
                          historis
                        </span>
                        <p>
                          {this.state.project.min_dividen +
                            " - " +
                            this.state.project.max_dividen}
                          % / Tahun
                        </p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="icon balik-modal"></div>
                      <div className="description">
                        <span>Waktu balik modal berdasarkan historis</span>
                        <p>{this.state.project.balik_modal} Bulan</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="note">
                  *Performa bisnis masa lalu tidak mencerminkan kinerja masa
                  depan
                </div>
              </TabPanel>
              <TabPanel>{this.content()}</TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="item">
                      <div className="icon kategori-bisnis"></div>
                      <div className="description">
                        <span>Kategori Bisnis</span>
                        <p>Restoran</p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="icon sistem-pendanaan"></div>
                      <div className="description">
                        <span>Sistem Pengelolaan</span>
                        <p>{this.state.project.sistem_pengelolaan}</p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="icon nilai-investasi"></div>
                      <div className="description">
                        <span>Nilai Investasi</span>
                        <p>
                          Rp{" "}
                          {(
                            this.state.project.harga_perlembar * 100
                          ).toLocaleString()}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="item">
                      <div className="icon penerbit"></div>
                      <div className="description">
                        <span>Penerbit</span>
                        <p>{this.state.project.penerbit}</p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="icon skema-bisnis"></div>
                      <div className="description">
                        <span>Skema Bisnis</span>
                        <p>{this.state.project.skema_bisnis}</p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="icon min-investasi"></div>
                      <div className="description">
                        <span>Minimum Investasi / Lembar Saham</span>
                        <p>
                          Rp{" "}
                          {parseInt(
                            this.state.project.min_invest
                          ).toLocaleString()}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>{this.getMap()}</TabPanel>
              <TabPanel>{this.galery()}</TabPanel>
              <TabPanel>
                {" "}
                <div className="row card-box p-4">
                  <div className="col-lg-6">
                    <div className="description">
                      <span>Masukkan Jumlah Lot</span>
                      <div class="input-group mb-3" style={{ width: "20%" }}>
                        <div class="input-group-prepend">
                          <button
                            class="btn btn-outline-secondary"
                            type="button"
                            onClick={() => {
                              if (this.state.investcalc != 1) {
                                this.setState({
                                  investcalc: this.state.investcalc - 1,
                                });
                              }
                            }}
                          >
                            -
                          </button>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          placeholder=""
                          aria-label=""
                          aria-describedby="basic-addon1"
                          value={this.state.investcalc * 10}
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-secondary"
                            type="button"
                            onClick={() => {
                              this.setState({
                                investcalc: this.state.investcalc + 1,
                              });
                            }}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="description">
                      <span>Nilai Investasi</span>
                      <p className="black">
                        Rp.{" "}
                        {(
                          this.state.project.min_invest * this.state.investcalc
                        ).toLocaleString()}
                      </p>
                    </div>
                  </div>
                  <span className="mt-4">
                    Keuntungan investor / tahun berdasarkan historis
                  </span>
                  <div className="col-lg-4">
                    <div className="description">
                      <p className="green">
                        Rp.{" "}
                        {parseInt(
                          (this.state.project.min_invest *
                            this.state.project.min_dividen *
                            this.state.investcalc) /
                            100
                        ).toLocaleString()}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className="description">
                      <h4 class="text-center">s/d</h4>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="description">
                      <p className="green">
                        Rp.{" "}
                        {parseInt(
                          (this.state.project.min_invest *
                            this.state.project.max_dividen *
                            this.state.investcalc) /
                            100
                        ).toLocaleString()}
                        ,00
                      </p>
                    </div>
                  </div>

                  <span className="mt-4">
                    Dividen yield (%) / tahun berdasarkan historis
                  </span>
                  <div className="col-lg-4">
                    <div className="description">
                      <input
                        class="form-control green"
                        type="text"
                        value={this.state.project.min_dividen}
                        readOnly
                      ></input>
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className="description">
                      <h4 class="text-center">s/d</h4>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="description">
                      <input
                        class="form-control green"
                        type="text"
                        value={this.state.project.max_dividen}
                        readOnly
                      ></input>
                    </div>
                  </div>

                  <div className="note">
                    *Performa bisnis masa lalu tidak mencerminkan kinerja masa
                    depan
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </>
      );
    }
  };

  componentDidMount = () => {
    this.getProject();
  };

  render() {
    return (
      <>
        {this.state.project ? (
          <div>
            {this.getProjectDetail()}
            {this.state.isShow ? (
              <AddToCart
                id={this.state.project.id}
                title={this.state.project.project_name}
                slug={this.state.project.bisnis_slug}
                img={this.state.project.img_url}
                harga={this.state.project.harga_perlembar}
                buttonClick={() => {
                  this.setState({
                    isShow: false,
                  });
                }}
                isShow={this.state.isShow}
              />
            ) : null}
          </div>
        ) : (
          <div className="text-center">
            <h1>Project Not Found</h1>
          </div>
        )}
      </>
    );
  }
}
