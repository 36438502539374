import axios from "axios";
import useToken from "./UseToken";

const goLogout = async () => {
  const { accessToken } = JSON.parse(localStorage.getItem("token"));
  console.log(accessToken);
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const bodyParameters = {
    key: "value",
  };
  try {
    const logout = await axios.post(
      "https://admin.gemaindonesia.id/admin/v1/auth/logout",
      bodyParameters,
      {
        headers: headers,
      }
    );
    if (logout) {
      localStorage.removeItem("token");
      localStorage.removeItem("token2");
      window.location.href = "/";
    }
  } catch (error) {
    console.log(error.response);
  }
};

export { goLogout };
