import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { cartItem, chart, isShow, transaction, user } from "../../store/Data";
import Swal from "sweetalert2";
import useAuth from "../Auth/UseAuth";

export default function AddToCart(props) {
  const [carts, setCarts] = useRecoilState(chart);
  const [qty, setQty] = useState(1);
  const [show, setShow] = useRecoilState(isShow);
  const total = qty * props.harga;
  const [isTransaction, setTransaction] = useRecoilState(transaction);
  const history = useHistory();
  const { user } = useAuth();

  useEffect(() => {
    if (show == true) {
      setShow(false);
    }
    if (isTransaction) {
      history.push("/checkout");
    }
  }, [isTransaction, carts]);

  return (
    <div className="overlay-black">
      <div className="addtocart">
        <div className="card" style={{ width: "40rem" }}>
          {/* <img src={props.img} class="card-img-top" /> */}
          <div className="card-body">
            <h6 className="card-title d-flex justify-content-between">
              <div className="spacer"></div>
              <button
                onClick={props.buttonClick}
                className="btn-close"
              ></button>
            </h6>
            <h3 className="text-center mb-4">
              <b>
                Pendanaan Project <br /> {props.title}
              </b>
            </h3>
            <div className="input-group">
              <span className="input-group-btn">
                <button
                  onClick={() => {
                    if (qty == 1) {
                    } else {
                      setQty(qty - 1);
                    }
                  }}
                  type="button"
                  className="btn btn-dark btn-number"
                >
                  -
                </button>
              </span>
              <input
                type="text"
                className="form-control input-number"
                value={qty * 10}
              />
              <span className="input-group-btn">
                <button
                  onClick={() => {
                    setQty(qty + 1);
                  }}
                  type="button"
                  className="btn btn-dark btn-number"
                >
                  +
                </button>
              </span>
            </div>
            <p className="my-3">*Minimal pembelian 10 Lot (dan kelipatanya)</p>
            <div className="text-white d-flex bg-dark align-items-center justify-content-between">
              <div className="px-3">
                <b>Total :</b>
                <b>Rp. {(total * 10 * 100).toLocaleString()}</b>
              </div>
              <div className="btn-checkout mr-auto">
                <button
                  onClick={() => {
                    if (user) {
                      const data = { ...carts };
                      data["paymentId"] =
                        props.slug + Math.floor(Math.random() * 500) + 100;
                      data["projectId"] = props.id;
                      data["totalKoin"] = total;
                      data["totalLot"] = total / 100;
                      data["totalPrice"] = total * 10 * 100;
                      data["userId"] = user.id;
                      data["title"] = props.title;
                      setCarts(data);
                      setTransaction(true);
                    } else {
                      localStorage.setItem("lastpage", window.location.href);
                      window.location.assign("/login");
                    }
                  }}
                  className="text-link2 text-white p-3 bg-orange"
                >
                  Beli
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
