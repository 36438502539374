import CardTeam from "./cardteam";

function Team() {

    const driveImg = (data) => {
        return data.replace("https://drive.google.com/file/d/","https://drive.google.com/uc?export=view&id=").replace("/view?usp=sharing","")
    }

    return (
      <div>
          <section id="team" className="team section-bg">
            <div className="container">

                <div className="section-title">
                <h2>Team</h2>
                <p>Pengurus Koperasi</p>
                </div>

                <div className="row">
                {/* <div className="col-xl-3 col-lg-3 col-md-6">
                    <CardTeam 
                    name="Ahmadi AS"
                    position="Ketua Umum"
                    url="https://gemaindonesia.id/wp-content/uploads/2021/10/Ahmadi-Ketua.jpg"
                    fb="https://www.facebook.com/ahmadiamrun"
                    linkedin="https://www.linkedin.com/in/ahmadiamrun"
                    ig="https://www.instagram.com/ahmadilurah/"
                    />
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6">
                    <CardTeam 
                    name="Singgih Andi Purbowo"
                    position="Ketua"
                    url="https://gemaindonesia.id/wp-content/uploads/2021/10/andi.jpg"
                    fb="https://www.facebook.com/singgih.purbowo"
                    linkedin="https://www.linkedin.com/in/singgih-andi-purbowo-398177154/"
                    ig="https://www.instagram.com/singgih_andi_purbowo/"
                    />
                </div> */}
                <div className="col-xl-4 col-lg-4 col-md-6">
                    <CardTeam 
                    name="Budi Turmoko"
                    position="Ketua Dewan Pengurus"
                    url="https://gemaindonesia.id/wp-content/uploads/2021/11/Profil-Moko.jpg"
                    fb="https://www.facebook.com/Budi.Turmoko"
                    linkedin="https://www.linkedin.com/in/mokokonveksi"
                    ig="https://www.instagram.com/moko_java/"
                    />
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                    <CardTeam 
                    name="Firman Dwi Nurdiansyah"
                    position="Sekertaris"
                    url= {driveImg("https://drive.google.com/file/d/15Xhh-Ib5cKBrN2KkWXGJvwMrteK9qElt/view?usp=sharing")}
                    fb=""
                    linkedin=""
                    ig=""
                    />
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                    <CardTeam 
                    name="Ustadz Lukman Hakim"
                    position="Ketua Dewan Pengawas"
                    url="https://gemaindonesia.id/wp-content/uploads/2021/10/Luqman.jpg"
                    fb=""
                    linkedin=""
                    ig=""
                    />
                </div>
                {/* <div className="col-xl-3 col-lg-4 col-md-6">
                    <CardTeam 
                    name="Firman Dwi"
                    position="Content Writer"
                    url="https://gemaindonesia.id/wp-content/uploads/2021/10/Firman.jpg"
                    />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6">
                    <CardTeam 
                    name="Litan Adhip"
                    position="Freelance Designer"
                    url="https://gemaindonesia.id/wp-content/uploads/2021/10/Lintan.jpg"
                    />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6">
                    <CardTeam 
                    name="David Agus H"
                    position="Developer"
                    url="https://gemaindonesia.id/wp-content/uploads/2021/10/David.jpg"
                    />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6">
                    <CardTeam 
                    name="Chelsea Branum"
                    position="Enterpreneur"
                    url="https://stage.startertemplatecloud.com/g11/wp-content/uploads/sites/8/2021/03/face-5-min.jpg"
                    />
                </div> */}
                </div>
            </div>
        </section>
      </div>
    );
  }
  
  export default Team;
  