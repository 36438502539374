import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import axios from "axios";
import Header from "../Header/header";

async function registerUser(credentials) {
  // console.log(credentials);
  return axios
    .post("https://admin.gemaindonesia.id/admin/v1/auth/register", credentials)
    .then((result) => result.data.data)
    .catch((err) => err.response);
}

export default function Register({ setToken }) {
  const [formReg, setFormReg] = useState([
    {
      name: "name",
      type: "text",
      label: "Name",
      value: "",
      size: 12,
      placeholder: "Enter your name",
      valid: false,
      validation: {
        required: true,
      },
    },
    {
      name: "email",
      label: "Email",
      type: "text",
      value: "",
      size: 6,
      placeholder: "Enter your email",
      valid: false,
      validation: {
        required: true,
        type: "email",
      },
    },
    {
      name: "username",
      label: "Username",
      type: "text",
      size: 6,
      value: "",
      placeholder: "Enter your username",
      valid: false,
      validation: {
        required: true,
      },
    },
    {
      name: "password",
      label: "Password",
      type: "password",
      value: "",
      size: 6,
      isShow: false,
      placeholder: "Enter your password",
      valid: false,
      validation: {
        required: true,
        minlength: 6,
      },
    },
    {
      name: "passwordConfirmation",
      label: "Password Confirmation",
      type: "password",
      value: "",
      size: 6,
      isShow: false,
      placeholder: "Enter your password confirmation",
      valid: false,
      validation: {
        required: true,
        minlength: 6,
      },
    },
  ]);
  const [remember, setRemember] = useState(false);

  // set password show
  const setPasswordShow = () => {
    const newFormReg = [...formReg];
    newFormReg[1].isShow = !newFormReg[1].isShow;
    setFormReg(newFormReg);
  };

  // create handle change with valdiation
  const handleChange = (e) => {
    // check input is email or not
    const isEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
      e.target.value
    );

    const { name, value } = e.target;
    let valid = true;
    let validation = formReg.find((el) => el.name === name).validation;
    if (validation) {
      if (validation.required) {
        valid = value.trim() !== "";
      }
      if (validation.type) {
        valid = valid && isEmail;
      }
      if (validation.minlength) {
        valid = valid && value.length >= validation.minlength;
      }
    }

    // password confirmation validation
    if (name === "passwordConfirmation") {
      if (value !== formReg.find((el) => el.name === "password").value) {
        valid = false;
      }
    }

    const newForm = formReg.map((el) => {
      if (el.name === name) {
        el.value = value;
        el.valid = valid;
      }
      return el;
    });
    setFormReg(newForm);
  };

  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  const generateString = (length) => {
    let result = " ";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  };

  function pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  const generateHours = (data) => {
    return (
      generateString(24).replace(" ", "") +
      data +
      generateString(24).replace(" ", "")
    );
  };

  const handleSubmit = async (e) => {
    // get formReg value
    const formData = {};
    formReg.forEach((el) => {
      formData[el.name] = el.value;
    });

    e.preventDefault();
    const token = await registerUser(formData);
    if (token) {
      window.location = "/";
    } else {
      // console.clear();
    }
  };

  return (
    <>
      <Header />
      <div className="bg-grey mt-5">
        <div className="container d-flex">
          <div className="row mx-auto my-auto">
            <div className="col-lg-3"></div>
            <div className="col-lg-6">
              <div className="card">
                <div className="card-body p-5">
                  <div className="text-center">
                    <img
                      src="https://i.ibb.co/K25ykWZ/logo-gema-169x60px.png"
                      alt=""
                      width={150}
                    />
                  </div>
                  <div className="row">
                    {formReg.map((el, index) => {
                      return (
                        <>
                          <div className={`col-md-${el.size}`} key={index}>
                            <div className="form-group">
                              <label>{el.label}</label>
                              <input
                                type={el.isShow ? "text" : el.type}
                                name={el.name}
                                className={
                                  el.valid
                                    ? "form-control is-valid"
                                    : "form-control is-invalid"
                                }
                                placeholder={el.placeholder}
                                onChange={handleChange}
                                value={el.value}
                              />
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                  <div class="form-check mt-3">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      name=""
                      id=""
                      value="checkedValue"
                      onChange={setPasswordShow}
                    />
                    <label class="form-check-label" for="">
                      Show Password
                    </label>
                  </div>
                  <div class="d-grid gap-2 mt-3">
                    <button
                      onClick={handleSubmit}
                      type="button"
                      name=""
                      id=""
                      class="btn btn-orange"
                    >
                      Register
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3"></div>
          </div>
        </div>
      </div>
    </>
  );
}

Register.propTypes = {
  setToken: propTypes.func.isRequired,
};
