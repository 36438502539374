import { Link } from "react-router-dom";
function Breadcumbs(props) {
    return (
      <div>
        <section id="breadcrumbs" className="breadcrumbs">
        <div className="container">

          <ol>
            <li><Link to="/">Home</Link></li>
            <li>{props.name}</li>
          </ol>
          <h2>{props.name}</h2>

        </div>
      </section>
      </div>
    );
  }
  
  export default Breadcumbs;
  