import React, { Component } from 'react'
import Layout2 from '../Layout/Layout2'
import { Link } from 'react-router-dom'
import parse from 'html-react-parser';
import moment from 'moment'
import axios from 'axios';
import { seo } from '../../helper/seo';
import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon
  } from "react-share";
  import {
    EmailShareButton,
    FacebookShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton
  } from "react-share";


function prettyDate(time) {
    var date = new Date((time || "").replace(/-/g, "/").replace(/[TZ]/g, " ")),
        diff = (((new Date()).getTime() - date.getTime()) / 1000),
        day_diff = Math.floor(diff / 86400);
    var year = date.getFullYear(),
        month = date.getMonth()+1,
        day = date.getDate();
  
    if (isNaN(day_diff) || day_diff < 0 || day_diff >= 31)
        return (
            year.toString()+'-'
            +((month<10) ? '0'+month.toString() : month.toString())+'-'
            +((day<10) ? '0'+day.toString() : day.toString())
        );
  
    var r =
    ( 
        (
            day_diff == 0 && 
            (
                (diff < 60 && "Just now")
                || (diff < 120 && "1 minute ago")
                || (diff < 3600 && Math.floor(diff / 60) + " minutes ago")
                || (diff < 7200 && "1 hour ago")
                || (diff < 86400 && Math.floor(diff / 3600) + " hours ago")
            )
        )
        || (day_diff == 1 && "Yesterday")
        || (day_diff < 7 && day_diff + " days ago")
        || (day_diff < 31 && Math.ceil(day_diff / 7) + " weeks ago")
    );
    return r;
  }


export default class Post extends Component {


    state = {
        post: {
            categories:{
                nodes: []
            },
            featuredImage:{
                node: {
                    mediaItemUrl: ""
                }
            },
            content: "",
            comments: {
                nodes: []
            },
            excerpt: ""
        },
        comment: "",
        name: "",
        excerpt: "aaaa",
        isDisabled: false,
        isLoading: true
    }

    postComment = async () => {
        this.setState({
            isDisabled: true
        })
        let res = await fetch('https://gemaindonesia.id/sandi/graphql', {
          method: "POST",
          headers: {'Content-Type' : 'application/json'},
          body: JSON.stringify({
            query: `
              mutation CREATE_COMMENT {
                createComment(
                  input: {commentOn: ${this.state.post.postId}, content: "${this.state.comment}", author: "${this.state.name || "Anonymous"}"}
                  ) {
                    success
                    comment {
                      id
                      content
                      author {
                        node {
                        name
                      }
                    }
                  }
                }
              }
              `
            })
        })
        window.location.reload()
      }

    share = () => {
        return (
            <>
            <div className="btn-share">          <WhatsappShareButton
              url={'https://gemaindonesia.id/artikel/' + this.state.post.slug}
              title={this.state.post.title}
              separator=" - "
            >
              <WhatsappIcon size={40} round />
            </WhatsappShareButton></div>
            <div className="btn-share">          <FacebookShareButton
              url={'https://gemaindonesia.id/artikel/' + this.state.post.slug}
              hashtag={'#gemaindonesia'}
            >
              <FacebookIcon size={40} round />
            </FacebookShareButton></div>
            <div className="btn-share">          <TelegramShareButton
              url={'https://gemaindonesia.id/artikel/' + this.state.post.slug}
              title={this.state.post.title}
            >
              <TelegramIcon size={40} round />
            </TelegramShareButton></div>
            <div className="btn-share">          <LineShareButton
              url={'https://gemaindonesia.id/artikel/' + this.state.post.slug}
              title={this.state.post.title}
            >
              <LineIcon size={40} round />
            </LineShareButton></div>
            <div className="btn-share">          <TwitterShareButton
              url={'https://gemaindonesia.id/artikel/' + this.state.post.slug}
              title={this.state.post.title}
            >
              <TwitterIcon size={40} round />
            </TwitterShareButton></div>
            </>
        )
      }
      

      share2 = () => {
        return (
            <>
            <div className="btn-share">          <WhatsappShareButton
              url={'https://gemaindonesia.id/artikel/' + this.state.post.slug}
              title={this.state.post.title}
              separator=":: "
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton></div>
            <div className="btn-share">          <FacebookShareButton
              url={'https://gemaindonesia.id'}
              hashtag={'#gemaindonesia'}
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton></div>
            <div className="btn-share">          <TelegramShareButton
              url={'https://gemaindonesia.id/artikel/' + this.state.post.slug}
              title={this.state.post.title}
            >
              <TelegramIcon size={32} round />
            </TelegramShareButton></div>
            <div className="btn-share">          <LineShareButton
              url={'https://gemaindonesia.id/artikel/' + this.state.post.slug}
              title={this.state.post.title}
            >
              <LineIcon size={32} round />
            </LineShareButton></div>
            <div className="btn-share">          <TwitterShareButton
              url={'https://gemaindonesia.id/artikel/' + this.state.post.slug}
              title={this.state.post.title}
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton></div>
            </>
        )
      }
    
    getPost = () => {
        const slug = this.props.slug

        axios({
          url: 'https://gemaindonesia.id/sandi/graphql',
          method: 'post',
          data: {
            query: `
            query Post {
              post(
                idType: SLUG
                id: "${slug}"
              ) {
                comments {
                  nodes {
                    content
                    author {
                      node {
                        name
                      }
                    }
                    date
                    approved
                    commentedOn {
                      node {
                        id
                      }
                    }
                  }
                }
                postId
                title
                slug
                date
                content
                excerpt
                featuredImage {
                  node {
                    mediaItemUrl
                  }
                }
                author {
                  node {
                    name
                  }
                }
                categories {
                  nodes {
                    name
                    posts {
                      nodes {
                        title
                        slug
                        featuredImage {
                          node {
                            mediaItemUrl
                          }
                        }
                        author {
                          node {
                            name
                            avatar {
                              url
                            }
                          }
                        }
                        date
                      }
                    }
                  }
                }
              }
            }       
              `
          }
        }).then((result) => {
          this.setState({
            post: result.data.data.post,
            isLoading: false
        })
        }).catch(error => {
            this.getPost()
        });
    }

    formChange = (e) => {
        this.setState({
            comment: e.target.value
        })
      }
    formChange2 = (e) => {
        this.setState({
            name: e.target.value
        })
      }

    getSeo = async (data, data2) =>{
      const a = parse(data2)
      const b = a.map(exc => {
        if(exc.key == 0){
          return exc.props.children
        }
      })
      seo({
        title:data + " | Gema Indonesia",
        metaDescription: await b[0]
      })
    }

    componentDidMount = () => {
        this.getPost()
    }

    getPostContent = () => {
      if(this.state.isLoading){
        return(
          <>
            <div className="article-loader-title">
            </div>
            <div className="article-loader-date">
            </div>
            <div className="article-loader-img">
            </div>
            <div className="article-loader-paragraph">
            </div>
            <div className="article-loader-paragraph">
            </div>
            <div className="article-loader-paragraph">
            </div>
            <div className="article-loader-paragraph2">
            </div>
          </>
        )
      }else{
        return(
          <>
          <div className="article-title">
          {this.state.post.title}
          </div>
          <div className="article-date">
              {moment(this.state.post.date).utc().format('MMMM DD, YYYY')} | <span className="article-category">{
              this.state.post.categories.nodes.map(categories => {
                  return categories.name
              })
          }</span>
          </div>
          <div className="article-img">
          <img src={this.state.post.featuredImage.node.mediaItemUrl} alt=''/>
          </div>
          <div dangerouslySetInnerHTML={{ __html: this.state.post.content }} />
          </>
        )
      }
    }
    

    render() {
      this.getSeo(this.state.post.title, this.state.post.excerpt)
        return (
            <>
            <Layout2>
            <div className="container">
                <div className="wrap">
                    <div className="article-content">
                        <div className="row">
                            <div className="col-lg-2">
                            <div className="widget-wrapper">
                      <div className="widget">
                      <span>Share</span>
                      <hr />
                      {this.share()}
                      <hr />
                      </div>
                      </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="article-cat">
                                <section id="breadcrumbs" className="breadcrumbs">
                                    <ol>
                                        <li><Link href="/">Home</Link></li>
                                        <li>{this.state.post.title}</li>
                                    </ol>
                                </section>
                                {this.getPostContent()}
                                <hr />
                                    <div className="article-share">
                                    <span>Share</span>
                                    {this.share2()}
                                    </div>
                                    <hr />
                                    <div className="article-comment">
                                        <div className="comment-section">
                                            {/* {console.log(this.state.post.comments.nodes)} */}
                                            {this.state.post.comments.nodes.map(com => {
                                                const getComment = parse(com.content)
                                                const commentContent= getComment[0].props.children
                                                const commentDate = com.date
                                                const commentAuthor = com.author.node.name
                                                if(com.approved){
                                                    return (
                                                    <>
                                                        <div className="comment-detail">
                                                        <div className="comment-img">
                                                        <img src="https://stonegatesl.com/wp-content/uploads/2021/01/avatar-300x300.jpg" width="50px" height="50px" />
                                                        </div>
                                                        <div className="detail-left">
                                                        <div className="author">
                                                        {commentAuthor}
                                                        </div>
                                                        {commentDate}
                                                        <div className="comment-content">
                                                        {commentContent}
                                                        </div>
                                                        </div>
                                                        </div>
                                                    </>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </div>
                                    <div className="post-comment">
                                    <span>Leave Reply</span>
                                    <input type="text" className="form-control my-3" placeholder="Name" onChange={this.formChange2} />
                                    <textarea name="" id="" className="form-control" placeholder="Write a response..." rows={10} onChange={this.formChange}></textarea>
                                    <button className="btn btn-dark mt-3" onClick={()=>{
                                    this.postComment()
                                    }} disabled={this.state.isDisabled}>Post Comment</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </Layout2>
            </>
        )
    }
}
