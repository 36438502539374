

function Contact() {
    return (
      <div>
        <section id="contact" className="contact section-bg">
        <div className="container">

            <div className="section-title">
            <h2>Contact</h2>
            <p>Contact Us</p>
            </div>

            <div className="row">

            <div className="col-lg-6">

                <div className="row">
                <div className="col-md-12">
                    <div className="info-box">
                    <i className="bx bx-map"></i>
                    <h3>Alamat Kami</h3>
                    <p>Jl. Weleri- Gemuh KM.4 No.234, RT.02/RW.03, Krajan, Purworejo, Kec. Ringinarum, Kabupaten Kendal, Jawa Tengah 51356</p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="info-box mt-4">
                    <i className="bx bx-envelope"></i>
                    <h3>Email Us</h3>
                    <p>cs@gemaindonesia.id</p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="info-box mt-4">
                    <i className="bx bx-phone-call"></i>
                    <h3>Call Us</h3>
                    <p>+6285163629898</p>
                    </div>
                </div>
                </div>

            </div>

            <div className="col-lg-6">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4831.809323734074!2d110.1221755!3d-6.9702953!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e70435ec14cb3dd%3A0x1319bea17787548c!2sGema%20Indonesia%20Cooperation!5e1!3m2!1sid!2sid!4v1636169323482!5m2!1sid!2sid" width="100%" height="365px" style={{ "border": "0" }} allowFullScreen="" loading="lazy"></iframe>
            </div>

            </div>

        </div>
        </section>
      </div>
    );
  }
  
  export default Contact;
  