import Header from "../../../components/Header/header";
import Breadcumbs from "../../../components/Breadcumbs/breadcumbs";
import Footer from "../../../components/Footer/footer";
import { seo } from "../../../helper/seo";
import { Link } from "react-router-dom";

function SyaratMenjadiInvestee(props) {
  seo({
    title:"Syarat Menjadi Investee | Gema Indonesia",
    metaDescription: `
    1. Sudah menjadi Anggota aktif Koperasi GEMA
    2. Memiliki izin usaha dari pemerintah, minimal NIB / SIUP
    3. Memiliki NPWP
    4. Menyetujui Syarat dan Ketentuan Anggota dan Investee untuk mendapatkan modal
    dari Koperasi GEMA dengan segala sesuatu konsekuensi yang timbul
    5. UMKM setuju untuk di seleksi
    `,
  })
  return (
    <div>
      {window.scrollTo(0, 0)}
        <Header/>
        <section className="page-header">
                <div className="container">
                    <div className="title">
                    <h1>Syarat Menjadi UMKM Investee</h1>
                    </div>
                </div>
            </section>
        <section className="inner-page">
        <div className="container">
            <div className="wrap">
            <h3>Syarat Menjadi Investee</h3>
            <p>
                <ol>
                    <li>Sudah menjadi Anggota aktif Koperasi GEMA</li>
                    <li>Memiliki izin usaha dari pemerintah, minimal NIB / SIUP</li>
                    <li>Memiliki NPWP</li>
                    <li>Menyetujui Syarat dan Ketentuan Anggota dan Investee untuk mendapatkan modal
                    dari Koperasi GEMA dengan segala sesuatu konsekuensi yang timbul</li>
                    <li>UMKM setuju untuk di seleksi</li>
                </ol>
            </p>
            <Link to="/register" className="btn btn-orange">Daftar Jadi UMKM Investee</Link>
            </div>
        </div>
        </section>
        <Footer />
    </div>
  );
}

export default SyaratMenjadiInvestee;
