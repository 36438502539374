import Header from "../../../components/Header/header";
import Breadcumbs from "../../../components/Breadcumbs/breadcumbs";
import Footer from "../../../components/Footer/footer";

function SNK(props) {
  return (
    <div>
        {window.scrollTo(0, 0)}
        <Header/>
        <section className="page-header">
                <div className="container">
                    <div className="title">
                    <h1>Syarat Dan Ketentuan</h1>
                    </div>
                </div>
            </section>
        <section className="inner-page">
        <div className="container">
            <div className="wrap">
            <h3 className="mb-4">Syarat dan Ketentuan Anggota Koperasi</h3>
            <h6>Definisi</h6>
            <p>
                <ol>
                    <li>“Koperasi” adalah Koperasi GEMA (Gerakan Masyarakat Madani), berkedudukan di Kendal,
yang terdiri dari kantor pusat, Customer Service, Marketing Lapangan, Surveyor serta
berbagai divisi lainnya.</li>
                    <li>“Akun” adalah rekening simpanan yang dibuka secara mandiri oleh Calon Anggota dan
Anggota, setelah mendapatkan pendaftaran anggota berhasil.</li>
                    <li>“Calon Anggota” adalah individu yang sudah mendaftar di gemaindonesia.id tetapi belum
menyelesaikan kewajiban sebagai anggota.</li>
                    <li>“Anggota” adalah calon anggota yang telah memenuhi kewajiban Simpanan Pokok dan
Simpanan Wajib.</li>
                    <li>“Simpanan Pokok” adalah simpanan yang harus dibayarkan calon anggota koperasi saat
pertama kali menjadi anggota.</li>
                    <li>“Simpanan Wajib” adalah simpanan yang harus dibayarkan oleh calon anggota dan Anggota
koperasi kepada koperasi dalam waktu dan kesempatan tertentu.</li>
                    <li>“SHU” adalah sisa hasil usaha koperasi yang diperoleh dari selisih antara pendapatan dan
biaya.</li>
                </ol>
            </p>
            <h6>Syarat & Ketentuan</h6>
            <p>
                <ol>
                    <li>Mematuhi dan mentaati Anggaran Dasar, Anggaran Rumah Tangga dan Keputusan Pengurus
Koperasi GEMA</li>
                    <li>Membela dan menjunjung tinggi nama baik Koperasi GEMA.</li>
                    <li>Bersedia mengikuti program-program yang dibuat oleh Koperasi GEMA yang bertujuan untuk
mensejahterakan Anggota.</li>
                    <li>Calon Anggota akan menjadi Anggota setelah menyetor simpanan pokok dan simpanan
wajib.</li>
                    <li>Calon anggota diberikan waktu 90 hari sejak pembukaan Akun untuk menyetor simpanan
pokok dan simpanan wajib. Apabila tidak dilakukan, maka status calon anggotanya
dinyatakan berakhir.</li>
                    <li>Nilai Simpanan Pokok sebesar Rp.130.000,- dan Simpanan Wajib sebesar Rp.10.000,- per
bulan yang dibayarkan secara langsung di awal yaitu sebesar Rp.120.000,- sehingga total simpanan pokok dan Simpanan Wajib Rp. 250.000,-</li>
                    <li>Uang Simpanan Pokok dan Simpanan Wajib tidak dapat diambil atau ditarik kembali selama
Anggota masih terdaftar dalam buku anggota Koperasi.</li>
                    <li>Simpanan yang bukan merupakan Simpanan Pokok dan Simpanan Wajib dapat diambil atau
ditarik kembali sesuai ketentuan yang telah disepakati.</li>
                    <li>Rapat Anggota Koperasi dilaksanakan dengan sistem delegasi yang mewakili anggota dalam
setiap provinsi yang tertuang dalam Anggaran Rumah Tangga Koperasi.</li>
                    <li>Pembagian SHU dibagi berdasarkan Simpanan Pokok, Simpanan Wajib, Simpanan Lain-lain
yang masing-masing memiliki bobot persentase berbeda yang selanjutnya diatur dalam
Anggaran Rumah Tangga.</li>
                    <li>Calon Anggota atau Anggota yang melanggar ketentuan Anggaran Dasar, Anggaran Rumah
Tangga, maupun peraturan lain yang berlaku di dalam Koperasi akan dikenakan sanksi sesuai
dengan peraturan yang berlaku.</li>
                </ol>
            </p>
            <h3 className="my-4">Syarat dan Ketentuan Investor</h3>
            <h6>Definisi</h6>
            <p>
            <ol>
                <li>Layanan Urun Dana adalah suatu program layanan urun dana yang diselenggarakan oleh
Penyelenggara dengan melakukan penawaran Unit Penyertaan Dana milik Penerbit kepada Investor atau
masyarakat umum melalui jaringan system elektronik layanan urun dana melalui penawaran
Unit Penyertaan Dana berbasis teknologi informasi (equity crowdfunding) milik Penyelenggara yang bersifat
terbuka.</li>
                <li>Penyelenggara adalah Koperasi GEMA, merupakan pihak yang menyediakan, mengelola, dan
mengoperasikan Layanan Urun Dana.</li>
                <li>Pengguna adalah Investee dan Investor.</li>
                <li>Investee atau penerbit Unit Penyertaan Dana atau penerima modal merupakan badan organisasi berbentuk
unit Usaha Mikro, Kecil dan Menengah (UMKM), merupakan pihak yang melaksanakan
Layanan Urun Dana menawarkan Unit Penyertaan Dana berbasis teknologi melalui Penyelenggara.</li>
                <li>Investor adalah pihak yang melakukan pembelian Unit Penyertaan Dana Penerbit melalui Penyelenggara.</li>
                <li>Unit Penyertaan Dana adalah nilai Unit Penyertaan Dana milik Penerbit selama Layanan Urun Dana berlangsung.</li>
                <li>Buyback adalah proses dimana Penerbit melakukan pembelian kembali Unit Penyertaan Dana yang telah
dijual oleh Penerbit kepada Investor.</li>
                <li>Hari Kalender adalah hari Senin sampai dengan hari Minggu, termasuk hari libur Nasional yang
ditetapkan Pemerintah Indonesia sebagaimana perhitungan tahun kalender Masehi.</li>
                <li>Hari Kerja adalah hari kerja dimana Penyelenggara beroperasi.</li>
            </ol>
            </p>
            <h6>PELAKSANAAN LAYANAN URUN DANA</h6>
            <p>Investor dengan ini mengajukan pendaftaran kepada Penyelenggara dan Penyelenggara
menerima pendaftaran dari Investor sebagai Anggota dalam rangka untuk melaksanakan
pembelian Unit Penyertaan Dana milik Penerbit melalui program Layanan Urun Dana dengan maksud dan tujuan,
lingkup Layanan Urun Dana, syarat dan ketentuan, serta batas tanggung jawab sesuai dengan
syarat dan ketentuan.</p>
<h6>MASA PENAWARAN Unit Penyertaan Dana</h6>
            <p>
                <ol>
                    <li>Dalam hal ini Investor melakukan pembelian Unit Penyertaan Dana Penerbit selama masa penawaran Unit Penyertaan Dana
oleh Penerbit yang dilakukan paling lama 60 (enam puluh) Hari Kalender.</li>
                    <li>Investor mengerti dan memahami bahwa Penerbit dapat membatalkan penawaran Unit Penyertaan Dana
melalui Layanan Urun Dana sebelum berakhirnya masa penawaran Unit Penyertaan Dana dengan membayar
sejumlah denda kepada Penyelenggara.</li>
                </ol>
            </p>
            <h6>PEMBELIAN Unit Penyertaan Dana</h6>
            <p>
                <ol>
                    <li>Pembelian Unit Penyertaan Dana oleh Investor dalam penawaran Unit Penyertaan Dana melalui Layanan Urun Dana
dilakukan dengan menyetorkan sejumlah dana pada akun atau rekening.</li>
                    <li>Tidak ada batasan pembelian Unit Penyertaan Dana di Koperasi GEMA</li>
                </ol>
            </p>
            <h6>PENYERAHAN DANA DAN Unit Penyertaan Dana</h6>
            <p>
                <ol>
                    <li>Investor mengerti dan memahami bahwa Penyelenggara wajib menyerahkan dana dari
Investor kepada Penerbit melalui Penyelenggara, paling lambat 21 (dua puluh satu) Hari Kerja
setelah berakhirnya masa penawaran Unit Penyertaan Dana.</li>
                    <li>Manfaat bersih dari penempatan dana dikembalikan kepada Investor secara proporsional.</li>
                    <li>Berakhirnya masa penawaran adalah:
                        <ol type="a">
                            <li>Tanggal tertentu yang telah ditetapkan dan disepakati oleh Para Pihak; atau</li>
                            <li>tanggal tertentu berakhirnya masa penawaran Unit Penyertaan Dana namun seluruh Unit Penyertaan Dana yang
ditawarkan melalui Layanan Urun Dana telah dibeli oleh Investor.</li>
                        </ol>
                    </li>
                    <li>Investor mengerti dan memahami bahwa Penerbit wajib menyerahkan Unit Penyertaan Dana kepada
Penyelenggara untuk didistribusikan kepada Investor paling lambat 5 (lima) Hari Kerja setelah
Penerbit menerima dana Investor dari Penyelenggara.</li>
                    <li>Investor mengerti dan memahami bahwa Penyelenggara wajib mendistribusikan Unit Penyertaan Dana
kepada Investor paling lambat 10 (sepuluh) Hari Kerja setelah menerima Unit Penyertaan Dana dari Penerbit.</li>
                    <li>Pendistribusian Unit Penyertaan Dana kepada Investor oleh Penyelenggara dapat dilakukan secara elektronik
melalui penitipan kolektif pada kustodian atau pendistribusian secara fisik melalui pengiriman
sertifikat Unit Penyertaan Dana.</li>
                    <li>Investor mengerti dan memahami bahwa Penerbit diwajibkan menetapkan jumlah minimum
dana yang harus diperoleh dalam penawaran Unit Penyertaan Dana melalui Layanan Urun Dana, dan apabila
jumlah minimum dana yang telah ditentukan oleh Penerbit tersebut tidak terpenuhi, maka
penawaran Unit Penyertaan Dana melalui Layanan Urun Dana tersebut dinyatakan batal demi hukum.</li>
                    <li>Investor mengerti dan memahami bahwa dalam hal penawaran Unit Penyertaan Dana batal demi hukum,
maka Penyelenggara wajib mengembalikan dana beserta seluruh manfaat yang timbul dari
dana tersebut ke dalam saldo deposit Investor di platform Penyelenggara secara proporsional
kepada Investor paling lambat 2 (dua) Hari Kerja setelah penawaran Unit Penyertaan Dana dinyatakan batal
demi hukum.</li>
                    <li>Bagi Investor yang transaksinya tidak valid atau valid sebagian, maka Koperasi GEMA akan
menghubungi Investor untuk melakukan konfirmasi. Apabila Investor tidak melakukan
konfirmasi balik selama 5 (lima) Hari Kerja kepada Penyelenggara, maka transaksi Investor
tersebut dimasukkan ke dalam saldo deposit Investor di platform Penyelenggara yang
sewaktu-waktu dapat ditarik oleh Investor.</li>
                    <li>Dalam hal transaksi pembelian Unit Penyertaan Dana Investor dilakukan pada saat Unit Penyertaan Dana telah dinyatakan
habis/soldout, maka Investor berhak atas pengembalian pembelian Unit Penyertaan Dana dengan
melakukan konfirmasi kepada Penyelenggara melalui media komunikasi yang telah disediakan
oleh Penyelenggara.</li>
                    <li>Pengembalian pembayaran pembelian Unit Penyertaan Dana tersebut akan masuk ke dalam saldo deposit
Investor di platform Penyelenggara yang sewaktu-waktu dapat ditarik oleh Investor.</li>
                </ol>
            </p>
            <h6>DAFTAR PEMEGANG Unit Penyertaan Dana</h6>
            <p>
                <ol>
                    <li>Investor mengerti dan memahami bahwa Penerbit wajib mencatatkan kepemilikan Unit Penyertaan Dana
Investor dalam daftar pemegang Unit Penyertaan Dana.</li>
                    <li>Persetujuan Investor terhadap Syarat dan Ketentuan ini berarti Investor setuju dan sepakat
bahwa Investor memberikan kuasa kepada Penyelenggara untuk mewakili Investor sebagai
pemegang Unit Penyertaan Dana Penerbit termasuk dalam Rapat Umum Pemegang Unit Penyertaan Dana (“RUPS”)
Penerbit dan penandatanganan akta serta dokumen terkait lainnya</li>
                </ol>
            </p>
            <h6>PENGHIMPUNAN DANA</h6>
            <p>
                <ol>
                    <li>Investor mengerti dan memahami bahwa pembagian Imbal Hasil kepada para pemegang Unit Penyertaan Dana
tidak bersifat lifetime karena Penerbit merupakan badan usaha berbadan hukum berhak
melakukan Buyback sebagaimana diatur dalam akta anggaran dasar Penerbit dan peraturan
perundang-undangan yang berlaku.</li>
                    <li>Investor mengerti dan memahami bahwa pembagian Imbal Hasil Penerbit diinformasikan di
dalam kebijakan Imbal Hasil dan didasarkan pada laba bersih Penerbit setelah dikurangi dengan
pencadangan. Mekanisme pembagian Imbal Hasil lainnya (termasuk pembagian Imbal Hasil
interim) mengacu pada anggaran dasar Penerbit dan peraturan perundang-undangan yang
berlaku.</li>
                    <li>Investor mengerti dan memahami bahwa pembagian Imbal Hasil final Penerbit mengacu pada
persetujuan Rapat Umum Pemegang Unit Penyertaan Dana (“RUPS”) Penerbit.</li>
                    <li>Investor mengerti dan memahami bahwa apabila terdapat beban operasional usaha yang
harus dikeluarkan setiap periode tertentu, Penerbit tidak memiliki hak untuk
membebankannya kepada Investor, melainkan beban tersebut dimasukkan ke dalam
penghitungan biaya operasional yang kemudian dilaporkan dalam laporan keuangan
periode tersebut.</li>
                </ol>
            </p>
            <h6>KEWAJIBAN INVESTOR</h6>
            <p>Tanpa mengesampingkan hak dan kewajiban lainnya sebagaimana telah tersebut dalam
Perjanjian ini, maka kewajiban Penyelenggara adalah sebagai berikut:
                <ol>
                    <li>Penyelenggara wajib memenuhi seluruh hak-hak Investor</li>
                    <li>Penyelenggara memonitor, menganalisa, dan memastikan bahwa Pengguna berada di jalur
yang sesuai dengan visi misi Penyelenggara dan Layanan Urun Dana.</li>
                    <li>Penyelenggara bertanggung jawab melakukan ganti rugi atas setiap kerugian Investor yang
timbul disebabkan oleh kelalaian karyawan ataupun direksi Penyelenggara.</li>
                </ol>
            </p>
            <h6>HAK PENYELENGGARA</h6>
            <p>Tanpa mengesampingkan hak dan kewajiban lainnya sebagaimana telah tersebut dalam
Perjanjian ini, maka hak Penyelenggara adalah :
                <ol>
                    <li>Penyelenggara berhak atas manfaat dari Investor atas Layanan Urun Dana yang sedang
berlangsung.</li>
                </ol>
            </p>
            <h6>PERPAJAKAN</h6>
            <p>Pembebanan pajak yang timbul dalam Layanan Urun Dana ini menjadi beban masing-masing
pihak sesuai dengan ketentuan hukum perpajakkan yang berlaku di wilayah Negara Republik
Indonesia.</p>
            <h6>HAK ATAS KEKAYAAN INTELEKTUAL</h6>
            <p>
                <ol>
                    <li>Hak atas kekayaan intelektual yang timbul atas pelaksanaan Layanan Urun Dana dan izin
Penyelenggara, beserta fasilitas-fasilitas lain yang dimiliki Penyelenggara dan digunakan
dalam Layanan Urun Dana ini adalah tetap dan seterusnya milik Penyelenggara dan tidak ada
penyerahan hak dari Penyelenggara kepada Investor dalam Layanan Urun Dana ini.</li>
                    <li>Investor tidak berhak untuk mengubah, mengembangkan, membagikan dan/atau menjual
baik seluruh maupun sebagian hak atas kekayaan intelektual yang timbul atas
pengembangan, inovasi, perubahan berupa fitur dan/atau fungsi terhadap sistem teknologi
informasi.</li>
                    <li>Penyelenggara dengan ini menjamin bahwa hak atas kekayaan intelektual yang terkandung
dalam pelaksanaan Layanan Urun Dana ini tidak melanggar hak atas kekayaan intelektual
milik pihak manapun, dan Penyelenggara membebaskan Investor dari segala tuntutan,
gugatan dari pihak manapun, sehubungan dengan pelanggaran terhadap hak atas kekayaan
intelektual yang terkandung dalam Layanan Urun Dana sesuai dengan syarat dan ketentuan
ini.</li>
                </ol>
            </p>
            <h6>JANGKA WAKTU DAN PENGAKHIRAN</h6>
            <p>
                <ol>
                    <li>Jangka waktu Layanan Urun Dana antara Penyelenggara dan Investor ini berlaku selama
Penerbit turut serta dalam Layanan Urun Dana.</li>
                    <li>Layanan Urun Dana ini berakhir dengan sendirinya, apabila :
                        <ol type="a">
                            <li>Penerbit melakukan Buyback Unit Penyertaan Dana;</li>
                            <li>Diakhiri oleh Penyelenggara</li>
                        </ol>
                    </li>
                    <li>Dalam hal Layanan Urun Dana ini berakhir dan/atau dinyatakan berakhir, maka Para Pihak
sepakat bahwa ketentuan Informasi Rahasia sebagaimana diatur dalam syarat dan ketentuan
ini tetap berlaku dan mengikat Para Pihak hingga kapanpun meskipun Layanan Urun Dana
telah berakhir.</li>
                    <li>Pengakhiran/pembatalan Layanan Urun Dana ini tidak menghapuskan kewajiban-kewajiban
masing-masing Pihak yang telah atau akan timbul dan belum dilaksanakan pada saat
berakhirnya Layanan Urun Dana ini.</li>
                    <li>Dalam hal pengakhiran/pembatalan Layanan Urun Dana ini, Para Pihak sepakat untuk
mengesampingkan keberlakuan ketentuan Pasal 1266 Kitab Undang-Undang Hukum
Perdata, sepanjang ketentuan tersebut mensyaratkan adanya suatu putusan atau penetapan
pengadilan untuk menghentikan atau mengakhiri suatu perjanjian, sehingga
pengakhiran/pembatalan Layanan Urun Dana ini cukup dilakukan dengan pemberitahuan
tertulis dari salah satu Pihak.</li>
                </ol>
            </p>
            <h6>INFORMASI RAHASIA</h6>
            <p>
                <ol>
                    <li>Salah satu Pihak (selanjutnya disebut “ Pihak Pemberi ”) dapat memberikan Informasi Rahasia
kepada Pihak lainnya (selanjutnya disebut “Pihak Penerima”) dalam melaksanakan Layanan
Urun Dana ini. Para Pihak sepakat bahwa pemberian, penerimaan dan penggunaan Informasi
Rahasia tersebut dilakukan sesuai dengan syarat dan ketentuan ini.</li>
                    <li>Informasi Rahasia yang dimaksud dalam ketentuan ini berarti informasi yang bersifat non-
publik, termasuk namun tidak terbatas pada skema atau gambar produk, penjelasan material,

spesifikasi, laporan keuangan dan informasi mengenai klien, kebijaksanaan dan praktek bisnis
Pihak Pemberi dan informasi mana dapat dimuat dalam media cetak, tulis, disk / tape /
compact disk komputer atau media lainnya yang sesuai.</li>
                    <li>
                    Tidak termasuk sebagai Informasi Rahasia adalah materi atau informasi yang mana dapat
dibuktikan oleh Pihak Penerima bahwa:
                    <p>
                        <ol type="a">
                            <li>Pada saat penerimaannya sebagai milik publik ( public domain ) atau menjadi milik publik
( public domain ) atau menjadi milik publik ( public domain ) tanpa adanya pelanggaran
oleh Pihak Penerima;</li>
                            <li>Telah diketahui oleh Pihak Penerima pada saat diberikan oleh Pihak Pemberi;</li>
                            <li>Telah didapatkan dari pihak ketiga tanpa adanya pelanggaran terhadap pengungkapan
Informasi Rahasia;</li>
                            <li>Dikembangkan sendiri oleh Pihak Penerima.</li>
                        </ol>
                    </p>
                    </li>
                    <li>Pihak Penerima dengan ini menyatakan bahwa tidak akan mengungkapkan Informasi Rahasia
apapun yang diberikan Pihak Pemberi ke pihak lainnya selain daripada yang diperlukan dalam
melaksanakan tugas, peran dan kewajibannya dalam Layanan Urun Dana ini, tanpa terlebih
dahulu memperoleh persetujuan dari Pihak Pemberi dan Pihak Penerima akan melakukan
semua tindakan-tindakan pencegahan yang wajar untuk mencegah terjadinya pelanggaran
atau kelalaian dalam pengungkapan, penggunaan, pembuatan salinan ( copy ) atau
pengalihan Informasi Rahasia tersebut.</li>
                    <li>Masing-masing Pihak berkewajiban untuk menyimpan segala rahasia data atau sistem yang
diketahuinya baik secara langsung maupun tidak langsung sehubungan Layanan Urun Dana
yang dilaksanakan sesuai dengan syarat dan ketentuan ini dan bertanggung jawab atas segala

kerugian yang diakibatkan karena pembocoran Informasi Rahasia tersebut, baik oleh masing-
masing Pihak maupun karyawannya maupun perwakilannya.</li>
                </ol>
            </p>
            <h6>PERNYATAAN DAN JAMINAN</h6>
            <p>
                <ol>
                    <li>
                    Tanpa mengesampingkan pernyataan dan jaminan yang diberikan oleh salah satu Pihak ke
Pihak lainnya dalam Layanan Urun Dana ini, masing-masing Pihak dengan ini menyatakan dan
menjamin Pihak lainnya dalam Layanan Urun Dana hal-hal sebagai berikut:
                        <ol type="a">
                            <li>Bahwa berdasarkan hukum negara Republik Indonesia, masing-masing Pihak cakap
menurut hukum untuk memiliki harta kekayaan dan melakukan perbuatan hukum
usahanya di wilayah Republik Indonesia serta memiliki segala perizinan yang diperlukan
untuk menjalankan perbuatan hukum.;</li>
                            <li>Bahwa masing-masing Pihak telah mengambil segala tindakan yang diperlukan untuk
memastikan wakil dari masing-masing Pihak dalam Layanan Urun Dana telah memiliki
kuasa dan wewenang penuh untuk mengikatkan diri dalam persetujuan Syarat dan
Ketentuan ini;</li>
                            <li>Bahwa masing-masing Pihak telah memastikan bahwa Layanan Urun Dana ini tidak
melanggar ketentuan dari anggaran dasar masing-masing Pihak dalam Layanan Urun

Dana dan tidak bertentangan dengan perjanjian apapun yang dibuat oleh masing-
masing Pihak dengan pihak ketiga;</li>
                            <li>Pelaksanaan ketentuan-ketentuan dalam Layanan Urun Dana ini dilaksanakan secara
profesional dengan penuh tanggung jawab dan atas dasar hubungan yang saling
menguntungkan.</li>
                            <li>Persetujuan syarat dan ketentuanini tidak bertentangan atau melanggar atau
berbenturan dengan kaidah-kaidah hukum dan peraturan perundangundangan serta
kebijakan-kebijakan pemerintah Republik Indonesia atau pihak yang berwenang lainnya;</li>
                            <li>Bersedia untuk menerapkan, mendukung dan mematuhi ketentuan hokum dan
peraturan perundang-undangan yang berlaku di Republik Indonesia termasuk namun
tidak terbatas pada peraturan mengenai tindak pidana korupsi, anti pencucian uang dan
anti penyuapan;</li>
                            <li>Masing-masing Pihak akan melaksanakan Layanan Urun Dana ini dengan itikad baik dan
secara jujur. Tidak satupun ketentuan dalam Layanan Urun Dana ini akan digunakan oleh
salah satu Pihak untuk mengambil keuntungan secara tidak wajar dan mengakibatkan
kerugian bagi Pihak lainnya dan tidak satupun ketentuan dalam Layanan Urun Dana ini
dimaksudkan untuk memberikan keuntungan secara tidak wajar kepada Pihak lainnya.</li>
                        </ol>
                    </li>
                    <li>
                    Tanpa mengesampingkan pernyataan dan jaminan yang diberikan oleh Penerbit ke
Penyelenggara, Investor dengan ini menyatakan dan menjamin kepada Penyelenggara hal-hal
sebagai berikut:
                    <ol type="a">
                        <li>Bahwa Investor akan membebaskan Penyelenggara dari klaim, tuntutan, gugatan dari
pihak ketiga atas kelalaian Investor dan/atau karyawan Investor dalam melaksanakan
Layanan Urun Dana ini;</li>
                        <li>Bahwa Investor menyatakan tidak berkeberatan dalam hal Otoritas Jasa Keuangan
dan/atau pihak lain yang sesuai undang-undang berwenang untuk melakukan
pemeriksaan, akan melakukan pemeriksaan terhadap pelaksanaan Layanan Urun Dana
ini;</li>
                        <li>Bahwa Investor bersedia untuk kemungkinan pengubahan, pembuatan atau pengambil
alih kegiatan yang dilaksanakan oleh Investor atau penghentian Layanan Urun Dana.</li>
                    </ol>
                    </li>
                </ol>
            </p>
            <h6>KEADAAN MEMAKSA</h6>
            <p>
                <ol>
                    <li>Keadaan Memaksa atau Force Majeure adalah kejadian-kejadian yang terjadi diluar
kemampuan dan kekuasaan Para Pihak sehingga menghalangi Para Pihak untuk melaksanakan
Layanan Urun Dana ini, termasuk namun tidak terbatas pada adanya kebakaran, banjir,
gempa bumi,likuifaksi, badai, huruhara, peperangan, epidemi, pertempuran, pemogokan,
sabotase, embargo, peledakan yang mengakibatkan kerusakan sistem teknologi informasi
yang menghambat pelaksanaan Layanan Urun Dana ini, serta kebijaksanaan pemerintah
Republik Indonesia yang secara langsung berpengaruh terhadap pelaksanaan Layanan Urun
Dana ini.</li>
                    <li>Masing-masing Pihak dibebaskan untuk membayar denda apabila terlambat dalam
melaksanakan kewajibannya dalam Layanan Urun Dana ini, karena adanya hal-hal Keadaan
Memaksa.</li>
                    <li>Keadaan Memaksa sebagaimana dimaksud harus diberitahukan oleh Pihak yang mengalami
Keadaan Memaksa kepada Pihak lainnya dalam Layanan Urun Dana ini paling lambat 7 (tujuh)
Hari Kalender dengan melampirkan pernyataan atau keterangan tertulis dari pemerintah

untuk dipertimbangkan oleh Pihak lainnya beserta rencana pemenuhan kewajiban yang
tertunda akibat terjadinya Keadaan Memaksa.</li>
                    <li>Keadaan Memaksa yang menyebabkan keterlambatan pelaksanaan Layanan Urun Dana ini
baik untuk seluruhnya maupun sebagian bukan merupakan alasan untuk pembatalan Layanan
Urun Dana ini sampai dengan diatasinya Keadaan Memaksa tersebut.</li>
                </ol>
            </p>
            <h6>PENGALIHAN LAYANAN URUN DANA</h6>
            <p>
                <ol>
                    <li>Investor setuju dan sepakat untuk tidak mengalihkan sebagian atau keseluruhan hak dan
kewajiban Penerbit dalam Layanan Urun Dana ini kepada pihak lainnya atau pihak manapun.</li>
                    <li>Dalam hal adanya permintaan peralihan atas hak kepemilikan Unit Penyertaan Dana dikarenakan Investor
meninggal dunia, maka ahli waris mengajukan permohonan perubahan kepemilikan Unit Penyertaan Dana
kepada Penyelenggara dengan melengkapi dokumen sebagai sebagai berikut :
                    <ol type="a">
                        <li>Surat permohonan peralihan kepemilikan Unit Penyertaan Dana dikarenakan Investor meninggal dunia;</li>
                        <li>Softcopy surat kematian dari instansi berwenang;</li>
                        <li>Softcopy surat keterangan ahli waris dari instansi berwenang dan/atau surat penetapan
pengadilan tentang ahli waris;</li>
                        <li>Softcopy E-KTP Investor (almarhum/almarhumah) dan ahli waris</li>
                        <li>Softcopy Kartu Keluarga (KK) Investor (almarhum/almarhumah)</li>
                        <li>Surat Penunjukan dan/atau Surat Kuasa dari ahli waris (apabila ahli warislebih dari satu)
untuk menunjuk dan/atau menguasakan peralihan kepemilikan Unit Penyertaan Dana kepada salah
satu ahli waris.</li>
                    </ol>
                    </li>
                </ol>
            </p>
            <h6>DOMISILI HUKUM DAN PENYELESAIAN SENGKETA</h6>
            <p>
                <ol>
                    <li>Layanan Urun Dana ini dibuat, ditafsirkan dan dilaksanakan berdasarkan hukum negara
Republik Indonesia.</li>
                    <li>Setiap perselisihan yang timbul sehubungan dengan Layanan Urun Dana ini, akan diupayakan
untuk diselesaikan terlebih dahulu oleh Para Pihak dengan melaksanakan musyawarah untuk
mufakat.</li>
                    <li>Apabila penyelesaian perselisihan secara musyawarah tidak berhasil mencapai mufakat
sampai dengan 30 (tiga puluh) Hari Kalender sejak dimulainya musyawarah tersebut, maka
Para Pihak sepakat untuk menyelesaikan perselisihan tersebut melalui proses pengadilan.</li>
                    <li>Para Pihak sepakat untuk menyelesaikan perselisihan di Pengadilan Negeri Kendal di Provinsi
Jawa Tengah tanpa mengurangi hak darisalah satu untuk mengajukan gugatan pada domisili
pengadilan lainnya ( nonexlusive jurisdiction ).</li>
                    <li>Tanpa mengesampingkan penyelesaian sengketa atau perselisihan melalui pengadilan negeri,
Para Pihak setuju dan sepakat apabila penyelesaian sengketa atau perselisihan di badan
arbitrase dan badan alternatif penyelesaian sengketa yang ditunjuk.</li>
                    <li>Hasil putusan pengadilan negeri maupun badan arbitrase dan badan alternatif penyelesaian
sengketa yang ditunjuk oleh Otoritas Jasa Keuangan maupun regulator berwenang lainnya
bersifat final dan mempunyai kekuatan hokum tetap dan mengikat bagi Para Pihak.</li>
                </ol>
            </p>
            <h6>KELALAIAN / WANPRESTASI</h6>
            <p>
                <ol>
                    <li>Dalam hal terjadi salah satu hal atau peristiwa yang ditetapkan di bawah ini, maka merupakan
suatu kejadian kelalaian (wanprestasi) terhadap Layanan Urun Dana ini:
                    <ol type="a">
                    <li>Kelalaian dalam Layanan Urun Dana
Dalam hal salah satu Pihak terbukti sama sekali tidak melaksanakan kewajiban, atau
melaksanakan kewajiban tetapi tidak sebagaimana disepakati, atau melaksanakan
kewajiban tetapi tidak sesuai dengan waktu yang disepakati, atau melakukan sesuatu
yang tidak diperbolehkan dalam Syarat dan Ketentuan.</li>
                    <li>Pernyataan Tidak Benar
Dalam hal ternyata bahwa sesuatu pernyataan atau jaminan yang diberikan oleh salah
satu Pihak kepada Pihak lainnya dalam Layanan Urun Dana ini terbukti tidak benar atau
tidak sesuai dengan kenyataannya dan menimbulkan kerugian langsung yang diderita
salah satu Pihak.</li>
                    <li>Kepailitan
Dalam hal salah satu Pihak dalam Layanan Urun Dana ini oleh instansi yang berwenang

dinyatakan berada dalam keadaan pailit atau diberikan penundaan membayar hutang-
hutang ( surseance van betaling ).</li>
                    <li>Permohonan Kepailitan
Dalam hal salah satu Pihak dalam Layanan Urun Dana ini mengajukan permohonan
kepada instansi yang berwenang untuk dinyatakan pailit atau untuk diberikan penundaan
membayar hutang-hutang ( surseance van betaling ) atau dalam hal pihak lain
mengajukan permohonan kepada instansi yang berwenang agar salah satu Pihak dalam
Layanan Urun Dana ini dinyatakan dalam keadaan pailit.</li>
                    </ol>    
                    </li>
                    <li>Dalam hal suatu kejadian kelalaian terjadi dan berlangsung, maka Pihak yang tidak lalai berhak
menyampaikan peringatan sebanyak 3 (tiga) kali dengan tenggang waktu 7 (tujuh) Hari
Kalender diantara masing-masing peringatan.</li>
                    <li>Setelah menyampaikan 3 (tiga) kali peringatan, Pihak yang tidak lalai berhak mengajukan
tuntutan berupa meminta pemenuhan prestasi dilakukan atau meminta prestasi dilakukan
disertai ganti kerugian atau meminta ganti kerugian saja atau menuntut pembatalan Layanan
Urun Dana disertai ganti kerugian.</li>
                </ol>
            </p>
            <h6>PENALTI</h6>
            <p>
            Apabila dalam Layanan Urun Dana ini, Investor melanggar ketentuan dalam Layanan Urun Dana
ini maka Penyelenggara berhak menon-aktifkan atau membekukan akun Investor, bahkan
pengakhiran Layanan Urun Dana Investor oleh Penyelenggara dalam Layanan Urun Dana ini.
            </p>
            <h6>MEKANISME DALAM HAL PENYELENGGARA TIDAK DAPAT MENJALANKAN OPERASIONALNYA</h6>
            <p>
            Mekanisme penyelesaian Layanan Urun Dana dalam hal Penyelenggara tidak dapat menjalankan
operasional adalah sebagai berikut :
                <ol>
                    <li>Penyelenggara melakukan pemberitahuan atau pengumuman secara tertulis di website
Penyelenggara dan media sosial lainnya kepada seluruh Pengguna atau khalayak umum
bahwa Penyelenggara tidak dapat memberitahukan operasionalnya dengan mencantumkan
alasan jelas;</li>
                    <li>Bahwa pengaturan tata cara Buyback mengacu pada akta anggaran dasar Penerbit dan
undang-undang dasar tentang perseroan terbatas yang berlaku di Negara Republik Indonesia;</li>
                    <li>Buyback seluruh Unit Penyertaan Dana yang dimiliki Investor dengan harga pasar atau disepakati secara
tertulis oleh Para Pihak di kemudian hari.</li>
                    <li>Menunjuk penyelenggara lain yang telah mendapat izin dari Otoritas Jasa Keuangan seperti
Penyelenggara, dengan syarat dan ketentuan yang sudah disepakati bersama dengan
Investor.</li>
                </ol>
            </p>
            <h6>DISCLAIMER</h6>
            <p>
                <ol>
                    <li>Penyelenggara bertindak sebagi penyelenggara Layanan Urun Dana, bukan sebagai pihak
yang menjalankan bisnis (Penerbit).</li>
                    <li>Semua data dan informasi yang tersaji di website, aplikasi dan prospectus diperoleh
Penyelenggara dari Penerbit dan media online.</li>
                    <li>Keputusan pembelian Unit Penyertaan Dana, sepenuhnya merupakan hak dan pilihan Investor, sehingga
segala risiko dan konsekuensi atas pembelian Unit Penyertaan Dana merupakan tanggung jawab dan risiko
Investor sepenuhnya.</li>
                    <li>Dengan membeli Unit Penyertaan Dana di Penyelenggara, berarti Investor dianggap telah membaca,
memahami dan menyetujui seluruh syarat dan ketentuan serta memahami risiko investasi
termasuk risiko kehilangan atau seluruh modal.</li>
                </ol>
            </p>
            <h6>LAIN - LAIN</h6>
            <p>
                <ol>
                    <li>Para Pihak wajib tunduk dan patuh terhadap peraturan perundang-undangan yang berlaku
di negara Republik Indonesia terkait pelaksanaan Layanan Urun Dana ini.</li>
                    <li>Layanan Urun Dana ini diinterpretasikan dan dilaksanakan berdasarkan hokum yang berlaku
di Negara Republik Indonesia.</li>
                    <li>Keabsahan, penafsiran dan pelaksanaan Layanan Urun Dana ini, diatur serta tunduk pada
hukum dan peraturan perundang-undangan yang berlaku di negara Republik Indonesia;</li>
                    <li>Dalam hal ada salah satu atau lebih ketentuan dalam syarat dan ketentuanini dinyatakan
tidak sah, tidak berlaku atau tidak dapat dilaksanakan berdasarkan hukum dan/atau
peraturan perundang-undangan yang berlaku di Republik Indonesia, maka kedua belah pihak
setuju bahwa keabsahan ketentuan lainnya dalam Syarat dan Ketentuan ini tetap berlaku dan
dapat dilaksanakan serta tidak akan terpengaruh;</li>
                    <li>Penyelenggara berhak untuk mengubah syarat dan ketentuanini sewaktuwaktu dalam
rangka penyempurnaan dan penyesuaian dengan ketentuan hukum yang berlaku di Negara
Republik Indonesia</li>
                    <li>Dokumen-dokumen atau kesepakatan-kesepakatan terkait dengan Layanan Urun Dana ini
yang telah dibuat oleh Para Pihak sebelum disetujuinya syarat dan ketentuan ini dinyatakan
dicabut dan tidak berlaku terhitung sejak disetujuinya syarat dan ketentuan.</li>
                </ol>
            </p>
            <p>
            SYARAT DAN KETENTUAN INI DIBUAT DAN DIBERIKAN PERSETUJUAN SECARA ELEKTRONIK OLEH
INVESTOR DALAM KEADAAN SEHAT DAN SADAR SERTA TANPA ADA PAKSAAN DARI PIHAK
MANAPUN JUGA.
            </p>
            <p>
            SETELAH INVESTOR MEMBUBUHKAN TANDA CENTANG (√) PADA KOTAK PERSETUJUAN SECARA
ELEKTRONIK ATAS SYARAT DAN KETENTUAN INI, MAKA INVESTOR DENGAN INI MENYATAKAN
SETUJU TELAH MEMBACA, MENGERTI, MEMAHAMI SECARA SEKSAMA DAN TUNDUK PADA
SETIAP DAN KESELURUHAN SYARAT DAN KETENTUAN, SERTA TUNDUK PADA PERATURAN
KOPERASI GEMA BESERTA PERUBAHAN-PERUBAHANNYA.
            </p>
            <p>
            DAN OLEH KARENA ITU, DALAM PELAKSANAAN LAYANAN URUN DANA, INVESTOR MENYATAKAN
DAN MENJAMIN BAHWA INVESTOR SELALU TETAP MEMATUHI DAN MELAKSANAKAN SETIAP
KETENTUAN YANG ADA DALAM SYARAT DAN KETENTUAN INI DENGAN PENUH TANGGUNG
JAWAB DAN PROFESIONAL.
            </p>
            <h3 className="my-4">Syarat dan Ketentuan Investee</h3>
            <h6>Definisi</h6>
            <p>
                <ol>
                    <li>Layanan Urun Dana adalah suatu program layanan urun dana yang diselenggarakan oleh
Penyelenggara dengan melakukan penawaran Unit Penyertaan Dana milik Penerbit kepada Investor atau
masyarakat umum melalui jaringan system elektronik layanan urun dana melalui penawaran
Unit Penyertaan Dana berbasis teknologi informasi (equity crowdfunding) milik Penyelenggara yang bersifat
terbuka.</li>
                    <li>Penyelenggara adalah Koperasi GEMA, merupakan pihak yang menyediakan, mengelola, dan
mengoperasikan Layanan Urun Dana.</li>
                    <li>Pengguna adalah Investee dan Investor.</li>
                    <li>Investee atau penerbit Unit Penyertaan Dana atau penerima modal merupakan badan organisasi berbentuk
unit Usaha Mikro, Kecil dan Menengah (UMKM), merupakan pihak yang melaksanakan
Layanan Urun Dana menawarkan Unit Penyertaan Dana berbasis teknologi melalui Penyelenggara.</li>
                    <li>Investor adalah pihak yang melakukan pembelian Unit Penyertaan Dana Penerbit melalui Penyelenggara.</li>
                    <li>Unit Penyertaan Dana adalah nilai Unit Penyertaan Dana milik Penerbit selama Layanan Urun Dana berlangsung.</li>
                    <li>Buyback adalah proses dimana Penerbit melakukan pembelian kembali Unit Penyertaan Dana yang telah
dijual oleh Penerbit kepada Investor.</li>
                    <li>Hari Kalender adalah hari Senin sampai dengan hari Minggu, termasuk hari libur Nasional yang
ditetapkan Pemerintah Indonesia sebagaimana perhitungan tahun kalender Masehi.</li>
                    <li>Hari Kerja adalah hari kerja dimana Penyelenggara beroperasi.</li>
                </ol>
            </p>
            <h6>PELAKSANAAN LAYANAN URUN DANA</h6>
            <p>
                <ol>
                    <li>Penerbit dengan ini mengajukan pendaftaran kepada Penyelenggara dan Penyelenggara
menerima pendaftaran dari Penerbit sebagai Anggota untuk melaksanakan Layanan Urun
Dana dengan maksud dan tujuan, lingkup Layanan Urun Dana, syarat dan ketentuan, serta
batas tanggung jawab sesuai dengan ketentuan Koperasi GEMA.</li>
                    <li>Penerbit hanya dapat menawarkan Unit Penyertaan Dana melalui 1 (satu) Penyelenggara dalam waktu yang
bersamaan (exclusive).</li>
                    <li>Batas maksimum penghimpunan dana melalui Layanan Urun Dana oleh Penerbit dalam
jangka waktu 12 (dua belas) bulan paling banyak sebesar Rp 100.000.000.000,00 (seratus
miliar Rupiah)</li>
                    <li>Penawaran Unit Penyertaan Dana oleh Penerbit dapat dilakukan dalam 1 (satu) kali penawaran atau lebih.</li>
                </ol>
            </p>
            <h6>MASA PENAWARAN Unit Penyertaan Dana</h6>
            <p>
                <ol>
                    <li>Masa penawaran Unit Penyertaan Dana oleh Penerbit dilakukan paling lama 60 (enam puluh) Hari Kalender.</li>
                    <li>Penerbit dapat membatalkan penawaran Unit Penyertaan Dana melalui Layanan Urun Dana sebelum
berakhirnya masa penawaran Unit Penyertaan Dana dengan membayar sejumlah denda kepada Investor
dan Penyelenggara.</li>
                </ol>
            </p>
            <h6>PEMBELIAN Unit Penyertaan Dana</h6>
            <p>Penerbit mengetahui dan memahami bahwa pembelian Unit Penyertaan Dana oleh Investor dalam penawaran
Unit Penyertaan Dana melalui Layanan Urun Dana dilakukan dengan menyetorkan sejumlah dana pada escrow
account Penyelenggara atas nama Koperasi GEMA.</p>
            <h6>PENYERAHAN DANA DAN Unit Penyertaan Dana</h6>
            <p>
                <ol>
                    <li>Penyelenggara wajib menyerahkan dana dari Investor kepada Penerbit paling lambat 21 (dua
puluh satu) Hari Kerja setelah berakhirnya masa penawaran Unit Penyertaan Dana.</li>
                    <li>Manfaat bersih dari penempatan dana dikembalikan kepada Investor secara proporsional.</li>
                    <li>Dalam Layanan Urun Dana ini Penyelenggara berhak mendapat fee dari Penerbit dari dana
terkumpul yang akan disepakati tertulis dalam Perjanjian Layanan Urun Dana.</li>
                    <li>Setelah Penerbit resmi terdaftar sebagai Anggota, maka Penerbit setuju untuk memberikan
annual fee kepada Penyelenggara sebagai biaya pemasaran dan maintenance yang dibayar
setiap pembagian Imbal Hasil tahunan (final). Besar annual fee Penerbit yang diberikan kepada
Penyelenggara tersebut akan disepakati secara tertulis dalam Perjanjian Layanan Urun Dana
dan dilakukan review setiap tahunnya sesuai dengan kebijakan Penyelenggara.</li>
                    <li>Berakhirnya masa penawaran Unit Penyertaan Dana adalah:
                        <ol type="a">
                            <li>Tanggal tertentu yang telah ditetapkan dan disepakati oleh Para Pihak; atau</li>
                            <li>tanggal tertentu berakhirnya masa penawaran Unit Penyertaan Dana namun seluruh Unit Penyertaan Dana yang
ditawarkan melalui Layanan Urun Dana telah dibeli oleh Investor.</li>
                        </ol>
                    </li>
                    <li>Penerbit wajib menyerahkan Unit Penyertaan Dana kepada Penyelenggara untuk didistribusikan kepada
Investor paling lambat 5 (lima) Hari Kerja setelah Penerbit menerima dana Investor dari
Penyelenggara.</li>
                    <li>Penyelenggara wajib mendistribusikan Unit Penyertaan Dana kepada Investor paling lambat 10 (sepuluh)
Hari Kerja setelah menerima Unit Penyertaan Dana dari Penerbit.</li>
                    <li>Penerbit sepakat untuk pendistribusian Unit Penyertaan Dana kepada Investor dilakukan secara elektronik
oleh Penyelenggara</li>
                </ol>
            </p>
            <h6>LAPORAN PENERBIT</h6>
            <p>
                <ol>
                    <li>Penerbit wajib menyampaikan laporan tahunan kepada mengumumkan kepada masyarakat
melalui situs web Penyelenggara dan/atau situs website Penerbit paling lambat 6 (enam) bulan
setelah tahun buku Penerbit berakhir.</li>
                    <li>Laporan tahunan Penerbit wajib memuat informasi tentang realisasi penggunaan dana hasil
penawaran Unit Penyertaan Dana melalui Layanan Urun Dana.</li>
                    <li>Informasi mengenai realisasi penggunaan dana wajib disampaikan dan diumumkan hingga
dana hasil penawaran Unit Penyertaan Dana melalui Layanan Urun Dana telah habis digunakan.</li>
                </ol>
            </p>
            <h6>DAFTAR PEMEGANG Unit Penyertaan Dana</h6>
            <p>
                <ol>
                    <li>
                    Unit Penyertaan Dana dititipkan dalam penitipan kolektif di KSEI karenanya Penerbit wajib mencatatkan
kepemilikan Unit Penyertaan Dana Investor dalam penitipan kolektif dan Penerbit memberikan kuasa kepada
Penyelenggara untuk melakukan administrasi efek sesuai dengan ketentuan dan prosedur

yang berlaku yang ditetapkan oleh KSEI dan ketentuan undang-undang tentang perseroan
terbatas yang berlaku di Negara Republik Indonesia.
                    </li>
                </ol>
            </p>
            <h6>PENGHIMPUNAN DANA DAN PENETAPAN DANA MINIMUM</h6>
            <p>
                <ol>
                    <li>Penerbit wajib menetapkan jumlah minimum dana yang harus diperoleh dalam penawaran
Unit Penyertaan Dana melalui Layanan Urun Dana berdasarkan kesepakatan tertulis yang dituangkan dalam
Perjanjian Layanan Urun Dana.</li>
                    <li>Dalam hal Penerbit menetapkan jumlah minimum dana, maka Penerbit wajib mengungkapkan:
                        <ol type="a">
                            <li>rencana penggunaan dana sehubungan dengan perolehan dana minimum; atau</li>
                            <li>sumber dana lain untuk melaksanakan rencana penggunaan dana.</li>
                        </ol>
                    </li>
                    <li>Penerbit dilarang mengubah jumlah minimum dalam masa penawaran Unit Penyertaan Dana.</li>
                    <li>Jika jumlah minimum dana tidak terpenuhi, maka penawaran Unit Penyertaan Dana melalui Layanan Urun
Dana tersebut dinyatakan batal demi hukum.</li>
                    <li>Dalam hal penawaran Unit Penyertaan Dana batal demi hukum, maka Penyelenggara wajib mengembalikan
dana beserta seluruh manfaat yang timbul dari dana tersebut selama dalam escrow account
secara proporsional kepada Investor paling lambat 2 (dua) Hari Kerja setelah penawaran
Unit Penyertaan Dana dinyatakan batal demi hukum.</li>
                    <li>Ketentuan pembagian Imbal Hasil Penerbit diinformasikan di dalam kebijakan Imbal Hasil dan
didasarkan pada laba bersih Penerbit setelah dikurangi dengan pencadangan. Mekanisme
pembagian Imbal Hasil lainnya (termasuk pembagian Imbal Hasil interim) mengacu pada anggaran
dasar Penerbit dan peraturan perundang-undangan yang berlaku.</li>
                    <li>Pembagian Imbal Hasil final Penerbit mengacu pada persetujuan Rapat Umum Pemegang Unit Penyertaan Dana
(“RUPS”) Penerbit.</li>
                    <li>Pembagian Imbal Hasil kepada para pemegang Unit Penyertaan Dana tidak bersifat lifetime sehingga Penerbit
berhak melakukan Buyback sebagaimana diatur dalam akta anggaran dasar Penerbit dan
peraturan perundang-undangan yang berlaku</li>
                    <li>Apabila terdapat beban operasional usaha yang harus dikeluarkan setiap periode tertentu,
Penerbit tidak memiliki hak untuk membebankannya kepada Investor, melainkan beban
tersebut dimasukkan ke dalam penghitungan biaya operasional yang kemudian dilaporkan
dalam laporan keuangan periode tersebut.</li>
                    <li>Apabila dikemudian hari Penerbit ingin mengakhiri kerjasama Layanan Urun Dana (opsi
Buyback sesuai dengan ketentuan undang-undang perseroan terbatas), Penerbit berkewajiban
mengajukan permohonan kepada Penyelenggara, selambat-lambatnya 60 (enam puluh) Hari
Kalender.</li>
                    <li>Dalam hal Penerbit mengakhiri kerjasama sebagaimana dimaksud dalam ketentuan nomor 10
di atas ini, maka Penerbit wajib untuk mengajukan surat permohonan pembatalan
pendaftaran</li>
                </ol>
            </p>
            <h6>KEWAJIBAN PENERBIT</h6>
            <p>Tanpa mengesampingkan hak dan kewajiban lainnya sebagaimana telah tersebut dalam Perjanjian
ini, maka kewajiban Penerbit adalah sebagai berikut:
            <ol>
                <li>Penerbit wajib membuat materi video pada saat sebelum dan sesudah dilakukannya Layanan
Urun Dana dengan konten (materi) yang akan ditentukan oleh Penyelenggara.</li>
                <li>Penerbit wajib kooperatif dan berpartisipasi aktif membantu proses penjualan Unit Penyertaan Dana.</li>
                <li>Penerbit agar turut melakukan broadcast dan penyebaran baik melalui media-media sosial yang
dimiliki ataupun jalur pribadi untuk men-support penjualan Unit Penyertaan Dana.</li>
                <li>Penerbit wajib memberikan laporan keuangan setiap bulan maksimal tanggal 10 bulan
berikutnya, minimal mencakup laporan laba rugi bulanan.</li>
                <li>Penerbit wajib memberikan laporan keuangan tiap per 6 (enam) bulan maksimal tanggal 15
bulan berikutnya, minimal mencakup laporan :
                    <ol type="a">
                        <li>Laporan Rugi Laba</li>
                        <li>Neraca / Balance Sheet</li>
                    </ol>
                </li>
                <li>Pembagian Imbal Hasil wajib dilakukan oleh Penerbit kepada Investor dengan jumlah bagian
persentase dari laba bersih Penerbit untuk Imbal Hasil sesuai porsi Investor.</li>
                <li>Penerbit wajib membayar fee dan annual fee kepada Penyelenggara.</li>
                <li>Penerbit wajib menentukan periode waktu untuk Imbal Hasil untuk Investor sebagaimana diatur
dalam akta anggaran dasar Penerbit.</li>
                <li>Pembagian Imbal Hasil oleh Penerbit ditetapkan oleh RUPS Penerbit berdasarkan laporan keuangan
Penerbit, dan pemegang Unit Penyertaan Dana Penerbit berhak menolak dan menerima laporan keuangan
Penerbit.</li>
                <li>Penerbit wajib memberikan izin kepada Penyelenggara untuk melakukan audit ulang laporan
keuangan Penerbit oleh tim independen, sebagai bagian dari due diligence.</li>
                <li>Penerbit wajib menjaga nama baik dan reputasi Penyelenggara dengan tidak melakukan
promosi-promosi yang mengandung unsur suku, agama dan ras, atau tidak melakukan
penyebaran informasi yang tidak benar dengan mengatasnamakan Penyelenggara.</li>
                <li>Penerbit wajib memberikan informasi terkait update perkembangan bisnis setiap 1 (satu) bulan
sekali, kepada Penyelenggara, untuk disampaikan sebagai news update kepada Investor.</li>
                <li>Penerbit wajib melaporkan penggunaan dana dari hasil Layanan Urun Dana.</li>
                <li>Penerbit wajib tunduk dan patuh pada syarat dan ketentuan yang tercantum dalam website
Penyelenggara serta wajib tunduk dan patuh pada Perjanjian Layanan Urun Dana.</li>
                <li>Penerbit wajib setuju dan sepakat bersedia untuk memberikan akses audit internal maupun
audit eksternal yang ditunjuk Penyelenggara atau regulator berwenang lainnya setiap kali
dibutuhkan terkait pelaksanaan Layanan Urun Dana ini.</li>
                <li>Penerbit wajib menyampaikan laporan tahunan kepada Otoritas Jasa Keuangan dan
mengumumkan kepada masyarakat melalui situs web Penyelenggara dan/atau situs website
Penerbit paling lambat 6 (enam) bulan setelah tahun buku Penerbit berakhir.</li>
                <li>Penerbit wajib menyerahkan dokumen dan/atau informasi kepada Penyelenggara paling sedikit
mengenai:
                <ol type="a">
                    <li>Akta pendirian badan hukum Penerbit, berikut perubahan anggaran dasar terakhir, jika
terdapat perubahan anggaran dasar;</li>
                    <li>Jumlah dana yang akan dihimpun dalam penawaran Unit Penyertaan Dana dan tujuan penggunaan dana
hasil penawaran Unit Penyertaan Dana melalui Layanan Urun Dana;</li>
                    <li>Jumlah minimum dana yang harus diperoleh dalam penawaran Unit Penyertaan Dana melalui Layanan
Urun Dana, jika Penerbit menetapkan jumlah minimum dana yang harus diperoleh;</li>
                    <li>Risiko utama yang dihadapi Penerbit dan risiko kemungkinan tidak likuidnya Unit Penyertaan Dana yang
ditawarkan, jika terdapat risiko kemungkinan tidak likuidnya Unit Penyertaan Dana;</li>
                    <li>Rencana bisnis Penerbit ;</li>
                    <li>Perizinan yang berkaitan dengan kegiatan usaha Penerbit dan/atau proyek yang akan
didanai dengan dana hasil penawaran Unit Penyertaan Dana melalui Layanan Urun Dana;</li>
                    <li>Laporan keuangan sederhana;</li>
                    <li>Mekanisme penetapan harga Unit Penyertaan Dana;</li>
                    <li>Surat keterangan domisili Penerbit;</li>
                    <li>Penerbit wajib memberikan sedikitnya 50 (lima puluh) referensi calon investor dari
kalangan Investor, keluarga, dan teman dekat kepada Penyelenggara.</li>
                </ol>
                </li>
            </ol>
            </p>
            <h6>HAK PENERBIT</h6>
            <p>Tanpa mengesampingkan hak dan kewajiban lainnya sebagaimana telah tersebut dalam syarat dan
ketentuan ini, maka hak Penerbit adalah sebagai berikut:
                <ol>
                    <li>Penerbit berhak mendapatkan support teknologi untuk proses pembukaan Layanan Urun Dana
dan diperdagangkan.</li>
                    <li>Penerbit berhak atas teknologi untuk pembagian Imbal Hasil yang peredarannya transparan dan
termonitor (dengan memanfaatkan teknologi blockchain).</li>
                    <li>Penerbit berhak mendapatkan dukungan marketing, sesuai dengan data yang diberikan oleh
Penerbit.</li>
                    <li>Penerbit berhak mendapatkan bantuan komunikasi penyebaran konten lewat channel resmi dan
jaringan audience yang dimiliki Para Pihak.</li>
                    <li>Penerbit berhak mendapat bantuan dalam transaksi penjualan Unit Penyertaan Dana, dan menyalurkan hasil
penjualan kepada Penerbit.</li>
                </ol>
            </p>
            <h6>KEWAJIBAN PENYELENGGARA</h6>
            <p>
            Tanpa mengesampingkan hak dan kewajiban lainnya sebagaimana telah tersebut dalam syarat dan
ketentuan ini, maka kewajiban Penyelenggara adalah sebagai berikut:
                <ol>
                    <li>Penyelenggara wajib memenuhi seluruh hak-hak Penerbit.</li>
                    <li>Penyelenggara wajib memonitor, menganalisa, dan memastikan bahwa Penerbit berada di jalur
yang sesuai dengan visi misi Penyelenggara dan Layanan Urun Dana.</li>
                    <li>Penyelenggara wajib proaktif dalam berkomunikasi dengan Penerbit dan dalam
mengimplementasikan program-program yang bisa membantu proses scale-up Penerbit.</li>
                </ol>
            </p>
            <h6>HAK PENYELENGGARA</h6>
            <p>
            Tanpa mengesampingkan hak dan kewajiban lainnya sebagaimana telah tersebut dalam syarat dan
ketentuan ini, maka kewajiban Penyelenggara adalah sebagai berikut:
                <ol>
                    <li>Hak Penyelenggara adalah hal-hal yang menjadi kewajiban Penerbit.</li>
                    <li>Penyelenggara berhak mendapatkan akses audit ulang laporan keuangan yang diberikan oleh
Penerbit, sebagai bagian dari perlindungan Investor.</li>
                    <li>Penyelenggara berhak menyetujui atau menolak permintaan tertulis dari Penerbit dalam
penggunaan dana, jika dirasa tidak sesuai dengan tujuan program pemberdayaan Usaha Mikro
Kecil dan Menengah (UMKM) dari Penyelenggara.</li>
                    <li>Penyelenggara berhak meminta kehadiran perwakilan dari Penerbit untuk menemui perwakilan
Penyelenggara secara langsung setiap kali dibutuhkan.</li>
                    <li>Penyelenggara berhak menghentikan seluruh partisipasi Penerbit dari Layanan Urun Dana jika
Penerbit dinilai tidak kooperatif, mencemarkan nama baik Penyelenggara atau diindikasi
melakukan aktifitas yang melanggar hukum.</li>
                </ol>
            </p>
            <h6>LAPORAN KEJADIAN KRITIS</h6>
            <p>
            Penerbit wajib memberikan laporan-laporan sehubungan dengan pelaksanaan syarat dan ketentuan
ini, dengan melaporkan dengan segera kepada Penyelenggara setiap kejadian kritis yang dapat
mengakibatkan kerugian keuangan yang signifikan dan/atau mengganggu kelancaran operasional
sistem Layanan Urun Dana.
            </p>
            <h6>PERPAJAKAN</h6>
            <p>
            Pembebanan pajak yang timbul dalam Layanan Urun Dana ini menjadi beban masing-masing pihak
serta tunduk pada ketentuan hukum perpajakkan yang berlaku di wilayah Negara Republik Indonesia.
            </p>
            <h6>HAK ATAS KEKAYAAN INTELEKTUAL</h6>
            <p>
                <ol>
                    <li>Hak atas kekayaan intelektual yang timbul atas pelaksanaan Layanan Urun Dana dan izin
Penyelenggara, beserta fasilitas-fasilitas lain yang dimiliki Penyelenggara dan digunakan dalam
Layanan Urun Dana ini adalah tetap dan seterusnya milik Penyelenggara dan tidak ada
penyerahan hak dari Penyelenggara kepada Penerbit dalam Layanan Urun Dana ini.</li>
                    <li>Penerbit tidak berhak untuk mengubah, mengembangkan, membagikan dan/atau menjual baik
seluruh maupun sebagian hak atas kekayaan intelektual yang timbul atas pengembangan,
inovasi, perubahan berupa fitur dan/atau fungsi terhadap sistem teknologi informasi.</li>
                    <li>Penyelenggara dengan ini menjamin bahwa hak atas kekayaan intelektual yang terkandung
dalam pelaksanaan Layanan Urun Dana ini tidak melanggar hak atas kekayaan intelektual milik
pihak manapun, dan Penyelenggara membebaskan Penerbit dari segala tuntutan, gugatan dari
pihak manapun, sehubungan dengan pelanggaran terhadap hak atas kekayaan intelektual yang
terkandung dalam Layanan Urun Dana sesuai syarat dan ketentuan ini.</li>
                    <li>Dalam hal terdapat hak atas kekayaan intelektual milik pihak manapun (eksternal) maupun
principal maka Penyelenggara menyatakan dan menjamin bahwa Penyelenggara tidak
menggunakan hak atas kekayaan intelektual tersebut dan tetap menjadi milik pihak manapun
(eksternal) maupun principal.</li>
                </ol>
            </p>
            <h6>KETENTUAN BUYBACK</h6>
            <p>
                <ol>
                    <li>Penerbit setuju dan sepakat untuk menginformasikan kepada Penyelenggara melalui email
Penyelenggara yaitu cs@gemaindonesia.id selambat-lambatnya 60 (enam puluh) Hari
Kalender terhitung sebelum tanggal pengembalian Buyback atau tanggal pengakhiran
Perjanjian Layanan Urun Dana;</li>
                    <li>Bahwa pengaturan tata cara Buyback mengacu pada akta anggaran dasar Penerbit dan
undang-undang dasar perseroan terbatas.</li>
                    <li>Penerbit setuju dan sepakat untuk membeli Unit Penyertaan Dana dengan harga pasar saat Buyback atau
batas harga lainnya yang ditentukan oleh penyelenggara dan membayar Buyback fee senilai

5% dari total buyback kepada Penyelenggara, dengan melalui transfer bank selambat-
lambatnya 15 (lima belas) Hari Kalender terhitung sejak tanggal pengembalian Buyback dan

tanggal pengakhiran Perjanjian Layanan Urun Dana;</li>
                    <li>Penerbit setuju dan sepakat untuk menyelesaikan kewajiban-kewajiban kepada
Penyelenggara dan Investor yang belum terselesaikan;</li>
                    <li>Penerbit setuju dan sepakat untuk memberikan release di dunia maya berupa media sosial,
website dan media sosial Penyelenggara.</li>
                </ol>
            </p>
            <h6>JANGKA WAKTU DAN PENGAKHIRAN</h6>
            <p>
                <ol>
                    <li>Jangka waktu Layanan Urun Dana ini mengacu pada kesepakatan tertulis yang dituangkan dalam
bentuk Perjanjian Layanan Urun Dana antara Penyelenggara dan Penerbit, dan dapat
diperpanjang berdasarkan kesepakatan tertulis dalam bentuk addendum yang ditandatangani
oleh Para Pihak.</li>
                    <li>Layanan Urun Dana ini berakhir dengan sendirinya, dalam hal:
                        <ol type="a">
                            <li>Jangka waktu berakhir sebagaimana diatur dalam Perjanjian Layanan Urun Dana;</li>
                            <li>Penerbit mengajukan Buyback atas semua Unit Penyertaan Dana yang dimiliki Investor;</li>
                            <li>Salah satu Pihak dilikuidasi, kecuali untuk maksud pendirian kembali atau merger;</li>
                            <li>Izin operasional salah satu Pihak dicabut oleh pemerintah Republik Indonesia dan/atau
instansi yang berwenang atau telah berakhir;</li>
                            <li>Salah satu Pihak dinyatakan pailit berdasarkan keputusan pengadilan yang berwenang;</li>
                            <li>Terdapat ketentuan peraturan perundang-undangan dan/atau kebijakan pemerintah
Republik Indonesia yang tidak memungkinkan berlangsungnya Layanan Urun Dana
berdasarkan syarat dan ketentuan ini.</li>
                        </ol>
                    </li>
                    <li>Tanpa mengesampingkan ketentuan sebagaimana diatur ketentuan nomor 1 dan 2 Romawi XVII
ini, Penyelenggara berhak untuk mengubah, membuat perjanjian baru atau menghentikan
Layanan Urun Dana ini sebelum berakhirnya Layanan Urun Dana atas dasar kondisi sebagai
berikut, termasuk namun tidak terbatas pada:
                        <ol type="a">
                            <li>Memburuknya kinerja Penerbit dalam pelaksanaan Layanan Urun Dana ini yang dapat
berdampak signifikan pada kegiatan Layanan Urun Dana;</li>
                            <li>Penerbit menjadi insolven, dalam proses menuju likuidasi, atau dipailitkan oleh pengadilan
yang berwenang;</li>
                            <li>Terdapat pelanggaran oleh Penerbit terhadap ketentuan Layanan Urun Dana ini; dan/atau</li>
                            <li>Terdapat kondisi yang menyebabkan Penerbit tidak dapat menyediakan data yang
diperlukan dalam rangka audit oleh Koperasi GEMA atau pihak berwenang.</li>
                        </ol>
                    </li>
                    <li>Dalam hal Layanan Urun Dana ini berakhir dan/atau dinyatakan berakhir, maka Para Pihak
sepakat bahwa ketentuan Informasi Rahasia sebagaimana diatur dalam Layanan Urun Dana ini
tetap berlaku dan mengikat Para Pihak hingga kapanpun meskipun Layanan Urun Dana telah
berakhir.</li>
                    <li>Pengakhiran/pembatalan Layanan Urun Dana ini tidak menghapuskan kewajibankewajiban
masing-masing Pihak yang telah atau akan timbul dan belum dilaksanakan pada saat berakhirnya
Layanan Urun Dana ini.</li>
                    <li>Dalam hal pengakhiran/pembatalan Layanan Urun Dana ini, Para Pihak sepakat untuk
mengesampingkan keberlakuan ketentuan Pasal 1266 Kitab Undang-Undang Hukum Perdata,
sepanjang ketentuan tersebut mensyaratkan adanya suatu putusan atau penetapan pengadilan
untuk menghentikan atau mengakhiri suatu perjanjian, sehingga pengakhiran/pembatalan
Layanan Urun Dana ini cukup dilakukan dengan pemberitahuan tertulis dari salah satu Pihak.</li>
                </ol>
            </p>
            <h6>INFORMASI RAHASIA</h6>
            <p>
                <ol>
                    <li>Salah satu Pihak (selanjutnya disebut “Pihak Pemberi”) dapat memberikan Informasi Rahasia
kepada Pihak lainnya (selanjutnya disebut “Pihak Penerima”) dalam melaksanakan Layanan Urun
Dana ini. Para Pihak sepakat bahwa pemberian, penerimaan dan penggunaan Informasi Rahasia
tersebut dilakukan sesuai dengan syarat dan ketentuan ini.</li>
                    <li>Informasi Rahasia yang dimaksud dalam ketentuan ini berarti informasi yang bersifat non-publik,
termasuk namun tidak terbatas pada skema atau gambar produk, penjelasan material, spesifikasi,
penjualan dan informasi mengenai klien, kebijaksanaan dan praktek bisnis Pihak Pemberi dan
informasi mana dapat dimuat dalam media cetak, tulis, disk / tape / compact disk komputer atau
media lainnya yang sesuai.</li>
                    <li>Tidak termasuk sebagai Informasi Rahasia adalah materi atau informasi yang mana dapat
dibuktikan oleh Pihak Penerima bahwa:
                        <ol type="a">
                            <li>Pada saat penerimaannya sebagai milik publik (public domain) atau menjadi milik publik
(public domain) atau menjadi milik publik (public domain) tanpa adanya pelanggaran oleh
Pihak Penerima;</li>
                            <li>Telah diketahui oleh Pihak Penerima pada saat diberikan oleh Pihak Pemberi;</li>
                            <li>Telah didapatkan dari pihak ketiga tanpa adanya pelanggaran terhadap pengungkapan
Informasi Rahasia;</li>
                            <li>Dikembangkan sendiri oleh Pihak Penerima.</li>
                        </ol>
                    </li>
                    <li>Pihak Penerima dengan ini menyatakan bahwa tidak akan mengungkapkan Informasi Rahasia
apapun yang diberikan Pihak Pemberi ke pihak lainnya selain daripada yang diperlukan dalam
melaksanakan tugas, peran dan kewajibannya dalam syarat dan ketentuan ini, tanpa terlebih
dahulu memperoleh persetujuan dari Pihak Pemberi dan Pihak Penerima akan melakukan semua
tindakan-tindakan pencegahan yang wajar untuk mencegah terjadinya pelanggaran atau kelalaian
dalam pengungkapan, penggunaan, pembuatan salinan (copy) atau pengalihan Informasi Rahasia
tersebut.</li>
                    <li>Masing-masing Pihak berkewajiban untuk menyimpan segala rahasia data atau sistem yang
diketahuinya baik secara langsung maupun tidak langsung sehubungan Layanan Urun Dana dan
bertanggung jawab atas segala kerugian yang diakibatkan karena pembocoran Informasi Rahasia
tersebut, baik oleh masing-masing Pihak maupun karyawannya maupun perwakilannya.</li>
                </ol>
            </p>
            <h6>PERNYATAAN DAN JAMINAN</h6>
            <p>
                <ol>
                    <li>Tanpa mengesampingkan pernyataan dan jaminan yang diberikan oleh salah satu Pihak ke Pihak
lainnya dalam Layanan Urun Dana ini, masing-masing Pihak dengan ini menyatakan dan menjamin
Pihak lainnya dalam Layanan Urun Dana hal-hal sebagai berikut:
                        <ol type="a">
                            <li>Bahwa masing-masing Pihak adalah suatu perusahaan yang didirikan dan sah berdasarkan
hukum negara Republik Indonesia dan cakap menurut hukum untuk memiliki harta kekayaan
dan melakukan perbuatan hukum usahanya di wilayah Republik Indonesia serta memiliki
segala perizinan yang diperlukan untuk kegiatan operasionalnya;</li>
                            <li>Bahwa masing-masing Pihak telah mengambil segala tindakan yang diperlukan untuk
memastikan wakil dari masing-masing Pihak dalam Layanan Urun Dana telah memiliki kuasa
dan wewenang penuh untuk mengikatkan diri dan menyetujui syarat dan ketentuan ini;</li>
                            <li>Bahwa masing-masing Pihak telah memastikan bahwa syarat dan ketentuan ini
tidakmelanggar ketentuan dari anggaran dasar masing-masing Pihak dalam syarat dan
ketentuan dan tidak bertentangan dengan perjanjian apapun yang dibuat oleh masing –
masing Pihak dengan pihak ketiga.</li>
                            <li>Pelaksanaan ketentuan-ketentuan dalam Layanan Urun Dana ini dilaksanakan secara
profesional dengan penuh tanggung jawab dan atas dasar hubungan yang saling
menguntungkan.</li>
                            <li>Persetujuan dan pelaksanaan syarat dan ketentuanini tidak bertentangan atau melanggar
atau berbenturan dengan kaidah-kaidah hukum dan peraturan perundang-undangan serta
kebijakan-kebijakan pemerintah Republik Indonesia atau pihak yang berwenang lainnya;</li>
                            <li>Bersedia untuk menerapkan, mendukung dan mematuhi ketentuan hukum dan peraturan
perundang-undangan yang berlaku di Republik Indonesia termasuk namun tidak terbatas
pada peraturan mengenai tindak pidana korupsi, anti pencucian uang dan anti penyuapan;</li>
                            <li>Masing-masing Pihak akan melaksanakan Layanan Urun Dana ini dengan itikad baik dan
secara jujur. Tidak satupun ketentuan dalam syarat dan ketentuan ini akan digunakan oleh
salah satu Pihak untuk mengambil keuntungan secara tidak wajar dan mengakibatkan
kerugian bagi Pihak lainnya dan tidak satupun ketentuan dalam syarat dan ketentuan ini
dimaksudkan untuk memberikan keuntungan secara tidak wajar kepada Pihak lainnya.</li>
                        </ol>
                    </li>
                    <li>Tanpa mengesampingkan pernyataan dan jaminan yang diberikan oleh Penerbit ke
Penyelenggara, Penerbit dengan ini menyatakan dan menjamin kepada Penyelenggara hal-hal
sebagai berikut:</li>
                    <li>Bahwa Penerbit akan membebaskan Penyelenggara dari klaim, tuntutan, gugatan dari pihak
ketiga atas kelalaian Penerbit dan/atau karyawan Penerbit dalam melaksanakan Layanan Urun
Dana ini;</li>
                    <li>Bahwa Penerbit menyatakan tidak berkeberatan dalam hal ini Koperasi GEMA dana tau pihak lain
yang sesuai undang-undang berwenang untuk melakukan pemeriksaan, akan melakukan
pemeriksaan terhadap pelaksanaan Layanan Urun Dana ini;</li>
                    <li>Bahwa Penerbit bersedia untuk kemungkinan pengubahan, pembuatan atau pengambil alih
kegiatan yang dilaksanakan oleh Penerbit atau penghentian Layanan Urun Dana, dalam hal atas
permintaan Otoritas Jasa keuangan apabila diperlukan.</li>
                </ol>
            </p>
            <h6>KEADAAN MEMAKSA (FORCE MAJEURE)</h6>
            <p>
                <ol>
                    <li>Keadaan Memaksa atau Force Majeure adalah kejadian-kejadian yang terjadi diluar kemampuan
dan kekuasaan Para Pihak sehingga menghalangi Para Pihak untuk melaksanakan Layanan Urun
Dan ini, termasuk namun tidak terbatas pada adanya kebakaran, banjir, gempa bumi, likuifaksi,
badai, huru-hara, peperangan, epidemi, pertempuran, pemogokan, sabotase, embargo,
peledakan yang mengakibatkan kerusakan sistem teknologi informasi yang menghambat
pelaksanaan Layanan Urun Dana ini, serta kebijaksanaan pemerintah Republik Indonesia yang
secara langsung berpengaruh terhadap pelaksanaan Layanan Urun Dana ini.</li>
                    <li>Masing-masing Pihak dibebaskan untuk membayar denda apabila terlambat dalam melaksanakan
kewajibannya dalam syarat dan ketentuanini, karena adanya hal-hal Keadaan Memaksa.</li>
                    <li>Keadaan Memaksa sebagaimana dimaksud harus diberitahukan oleh Pihak yang mengalami
Keadaan Memaksa kepada Pihak lainnya dalam syarat dan ketentuan ini paling lambat 7 (tujuh)
Hari Kalender dengan melampirkan pernyataan atau keterangan tertulis dari pemerintah untuk
dipertimbangkan oleh Pihak lainnya beserta rencana pemenuhan kewajiban yang tertunda akibat
terjadinya Keadaan Memaksa.</li>
                    <li>Keadaan Memaksa yang menyebabkan keterlambatan pelaksanaan Layanan Urun Dana ini baik
untuk seluruhnya maupun sebagian bukan merupakan alasan untuk pembatalan Layanan Urun
Dana ini sampai dengan diatasinya Keadaan Memaksa tersebut.</li>
                </ol>
            </p>
            <h6>PENGALIHAN PERJANJIAN</h6>
            <p>
                <ol>
                    <li>Penerbit setuju dan sepakat untuk tidak mengalihkan sebagian atau keseluruhan hak dan
kewajiban Penerbit dalam Layanan Urun Dana ini kepada pihak lainnya.</li>
                </ol>
            </p>
            <h6>DOMISILI HUKUM DAN PENYELESAIAN SENGKETA</h6>
            <p>
                <ol>
                    <li>Layanan Urun Dana ini dibuat, ditafsirkan dan dilaksanakan berdasarkan hukum negara Republik
Indonesia.</li>
                    <li>Setiap perselisihan yang timbul sehubungan dengan Layanan Urun Dana ini, akan diupayakan
untuk diselesaikan terlebih dahulu oleh Para Pihak dengan melaksanakan musyawarah untuk
mufakat.</li>
                    <li>Apabila penyelesaian perselisihan secara musyawarah tidak berhasil mencapai mufakat sampai
dengan 30 (tiga puluh) Hari Kalender sejak dimulainya musyawarah tersebut, maka Para Pihak
sepakat untuk menyelesaikan perselisihan tersebut melalui proses pengadilan.</li>
                    <li>Para Pihak sepakat untuk menyelesaikan perselisihan di Pengadilan Negeri Kendal di Provinsi Jawa
Tengah tanpa mengurangi hak dari salah satu untuk mengajukan gugatan pada domisili
pengadilan lainnya (non-exlusive jurisdiction).</li>
                    <li>Tanpa mengesampingkan penyelesaian sengketa atau perselisihan melalui pengadilan negeri,
Para Pihak setuju dan sepakat apabila penyelesaian sengketa atau perselisihan di badan arbitrase
dan badan alternatif penyelesaian sengketa yang ditunjuk Koperasi GEMA maupun regulator
berwenang lainnya.</li>
                    <li>Hasil putusan pengadilan negeri maupun badan arbitrase dan badan alternatif penyelesaian
sengketa yang ditunjuk oleh Otoritas Jasa Keuangan maupun regulator berwenang lainnya
bersifat final dan mempunyai kekuatan hukum tetap dan mengikat bagi Para Pihak.</li>
                </ol>
            </p>
            <h6>KELALAIAN / WANPESTASI</h6>
            <p>
                <ol>
                    <li>Dalam hal terjadi salah satu hal atau peristiwa yang ditetapkan di bawah ini, maka merupakan
suatu kejadian kelalaian (wanprestasi) terhadap Layanan Urun Dana ini:
                        <ol type="a">
                            <li>Kelalaian dalam Layanan Urun Dana. <br />
Dalam hal salah satu Pihak terbukti sama sekali tidak melaksanakan kewajiban, atau
melaksanakan kewajiban tetapi tidak sebagaimana disepakati, atau melaksanakan kewajiban
tetapi tidak sesuai dengan waktu yang disepakati, atau melakukan sesuatu yang tidak
diperbolehkan dalam Layanan Urun Dana.</li>
                            <li>Pernyataan Tidak Benar <br />
Dalam hal ternyata bahwa sesuatu pernyataan atau jaminan yang diberikan oleh salah satu
Pihak kepada Pihak lainnya dalam Layanan Urun Dana ini terbukti tidak benar atau tidak
sesuai dengan kenyataannya dan menimbulkan kerugian langsung yang diderita salah satu
Pihak.</li>
                            <li>Kepailitan <br />
Dalam hal salah satu Pihak dalam Layanan Urun Dana ini oleh instansi yang berwenang
dinyatakan berada dalam keadaan pailit atau diberikan penundaan membayar hutang-hutang
(surseance van betaling).</li>
                            <li>Permohonan Kepailitan. <br />
Dalam hal salah satu Pihak dalam Layanan Urun Dana ini mengajukan permohonan kepada
instansi yang berwenang untuk dinyatakan pailit atau untuk diberikan penundaan membayar
hutang-hutang (surseance van betaling) atau dalam hal pihak lain mengajukan permohonan
kepada instansi yang berwenang agar salah satu Pihak dalam Layanan Urun Dana ini
dinyatakan dalam keadaan pailit.</li>
                        </ol>
                    </li>
                    <li>Dalam hal suatu kejadian kelalaian terjadi dan berlangsung, maka Pihak yang tidak lalai berhak
menyampaikan peringatan sebanyak 3 (tiga) kali dengan tenggang waktu 7 (tujuh) Hari Kalender
diantara masing-masing peringatan.</li>
                    <li>Setelah menyampaikan 3 (tiga) kali peringatan, Pihak yang tidak lalai berhak mengajukan tuntutan
berupa meminta pemenuhan prestasi dilakukan atau meminta prestasi dilakukan disertai ganti
kerugian atau meminta ganti kerugian saja atau menuntut pembatalan Layanan Urun Dana atau
menuntut pembatalan Layanan Urun Dana disertai ganti kerugian.</li>
                </ol>
            </p>
            <h6>DENDA ATAU PENALTI</h6>
            <p>Pengaturan mengenai pengenaan dan besarnya denda atau penalti diatur lebih lanjut dalam Perjanjian
Layanan Urun Dana antara Penyelenggara dan Penerbit.</p>
            <h6>MEKANISME DALAM HAL PENYELENGGARA TIDAK DAPAT MENJALANKAN OPERASIONALNYA</h6>
            <p>Mekanisme penyelesaian Layanan Urun Dana dalam hal Penyelenggara tidak dapat menjalankan
operasional adalah sebagai berikut :
                <ol>
                    <li>Melakukan diskusi dengan para Investor untuk memilih satu diantara penyelenggara yang telah
diberi izin oleh Otoritas Jasa Keuangan untuk dijadikan pengganti Penyelenggara, dengan syarat
dan ketentuan yang sudah disepakati bersama dengan Investor.</li>
                    <li>Buyback seluruh Unit Penyertaan Dana yang dimiliki Investor dengan harga pasar atau disepakati secara tertulis
oleh Para Pihak di kemudian hari.</li>
                    <li>Menunjuk penyelenggara lain yang seperti Penyelenggara, dengan syarat dan ketentuan yang
sudah disepakati bersama dengan Investor.</li>
                </ol>
            </p>
            <h6>LAIN - LAIN</h6>
            <p>
                <ol>
                    <li>Para Pihak wajib tunduk dan patuh terhadap peraturan perundang-undangan yang berlaku di
negara Republik Indonesia terkait pelaksanaan Layanan Urun Dana ini.</li>
                    <li>Layanan Urun Dana ini diinterpretasikan dan dilaksanakan berdasarkan hukum yang berlaku di
Negara Republik Indonesia.</li>
                    <li>Segala sesuatu yang tidak atau belum cukup diatur dalam syarat dan ketentuan ini, akan diatur
kemudian dalam bentuk Perjanjian Layanan Urun Dan yang dibuat secara tertulis dan
ditandatangani oleh Penyelenggara dan Investor.</li>
                    <li>Keabsahan, penafsiran dan pelaksanaan Layanan Urun Dana ini, diatur serta tunduk pada hukum
dan peraturan perundang-undangan yang berlaku di negara Republik Indonesia.</li>
                    <li>Dalam hal ada salah satu atau lebih ketentuan dalam syarat dan ketentuan ini dinyatakan tidak
sah, tidak berlaku atau tidak dapat dilaksanakan berdasarkan hukum dan/atau peraturan
perundang-undangan yang berlaku di Republik Indonesia, maka kedua belah pihak setuju bahwa
keabsahan ketentuan lainnya dalam Perjanjian Layanan Urun Dana tetap berlaku dan dapat
dilaksanakan serta tidak akan terpengaruh.</li>
                    <li>Dokumen-dokumen atau kesepakatan-kesepakatan terkait dengan Layanan Urun Dana ini yang
telah dibuat oleh Para Pihak sebelum ditandatanganinya Perjanjian Layanan Urun Dana
dinyatakan dicabut dan tidak berlaku sejak terhitung ditandatanganinya Perjanjian Layanan Urun
Dana.</li>
                </ol>
            </p>
            <p>
            SYARAT DAN KETENTUAN INI DIBUAT DAN DIBERIKAN PERSETUJUAN SECARA ELEKTRONIK OLEH PENERBIT
DALAM KEADAAN SEHAT DAN SADAR SERTA TANPA ADA PAKSAAN DARI PIHAK MANAPUN JUGA.
            </p>
            <p>
            SETELAH PENERBIT MEMBUBUHKAN TANDA CENTANG (v) PADA KOTAK PERSETUJUAN SECARA
ELEKTRONIK ATAS SYARAT DAN KETENTUAN INI, MAKA PENERBIT DENGAN INI MENYATAKAN SETUJU
TELAH MEMBACA, MENGERTI, MEMAHAMI SECARA SEKSAMA DAN TUNDUK PADA SETIAP DAN
KESELURUHAN SYARAT DAN KETENTUAN, DAN PERJANJIAN LAYANAN URUN DANA YANG DISEPAKATI DI

KEMUDIAN HARI, SERTA TUNDUK PADA PERATURAN KOPERASI GEMA BESERTA PERUBAHAN-
PERUBAHANNYA.
            </p>
            <p>
            DAN OLEH KARENA ITU, DALAM PELAKSANAAN LAYANAN URUN DANA, PENERBIT MENYATAKAN DAN
MENJAMIN BAHWA PENERBIT SELALU TETAP MEMATUHI DAN MELAKSANAKAN SETIAP KETENTUAN
YANG ADA DALAM SYARAT DAN KETENTUAN INI DAN PERJANJIAN LAYANAN URUN DANA YANG
DISEPAKATI DI KEMUDIAN HARI DENGAN PENUH TANGGUNG JAWAB DAN PROFESIONAL.
            </p>
            </div>
        </div>
        </section>
        <Footer />
    </div>
  );
}

export default SNK;
