import { useState } from "react";

export default function useAuth() {
  const getUser = () => {
    const tokenString = localStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    return userToken?.user;
  };

  const [user, setUser] = useState(getUser());

  const saveToken = (userToken) => {
    localStorage.setItem("token", JSON.stringify(userToken));
    setUser(userToken.user);
  };

  return {
    user,
  };
}
