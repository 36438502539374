import Header from "../Header/header";
import Footer from "../Footer/footer";

export default function Layout2({children}){
    return(
        <>
        <Header/>
        <>{children}</>
        <Footer />
        </>
    )

}