import axios from "axios";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { getProject } from "../../store/Data";

const Count = () => {
  const projects = useRecoilValue(getProject) || [];
  const [totalUnit, setTotalUnit] = useState(0);

  const getTotalDana = () => {
    var total = 0;
    projects.map((project) => {
      total += project.totalPendanaan;
    });
    return Math.floor(total / 1000000000);
  };

  const getTotalUnit = () => {
    return projects.length;
  };

  return (
    <div>
      <section id="counts" className="counts mt-5">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-lg-4 col-md-6 d-md-flex align-items-md-stretch">
              <div className="count-box">
                <i className="bi bi-emoji-smile"></i>
                <span>{getTotalUnit()}+ Unit Bisnis</span>
                <p>
                  Lebih dari <strong>{getTotalUnit()} unit bisnis</strong> sudah
                  berdiri
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-md-flex align-items-md-stretch">
              <div className="count-box">
                <i className="bi bi-journal-richtext"></i>
                <span>{getTotalDana()} Milyar+ Dana</span>
                <p>
                  Lebih dari <strong>{getTotalDana()} milyar dana</strong>{" "}
                  terkumpul dari seluruh Indonesia
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-md-flex align-items-md-stretch">
              <div className="count-box">
                <i className="bi bi-headset"></i>
                <span>450+ Anggota</span>
                <p>
                  Lebih dari <strong>450 anggota</strong> di seluruh Indonesia
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Count;
