import { Link } from "react-router-dom";

function Footer() {
    return (
      <div>
          <footer id="footer">
            <div className="footer-top">
            <div className="container">
                    <div className="row">

                    <div className="col-lg-4 col-md-6">
                        <div className="footer-info">
                        <a href="index.html" className="logo"><img src="https://gemaindonesia.id/wp-content/uploads/2021/10/logo_gema_169x60.png" alt="" className="img-fluid mb-3"/></a>
                        <p className="pb-3"><em>GEMA adalah sebuah Koperasi yang dikelola dengan managamen modern, menggunakan platform Equity Crowdfunding untuk pengembangan usaha seperti Usaha Mikro, Kecil dan Menengah (UMKM), serta usaha rintisan (Startup)</em></p>
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-6 footer-links">
                        <h4>Useful Links</h4>
                        <ul>
                        <li><i className="bx bx-chevron-right"></i> <Link to="/tentang-kami">Tentang Kami</Link></li>
                        <li><i className="bx bx-chevron-right"></i> <Link to="/faq">F.A.Q</Link></li>
                        <li><i className="bx bx-chevron-right"></i> <Link to="/visi-dan-misi">Visi dan Misi</Link></li>
                        <li><i className="bx bx-chevron-right"></i> <Link to="/skema-investasi">Skema Investasi</Link></li>
                        <li><i className="bx bx-chevron-right"></i> <Link to="/syarat-menjadi-investor">Syarat Menjadi Investor</Link></li>
                        <li><i className="bx bx-chevron-right"></i> <Link to="/syarat-menjadi-investee">Syarat Menjadi Investee</Link></li>
                        </ul>
                    </div>

                    <div className="col-lg-2 col-md-6 footer-links">
                        <h4>Our Services</h4>
                        <ul>
                        <li><i className="bx bx-chevron-right"></i> <Link to="/register">Daftar Jadi Anggota</Link></li>
                        <li><i className="bx bx-chevron-right"></i> <Link to="/manajemen">Manajemen</Link></li>
                        <li><i className="bx bx-chevron-right"></i> <Link to="/dividen-shu">Dividen SHU</Link></li>
                        <li><i className="bx bx-chevron-right"></i> <Link to="/list-umkm">List UMKM</Link></li>
                        <li><i className="bx bx-chevron-right"></i> <Link to="/syarat-dan-ketentuan">Syarat dan Ketentuan</Link></li>
                        </ul>
                    </div>

                    <div className="col-lg-4 col-md-6 footer-newsletter">
                        <h4>Sign Up</h4>
                        <p>Daftar menjadi anggota GEMA Indonesia sekarang juga</p>
                        <a href="/register" className="btn btn-orange4">Daftar Sekarang</a>
                    </div>

                    </div>
                </div>
            </div>

            <div className="container">
            <div className="copyright">
                &copy; Copyright <strong><span>Gema Indonesia</span></strong>. All Rights Reserved
            </div>
            </div>
        </footer>
      </div>
    );
  }
  
  export default Footer;
  