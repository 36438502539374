function CardTeam(props) {
    return (
      <div>
            <div className="member">
              <img src={props.url} className="img-fluid" alt=""/>
              <div className="member-info">
                <div className="member-info-content">
                  <h4>{props.name}</h4>
                  <span>{props.position}</span>
                </div>
                <div className="social">
                  <a target="_blank" href={props.fb}><i className="bi bi-facebook"></i></a>
                  <a target="_blank" href={props.ig}><i className="bi bi-instagram"></i></a>
                  <a target="_blank" href={props.linkedin}><i className="bi bi-linkedin"></i></a>
                </div>
              </div>
            </div>
      </div>
    );
  }
  
  export default CardTeam;
  