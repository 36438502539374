import Header from "../../../components/Header/header";
import Breadcumbs from "../../../components/Breadcumbs/breadcumbs";
import Innerpages from "../../../components/Innerpages/innerpages";
import Team from "../../../components/Team/team";
import Team2 from "../../../components/Team/team2";
import Footer from "../../../components/Footer/footer";
import { seo } from "../../../helper/seo";

function Management(props) {
  seo({
    title:"Manajemen | Gema Indonesia",
    metaDescription: "Berikut adalah daftar team Manajemen GEMA Indonesia",
  })
  return (
    <div>
      {window.scrollTo(0, 0)}
        <Header/>
        <section className="page-header">
                <div className="container">
                    <div className="title">
                    <h1>Manajemen</h1>
                    </div>
                </div>
            </section>
            <Team2 />
        <Footer />
    </div>
  );
}

export default Management;
