import Header from "../../../components/Header/header";
import Breadcumbs from "../../../components/Breadcumbs/breadcumbs";
import Footer from "../../../components/Footer/footer";
import { seo } from "../../../helper/seo";

function TentangKami(props) {
  seo({
    title:"Tentang Kami | Gema Indonesia",
    metaDescription: "GEMA adalah sebuah Koperasi yang dikelola dengan managamen modern, menggunakan platform Equity Crowdfunding untuk pengembangan usaha seperti Usaha Mikro, Kecil dan Menengah (UMKM), serta usaha rintisan (Startup)",
  })
  return (
    <div>
      {window.scrollTo(0, 0)}
        <Header/>
        <section className="page-header">
                <div className="container">
                    <div className="title">
                    <h1>Tentang Kami</h1>
                    </div>
                </div>
            </section>
        <section className="inner-page">
        <div className="container">
            <div className="wrap">
            <h1>KOPERASI GERAKAN EKONOMI MADANI (GEMA)</h1>
            <div className="text-center">
            <img src="assets/img/tentang-kami-gemaindonesia.jpg" width="70%" className="my-5" alt="" />
            </div>
            <p>
            Gerakan Ekonomi Madani atau disingkat GEMA adalah sebuah Badan Usaha
            Berbadan Hukum berwujud Koperasi yang dikelola dengan managamen modern,
            menggunakan platform Equity Crowdfunding dimana sejumlah besar orang bisa ikut
            berpartisipasi dalam penggalangan dana untuk pengembangan usaha yang
            dilakukan oleh bisnis Usaha Mikro, Kecil dan Menengah (UMKM), serta usaha
            rintisan (startup) dengan mendapatkan Unit Penyertaan Dana dan/atau sharing profit sebagai
            imbalannya.
            </p>
                <p>Dengan platform penggalangan dana ini, sesuai Program Pemerintaah berdasarkan
            prinsip Koperasi yakni “Dari Kita, Oleh Kita dan Untuk Kita”, pelaku usaha UMKM
            dan startup bisa mendapatkan dana segar baik berbentuk pinjaman (lending)
            maupun investasi untuk mengembangkan bisnisnya, baik di skala lokal, nasional
            bahkan global sampai manca negara.</p>
                <p>Semua perusahan yang terdaftar di Koperasi GEMA pada dasarnya telah lolos
            mengikuti seleksi yang sangat ketat baik terkait sistem administratif, akuntability
            sampai Business Plan dan Financial Projection yang bisa dipertanggungjawabkan.
            Harapannya kedepan kita bisa memenuhi segala macam kebutuhan kita sendiri,
            dengan menjadikan pasar sebagai pemilik bisnis. Semangat ini lahir karena peranan
            kita yang masih termarjinalkan. Pasar, produk, ekonomi serta distribusi tidak di
            kuasai oleh kita khusunya pelaku usaha UMKM. Dengan semangat ekonomi
            gotong-royong yang fokus pada usaha sektor riil kita mampu berdaya di negeri
            sendiri.</p>
            </div>
        </div>
        </section>
        <Footer />
    </div>
  );
}

export default TentangKami;
