import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import Footer from "../../../components/Footer/footer";
import Header from "../../../components/Header/header";
import { getTransaction } from "../../../store/Data";
import {
  FiLogOut,
  FiPackage,
  FiPieChart,
  FiShoppingCart,
  FiTrendingUp,
  FiUser,
} from "react-icons/fi";
import { GrTransaction } from "react-icons/gr";
import { useEffect } from "react";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import axios from "axios";
import { goLogout } from "../../../components/Auth/Logout";
import useAuth from "../../../components/Auth/UseAuth";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import useToken from "../../../components/Auth/UseToken";

const DataDiri = (props) => {
  return (
    <>
      <div className="row mb-3">
        <div className="col">
          <label className="mb-1" htmlFor="">
            Nama Lengkap
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Nama Lengkap"
            value={props.user ? props.user.name : null}
            readOnly
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-6">
          <label className="mb-1" htmlFor="">
            Email
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Email"
            value={props.user ? props.user.email : null}
            readOnly
          />
        </div>
        <div className="col-6">
          <label className="mb-1" htmlFor="">
            Phone
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Nomor Telphone"
            name="phone"
            value={props.user ? props.user.phone : null}
          />
        </div>
      </div>
      {/* <div className="row mb-3">
        <div className="col-6">
          <label className="mb-1" htmlFor="">
            Alamat
          </label>
          <textarea
            className="form-control"
            value={props.user ? props.user.details.address : null}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-6">
          <label className="mb-1" htmlFor="">
            Kota
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Kota"
            value={props.user ? props.user.details.city : null}
          />
        </div>
        <div className="col-6">
          <label className="mb-1" htmlFor="">
            Kecamatan
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Kecamatan"
            value={props.user ? props.user.details.districts : null}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-6">
          <label className="mb-1" htmlFor="">
            Kelurahan
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Kelurahan"
            value={props.user ? props.user.details.region : null}
          />
        </div>
        <div className="col-6">
          <label className="mb-1" htmlFor="">
            Kode Pos
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Kode Pos"
            value={props.user ? props.user.details.post_code : null}
          />
        </div>
      </div> */}
    </>
  );
};

const UploadKTP = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  return selectedImage ? (
    selectedImage && (
      <div>
        <img
          alt="not fount"
          width={"250px"}
          src={URL.createObjectURL(selectedImage)}
        />
        <br />
        <button
          className="btn btn-orange mt-2"
          onClick={() => setSelectedImage(null)}
        >
          Remove
        </button>
      </div>
    )
  ) : (
    <div>
      <label htmlFor="" className="mb-3">
        Foto KTP
      </label>
      <input
        type="file"
        name="myImage"
        onChange={(event) => {
          setSelectedImage(event.target.files[0]);
        }}
      />
    </div>
  );
};

const UploadSelfie = () => {
  const [selectedImage2, setSelectedImage2] = useState(null);

  return selectedImage2 ? (
    selectedImage2 && (
      <div>
        <img
          alt="not fount"
          width={"250px"}
          src={URL.createObjectURL(selectedImage2)}
        />
        <br />
        <button
          className="btn btn-orange mt-2"
          onClick={() => setSelectedImage2(null)}
        >
          Remove
        </button>
      </div>
    )
  ) : (
    <div>
      <label htmlFor="" className="mb-3">
        Foto Selfie
      </label>
      <input
        type="file"
        name="myImage"
        onChange={(event) => {
          setSelectedImage2(event.target.files[0]);
        }}
      />
    </div>
  );
};

const UploadNPWP = () => {
  const [selectedImage3, setSelectedImage3] = useState(null);

  return selectedImage3 ? (
    selectedImage3 && (
      <div>
        <img
          alt="not fount"
          width={"250px"}
          src={URL.createObjectURL(selectedImage3)}
        />
        <br />
        <button
          className="btn btn-orange mt-2"
          onClick={() => setSelectedImage3(null)}
        >
          Remove
        </button>
      </div>
    )
  ) : (
    <div>
      <label htmlFor="" className="mb-3">
        Foto NPWP
      </label>
      <input
        type="file"
        name="myImage"
        onChange={(event) => {
          setSelectedImage3(event.target.files[0]);
        }}
      />
    </div>
  );
};

const Dokumen = (props) => {
  return (
    <>
      <div className="row mb-3">
        <div className="col">
          <label className="mb-1" htmlFor="">
            Nomor KTP
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Nomor KTP"
            readOnly
            value={props.user ? props.user.details.id_card_number : null}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-6">
          <div className="mb-1">Foto KTP</div>
          <img
            alt="not fount"
            width={"60%"}
            src={props.user ? props.user.details.id_card_path : null}
          />
        </div>
        <div className="col-6">
          <div className="mb-1">Foto Selfie</div>
          <img
            alt="not fount"
            width={"60%"}
            src={props.user ? props.user.details.selfie_path : null}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <label className="mb-1" htmlFor="">
            Nomor NPWP
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Nomor KTP"
            value={props.user ? props.user.details.npwp_number : null}
            readOnly
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-6">
          {props.user?.details.npwp_path ==
          "https://backend.gemaindonesia.id/storage/" ? null : (
            <>
              <div className="mb-1">Foto NPWP</div>
              <img
                alt="not fount"
                width={"250px"}
                src={props.user.details.npwp_path}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

const Rekening = (props) => {
  const { user } = useAuth();
  return (
    <>
      <div className="row mb-3">
        <div className="col">
          <label className="mb-1" htmlFor="">
            Atas Nama Rekening
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Atas Nama Rekening"
            value={user.name}
            readOnly
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-6">
          <label className="mb-1" htmlFor="">
            Nama Bank
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Nama Bank"
            value=""
          />
        </div>
        <div className="col-6">
          <label className="mb-1" htmlFor="">
            Nomor Rekening
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Nomor Rekening"
            value=""
          />
        </div>
      </div>
    </>
  );
};

const DashboardEmpty = (props) => {
  const history = useHistory();

  return (
    <div className="dashboard-empty text-center">
      <img
        src="/assets/img/icon-empty.svg"
        className="mt-5"
        width={"35%"}
        alt=""
      />
      <div className="mt-3">
        <h6>Belum ada {props.title ? props.title : null}</h6>
        <p>
          Yuk mulai berinvestasi! Tekan tombol dibawah ini untuk berinvestasi
          pada bisnis pilihan.
        </p>
        <Link to="/list-umkm" className="btn btn-orange mb-5">
          Mulai Investasi
        </Link>
      </div>
    </div>
  );
};

const DashboardUser = (props) => {
  const [dataDiri, setDataDiri] = useState(true);
  const [dokumen, setDokumen] = useState(false);
  const [rekening, setRekening] = useState(false);

  return (
    <div className="dashboard-inner">
      <div className="dashboard-content">
        <div className="dashboard-nav">
          <button
            onClick={() => {
              setDataDiri(true);
              setDokumen(false);
              setRekening(false);
            }}
            className={`btn btn-dashboard ${dataDiri ? "solid" : "line"} mx-2`}
          >
            Data Diri
          </button>
          <button
            onClick={() => {
              setDataDiri(false);
              setDokumen(false);
              setRekening(true);
            }}
            className={`btn btn-dashboard ${rekening ? "solid" : "line"} mx-2`}
          >
            Rekening
          </button>
        </div>
        <hr />
        <div className="dashboard-form">
          {dataDiri ? <DataDiri user={props.user} /> : null}
          {dokumen ? <Dokumen user={props.user} /> : null}
          {rekening ? <Rekening user={props.user} /> : null}
        </div>
      </div>
    </div>
  );
};

const Portofolio = () => {
  const { token } = useToken();
  const transaction = useRecoilValue(getTransaction);

  const testTransaction = async () => {
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    console.log(headers);
    const bodyParameters = {
      key: "value",
    };
    try {
      const res = await axios.get(
        "https://admin.gemaindonesia.id/admin/v1/entities/custom-transaction",
        {
          headers: headers,
        }
      );
      if (res) console.log(res);
    } catch (error) {
      console.log(error.response);
    }
  };

  console.log(transaction);
  const history = useHistory();
  return (
    <>
      {transaction?.length > 0 ? (
        <table class="table mt-3">
          <thead>
            <tr>
              <th>Payment ID</th>
              <th>Nama Project</th>
              <th>Status</th>
              <th>Jumlah Koin</th>
              <th>Jumlah Lot</th>
              <th>Total</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {transaction?.map((e) => {
              return (
                <>
                  <tr>
                    <td>
                      <span className="bg-warning px-2">{e.payment_id}</span>
                    </td>
                    <td>{e.project?.project_name || " "}</td>
                    <td>
                      {e.status == "pending" ? (
                        <span class="badge bg-danger">{e.status}</span>
                      ) : (
                        <span class="badge bg-success">{e.status}</span>
                      )}
                    </td>
                    <td>{e.total_koin.toLocaleString()}</td>
                    <td>{e.total_lot.toLocaleString()}</td>
                    <td>Rp. {e.total_price.toLocaleString()}</td>
                    <td>
                      <button
                        onClick={() => {
                          window.location = `/invoice/${e.payment_id}`;
                        }}
                        className="btn btn-sm btn-dark"
                      >
                        Invoice
                      </button>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      ) : (
        <DashboardEmpty title="portofolio" />
      )}
    </>
  );
};

const PortoMenu = () => {
  const [portofolio, setPortofolio] = useState(true);
  const [history, setHistory] = useState(false);

  return (
    <div className="dashboard-inner">
      <div className="dashboard-content">
        <div className="dashboard-nav">
          <button
            onClick={() => {}}
            className={`btn btn-dashboard ${
              portofolio ? "solid" : "line"
            } mx-2`}
          >
            Portofolio
          </button>
        </div>
        <Portofolio />
      </div>
    </div>
  );
};

const Transaksi = () => {
  const [pending, setPending] = useState(true);
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);

  const toggleButton = (data) => {
    if (data == "pending") {
      setPending(true);
      setSuccess(false);
      setFail(false);
    } else if (data == "success") {
      setPending(false);
      setSuccess(true);
      setFail(false);
    } else if (data == "fail") {
      setPending(false);
      setSuccess(false);
      setFail(true);
    }
  };

  return (
    <div className="dashboard-inner">
      <div className="dashboard-content">
        <div className="dashboard-nav">
          <button
            onClick={() => {
              toggleButton("pending");
            }}
            className={`btn btn-dashboard ${pending ? "solid" : "line"} mx-2`}
          >
            Pending
          </button>
          <button
            onClick={() => {
              toggleButton("success");
            }}
            className={`btn btn-dashboard ${success ? "solid" : "line"} mx-2`}
          >
            Paid
          </button>
          <button
            onClick={() => {
              toggleButton("fail");
            }}
            className={`btn btn-dashboard ${fail ? "solid" : "line"} mx-2`}
          >
            Canceled
          </button>
        </div>
        {<DashboardEmpty title="history" />}
      </div>
    </div>
  );
};

export default function Dashboard() {
  const { user } = useAuth();
  const userdata = user;

  const [profile, setProfile] = useState(true);
  const [portofolio, setPortofolio] = useState(false);
  const url = useLocation().pathname;

  const menu = [
    {
      name: "Data Diri",
      link: "/dashboard/profile",
      icon: <FiUser className="mx-3" />,
    },
    {
      name: "Portofolio",
      link: "/dashboard/portofolio",
      icon: <FiPackage className="mx-3" />,
    },
    {
      name: "Transaksi",
      link: "/dashboard/transaksi",
      icon: <FiShoppingCart className="mx-3" />,
    },
  ];

  const isActive = () => {
    if (userdata) {
      if (userdata?.email_verified_at) {
        return "Activated";
      } else {
        return "Not Activated";
      }
    }
  };

  return (
    <div>
      {window.scrollTo(0, 0)}
      <Header />
      <section className="inner-page">
        <div className="container">
          <div className="wrap">
            <div className="row mt-5">
              <div className="col-lg-3">
                <div className="dashboard-sidebar text-center">
                  <div className="dashboard-wrapper">
                    <img
                      src="https://kerma.widyatama.ac.id/wp-content/uploads/2021/05/blank-profile-picture-973460_1280.png"
                      className="avatar-md"
                      alt=""
                    />
                    <div
                      className="text-white mt-3"
                      style={{ fontSize: "16px" }}
                    >
                      {userdata?.name}
                      <div style={{ opacity: "0.3", fontSize: "14px" }}>
                        {isActive()}
                      </div>
                    </div>
                  </div>
                  <div className="dashboard-menu text-white">
                    {menu.map((e, index) => {
                      return (
                        <Link to={e.link} key={index}>
                          <div
                            className={`menu-item ${
                              e.link == url ? "active" : null
                            }`}
                            style={{ color: "white" }}
                          >
                            {e.icon} {e.name}
                          </div>
                        </Link>
                      );
                    })}
                    <a onClick={goLogout} style={{ cursor: "pointer" }}>
                      <div className="menu-item mb-5">
                        {" "}
                        <FiLogOut className="mx-3" />
                        Keluar
                      </div>
                    </a>
                  </div>
                  <div className="spacer"></div>
                </div>
              </div>
              <div className="col-lg-9">
                <Switch>
                  <Route exact path="/dashboard">
                    <DashboardUser user={userdata} />
                  </Route>
                  <Route path="/dashboard/profile">
                    <DashboardUser user={userdata} />
                  </Route>
                  <Route path="/dashboard/portofolio">
                    <PortoMenu />
                  </Route>
                  <Route path="/dashboard/transaksi">
                    <Transaksi />
                  </Route>
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
