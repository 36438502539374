import { Link } from "react-router-dom";

function About() {
    return (
      <div>
        <section id="cta" className="cta">
        <div className="container">
            <div className="text-center">
            <p>Untuk Anda yang ingin memiliki bisnis tetapi tidak mengerti cara berbisnis atau mengatur operasional bisnis, atau Anda ingin tetap bekerja tetapi ingin mendapatkan passive income, maka investasi bisnis dengan Equity Crowndfunding merupakan solusi yang tepat untuk Anda. </p>
            <p>Equity Crowdfunding adalah penawaran Unit Penyertaan Dana bisnis kepada investor atau dikenal dengan sistem urun dana.
                Sistem urun dana memberikan investor kesempatan untuk berinvestasi ke dalam bisnis privat dengan modal kecil yang sebelumnya sulit untuk dilakukan. Investasi system ini juga merupakan peluang besar bagi investor yang ingin sekali berinvestasi bisnis tetapi memiliki keterbatasan dana. 
                </p>
            <Link className="cta-btn" to="/skema-investasi">Selengkapnya</Link>
            </div>

        </div>
        </section>
      </div>
    );
  }
  
  export default About;
  